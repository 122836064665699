import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@material-ui/core";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { AppConfig } from "app-config";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import ArrowDownIcon from "../../../../images/arrowdown.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    color: "#B0AEAE",
  },
  editButton: {
    border: "none",
    textTransform: "capitalize",
    background: "#47BB78",
    color: "white",
    "&:hover": {
      border: "none",
      textTransform: "capitalize",
      background: "#47BB78",
    },
  },
  deleteButton: {
    border: "1px solid #EB4B4B",
    background: "#EB4B4B",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      background: "#EB4B4B",
      color: "white",
    },
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
  paginated: {
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: "60px",
    paddingBlock: "18px",
  },
});

const columns = [
  { id: "name", label: "ZMC Name", minWidth: 170 },
  { id: "number", label: "Contact number", minWidth: 100 },
  {
    id: "type",
    label: "Zone type",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date",
    label: "Renewal Date",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, amount, status, date, actions) {
  return { name, amount, status, date, actions };
}

const ActionButton = () => {
  const classes = useStyles();
  return (
    <div>
      <Button className={classes.editButton}>Edit</Button>
      <Button className={classes.deleteButton}>Delete</Button>
    </div>
  );
};

const rows = [
  createData(
    "Semira enterprise",
    "893860592RW",
    "Oil and gas",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Industrial park",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Oil and gas",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Oil and gas",
    "05/07/2022",
    <ActionButton />,
  ),
];

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TotatalRequestsTab = () => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [statusColor, setStatusColor] = React.useState("#47BB78");
  const [statusBackground, setStatusBackground] = React.useState(
    "rgba(71, 187, 120, 0.3)",
  );
  const [pendingStatusBackground, setPendingStatusBackground] = React.useState(
    "rgba(239, 163, 75, 0.3)",
  );
  const [pendingColor, setPendingColor] = React.useState("#EFA34B");
  const [totalRequest, setTotalRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mssg, setMssg] = useState("");
  const [step, setStep] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [paginated, setPaginated] = useState(false);
  const [page, setPage] = useState(1);

  let history = useHistory();

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevState = () => {
    setStep(step - 1);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Previous = (e) => {
    e.preventDefault();
    prevState();
  };

  const Next = (e) => {
    e.preventDefault();
    nextStep();
  };

  if (rows.status === "Pending") {
    // setStatusBackground("rgba(239, 163, 75, 0.3)")
    setStatusColor("#EFA34B");
  }

  const requestStatus = "declined";

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  //format as money code
  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  //get total application requests
  const totalRequestInventory = async () => {
    setLoading(true);
    let url = `${AppConfig.URL}/zone-admin/zone-manager-inventory?page=${page}&status=${requestStatus}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setLoading(false);
        setMssg(message);
        setLastPage(data.last_page);
        console.log("Total Request Mssg", message);
        setTotalRequest(data?.data);
        console.log("MYTOTALREQ", totalRequest);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    totalRequestInventory();
  }, [page]);

  //show application detail
  const showRequestDetails = (rowId) => {
    console.log(rowId);
    history.push(`/admin-account/zone-request-inventory/${rowId}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setMssg("No Declined Request");
    }, 2000);
  });

  return (
    <>
      {/* use this when endpoints are ready*/}
      <Paper elevation={0}>
        {loading ? (
          <Box className={classes.spinner}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: "#B0AEAE" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {totalRequest?.map((row) => (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textTransform: "capitalize" }}
                      key={row?.id}
                    >
                      {row?.name}
                    </TableCell>

                    <TableCell
                      component="th"
                      align="left"
                      className={classes.tableCell}
                    >
                      {row?.contact_number}
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        style={{
                          color: "#0D8FD8",
                          textAlign: "center",
                          fontSize: "14px",
                          borderRadius: "20px",
                          padding: "5px",
                          textTransform: "capitalize",
                          background: "rgba(146, 179, 230, 0.17)",
                        }}
                      >
                        {row?.zone_type ? row?.zone_type : "Public"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        {row?.opl_renewal_date !== null
                          ? moment(row?.opl_renewal_date).format("M/D/YYYY")
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        className={classes.editButton}
                        onClick={() => showRequestDetails(row?.application_id)}
                      >
                        View request
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Box className={classes.paginated}>
        <Button onClick={Previous} color="default" className={classes.btn}>
          <span style={{ marginRight: "5px" }}>
            <ArrowBackIcon />
          </span>
          Previous
        </Button>
        <Pagination
          component="Box"
          count={lastPage}
          onChange={handleChangePage}
          page={page}
          classes={{ ul: classes.ul }}
          color="standard"
        />
        <Button
          onClick={() => {
            setStep(step + 1);
          }}
          color="default"
          className={classes.btn}
        >
          Next
          <span>
            <ArrowForwardIcon />
          </span>
        </Button>
      </Box>
    </>
  );
};

export default TotatalRequestsTab;
