import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  Chip,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import TotalRequestIcon from "../../images/totalRequestIcon.svg";
import TotalRequestAmountIcon from "../../images/totalRequestAmountIcon.svg";
import PendingRequestIcon from "../../images/pendingRequestIcon.svg";
import ApprovedRequestIcon from "../../images/approvedRequestIcon.svg";
import DeclinedRequestIcon from "../../images/declinedRequestIcon.svg";
import blueLocation from "../../images/blue-location.svg";
import orangeLocation from "../../images/orange-location.svg";
import greenTv from "../../images/green-tv.svg";
import greenBox from "../../images/green-box.svg";
import greenWallet from "../../images/green-wallet.svg";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart";
import FinanceAppInfo from "./Applications/FinanceAppInfo";
import moment from "moment/moment";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "35px",
    marginTop: "20px",
  },
  bottomData: {
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    minHeight: "112px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "white",
    gap: "20px",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    background: "rgba(245, 244, 244, 0.8)",
    flexGrow: 0,
  },
  whiteBg: {
    background: "white",
    padding: "35px",
  },
  inline: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    marginBottom: "51px",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    color: "#B0AEAE",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default () => {
  const [loading, setLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(false);

  const [stat, setStat] = useState({});
  const [apps, setApps] = useState([]);
  const [view, setView] = useState(null);
  const [cards, setCards] = useState({
    approved_request: 0,
    declined_request: 0,
    pending_request: 0,
    revenue: 0,
    total_request: 0,
  });

  const user = localStorage.getItem("userDetails");
  const [charts, setCharts] = useState([]);

  const history = useHistory();

  const [filter, setFilter] = useState("private");

  const userData = JSON.parse(user);
  const classes = useStyles();
  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/super-admin/chart?entity_type=enterprise`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        // setStat(data);
        setCharts(data);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(fee);
  };

  const getApps = async () => {
    setAppLoading(true);
    try {
      const response = await fetch(
        `${
          AppConfig.URL
        }/super-admin/application-requests?page=${1}&per_page=5`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data.data);
        setApps(data.data);
      } else {
        Swal.fire("", message, "info");
      }
      setAppLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "could not get applications", "error");
      setLoading(false);
    }
  };
  const getDashboard = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/super-admin/finance/cards`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        setCards(data.cards);
        // setCharts(data.charts);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getApps();
    getDashboard();
    getCategories();
  }, []);

  //show application detail
  const showRequestDetails = (rowId) => {
    console.log(rowId);
    history.push(`/admin-account/finance-applications/${rowId}`);
  };

  const cars = [
    {
      number: formatAsMoney(parseFloat(cards[0]?.data).toFixed() || 0),
      image: blueLocation,
      name: "Enterprise revenue",
    },
    {
      number: formatAsMoney(parseFloat(cards[1]?.data).toFixed() || 0),
      image: orangeLocation,
      name: "ZMCs Revenue",
    },
    {
      number: formatAsMoney(parseFloat(cards[2]?.data).toFixed() || 0),
      image: greenTv,
      name: "Private FZCs",
    },
    {
      number: formatAsMoney(parseFloat(cards[3]?.data).toFixed() || 0),
      image: greenBox,
      name: "Public FZCs",
    },
    {
      number: formatAsMoney(parseFloat(cards[4]?.data).toFixed() || 0),
      image: TotalRequestAmountIcon,
      name: "Total Revenue generated",
    },
  ];

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {cars.map(({ number, image, name }) => (
          <Grid item xs={12} sm={6} md={4}>
            <Link to="/admin-account/finance-applications">
              <div className={classes.paper}>
                <div className={classes.cards}>
                  <img src={image} alt="icon" />
                  <div>
                    <Typography
                      variant="h5"
                      style={{ color: "black", marginBottom: "12px" }}
                    >
                      {number}
                      {/* {item.isMoney ? formatAsMoney(item.data) : item.data} */}
                    </Typography>
                    <Typography style={{ color: "#B0AEAE" }}>
                      {name}
                      {/* {item.title} */}
                    </Typography>
                  </div>
                </div>
                <div></div>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
      <div className={classes.whiteBg}>
        <div className={classes.inline}>
          <Typography
            style={{ color: "black", fontSize: "20px", fontWeight: 600 }}
          >
            Applications
          </Typography>
        </div>
        <Grid item xs={12} sm={12}>
          <TableContainer className={classes.table}>
            <Table stickyHeader aria-label="customized table">
              <TableHead className={classes.head}>
                <TableRow>
                  <StyledTableCell>Company Name</StyledTableCell>
                  <StyledTableCell>Application type</StyledTableCell>
                  <StyledTableCell>Application date</StyledTableCell>
                  <StyledTableCell>Payment status</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apps &&
                  apps.map((zone, index) => (
                    <TableRow key={zone.id}>
                      <StyledTableCell component="th" scope="row">
                        {zone.name}
                      </StyledTableCell>
                      {/* <TableCell>{index + 1}</TableCell> */}
                      <TableCell>{zone.application_type}</TableCell>
                      <TableCell align="left">
                        {moment(zone.application_date).format("DD/MM/YY")}
                        {/* <Chip label={zone.address} className={classes.chip} /> */}
                      </TableCell>
                      <TableCell align="left">
                        {zone.payment_status ? (
                          <Chip label={"PAID"} className={classes.chip} />
                        ) : (
                          <Chip label={"NOT PAID"} className={classes.chip} />
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => {
                            showRequestDetails(zone?.application_id);
                          }}
                        >
                          View details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </div>
  );
};
