import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Paper,
  Typography,
  Chip,
  TextField,
  TextareaAutosize,
  Button,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import circleCheck from "../../../images/check-circle.svg";
import downoadCloud from "../../../images/download-cloud.svg";
import avatar from "../../../images/avatar.svg";
import { useState } from "react";
import { AppConfig } from "app-config";
import Swal from "sweetalert2";
import { useEffect } from "react";
import moment from "moment";
import axiosInstance from "Api/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: "3rem",
    paddingInline: "2rem",
    background: "white",
    display: "flex",
    gap: "2em",
  },
  tabs: {
    marginTop: "3rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginBottom: "1rem",
    height: "100%",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  heading: {
    textAlign: "center",
    paddingTop: 32,
  },
  greyText: {
    fontWeight: "500",
    fontSize: "16px",
    color: "#B0AEAE",
    marginTop: "16px",
  },
  blackText: {
    fontWeight: "500",
    fontSize: "14px",
    display: "block",
    color: "black",
    marginTop: "12px",
  },
  greenBorder: {
    borderLeft: "3px solid #47BB78",
    padding: "10px",
    color: "#47BB78",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "18px",
  },
  boxIndent: {
    paddingInline: "31px",
  },
  greyBg: {
    background: "rgba(250, 250, 250, 0.8)",
    marginBottom: "14px",
  },
  halfWidth: {
    width: "50%",
  },
  documentRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "20px",
  },
  boldText: {
    fontWeight: "600",
    fontSize: "14px",
    display: "block",
    color: "black",
  },

  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  chip: {
    background: "rgba(71, 187, 120, 0.3)",
    borderRadius: "29px",
    color: " #47BB78",
  },
  textarea: {
    padding: "10px 0px 10px 17px",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
  },
  button: {
    marginTop: "27px",
  },
  inputField: {
    marginBottom: "20px",
    width: "100%",
    background: "white",
    border: "1px solid black",
    "& .MuiFilledInput-multiline": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
      marginBottom: "10px",
    },
  },
  textField: {
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      marginBottom: "10px",
      backgroundColor: "white",
    },
  },
}));

const EnterpriseAppInfo = ({ id, setView }) => {
  const classes = useStyles();
  const [docTypes, setDocTypes] = useState([]);
  const [approvalList, setApprovalList] = useState([]);
  const [zones, setZones] = useState([]);
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [service, setService] = useState("");
  const [defaultValue, setDefaultValue] = useState(null);
  const [hasLicense, setHasLicense] = useState(null);
  const [applicationID, setApplicationID] = useState(null);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  console.log(defaultValue);

  const selectDocument = (e) => {
    setDocument(e.target.value);
  };

  const handleSend = (e) => {
    setDefaultValue(e.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  const getZones = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/super-admin/application-details?entity_type=enterprise&application_id=` +
          Number(id),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getAdmin().access_token}`,
          },
        }
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data);
        console.log("DATAID", data[0].id);
        applicationState(data);
        setZones(data);
        setApplicationID(data[0].id);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to get zones", "error");
      setLoading(false);
    }
  };

  console.log("SET APPLICATION ID", applicationID);

  const generateLicense = async () => {
    console.log("LOG__ID", applicationID);
    setSendLoading(true);
    axiosInstance

      // .post(`/license/generate/${Number(id)}/${service}`)
      .post(`/application_approval/add`, {
        approval_instance_id: 4,
        comment: "approved by legal",
        application_id: applicationID,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire(
            "License generated",
            "Your request was processed successfully",
            "success"
          ).then((res) => (res.isConfirmed ? setView(null) : null));
        } else {
          Swal.fire("", response.message, "info");
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire(
          "Sorry this action could not be completed",
          error.message,
          "error"
        );
      })
      .finally(() => setSendLoading(false));
  };

  const applicationState = async (data) => {
    // setApprovalLoading(true);
    console.log(data[0].service.service_type.service_type, "servie");
    const service = data[0].service.service_type.service_type;
    setService(service);
    try {
      const response = await fetch(
        `${AppConfig.URL}/license/list/${Number(id)}/${service}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getAdmin().access_token}`,
          },
        }
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data, message);
        setHasLicense(data.has_license);
      } else {
        Swal.fire("", message, "info");
      }
      // setApprovalLoading(false);
    } catch (error) {
      // Swal.fire("Something went wrong", "Unable to get zones", "error");
      // setApprovalLoading(false);
    }
  };

  const getApprovalList = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/approval_instances/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAdmin().access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setApprovalList(data);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to approval actions", "error");
    }
  };

  const approval = async () => {
    setSendLoading(true);
    axiosInstance

      .post(`/application_approval/add`, {
        comment,
        approval_instance_id: defaultValue,
        application_id: id,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire(
            "Approval recorded",
            "Your approval has been recorded successfully",
            "success"
          ).then((res) => (res.isConfirmed ? setView(null) : null));
        } else {
          Swal.fire("", response.message, "info");
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire(
          "Your approval cold not be processed",
          error.message,
          "error"
        );
      })
      .finally(() => setSendLoading(false));
  };

  useEffect(() => {
    getZones();
    getApprovalList();
  }, []);

  console.log("ZONES", zones);

  if (!loading && zones.length === 0) {
    return (
      <div className={classes.norec}>
        <Typography component="h1" variant="h3">
          No record found
        </Typography>

        <Button
          className={classes.button}
          color="primary"
          onClick={getZones}
          variant="contained"
        >
          Go Back
        </Button>
      </div>
    );
  } else if (loading || !zones.length) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Enterprise information</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Enterprise name
              <span className={classes.blackText}>
                {" "}
                {zones[0].company.name}
              </span>
            </p>
            <p className={classes.greyText}>
              RCI numbers
              <span className={classes.blackText}>
                {zones[0].company.rc_no}
              </span>
            </p>
            <p className={classes.greyText}>
              Enterprise address
              <span className={classes.blackText}>
                {zones[0].company.address}
              </span>
            </p>
            {zones[0].company.fzc ? (
              <p className={classes.greyText}>
                FZC
                <span className={classes.blackText}>Semira enterprise</span>
              </p>
            ) : null}
            <p className={classes.greyText}>
              Email
              <span className={classes.blackText}>
                {zones[0].company.email}
              </span>
            </p>
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Application details</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Application type
              <span className={classes.blackText}>
                {zones[0].service.service_type.service_type}
              </span>
            </p>
            <p className={classes.greyText}>
              Remitta code
              <span className={classes.blackText}>{zones[0].remitta_code}</span>
            </p>
            <p className={classes.greyText}>
              Application fee
              <span className={classes.blackText}>
                {formatAsMoney(zones[0].fee)}
              </span>
            </p>
            <p className={classes.greyText}>
              Application date
              <span className={classes.blackText}>
                {moment(zones[0].application_date).format("DD/MM/YY")}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.halfWidth}>
        <div
          className={classes.greyBg}
          style={{ overflowY: "scroll", height: "20rem" }}
        >
          <div className={classes.greenBorder}>Uploaded documents</div>
          <div className={classes.boxIndent}>
            {zones[0].application_attachments.map((item) => (
              <a
                href={item.upload_url}
                className={classes.documentRow}
                download
              >
                <img src={circleCheck} alt="circle-icon" />
                <span className={classes.boldText}>
                  {item.document ? item.document : "Document name not captured"}
                </span>
                <img src={downoadCloud} alt="circle-icon" />
              </a>
            ))}
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Approval Trail</div>
          <div className={classes.boxIndent}>
            {zones[0].application_approvals.map((item) => (
              <div className={classes.space}>
                <div className={classes.documentRow}>
                  <img src={circleCheck} alt="circle-icon" />
                  <span className={classes.boldText}>
                    {item?.approval_instance?.approval_stage?.approval_stage}
                  </span>
                </div>
                <Chip label={"Approved"} className={classes.chip} />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Authority remarks</div>
          <div className={classes.boxIndent}>
            {zones[0].application_approvals.map((item) => (
              <>
                <div className={classes.documentRow}>
                  <img src={avatar} alt="circle-icon" />
                  {item?.approval_instance?.approval_stage?.approval_stage}
                </div>
                <span className={classes.greyText}>{item?.comment}</span>
              </>
            ))}
          </div>
        </div>
        {getAdmin().data.role_id == 5 ? (
          <>
            <div className={classes.greyBg}>
              <Button
                // autoFocus
                fullWidth
                variant={"contained"}
                color="primary"
                disabled={!hasLicense || sendLoading}
                onClick={generateLicense}
                className={classes.button}
                size="large"
              >
                {!hasLicense || sendLoading ? (
                  <div className={classes.spinner}>
                    <CircularProgress
                      style={{ height: "30px", width: "30px", margin: 0 }}
                    />
                  </div>
                ) : hasLicense == "no" ? (
                  "Issue certificate/License"
                ) : (
                  "Renewal service"
                )}
              </Button>
              {/* <Button
                // autoFocus
                fullWidth
                color="primary"
                variant="outlined"
                // onClick={handleSubmit}
                className={classes.button}
                size="large"
              >
                
              </Button> */}
            </div>
          </>
        ) : (
          <div className={classes.greyBg}>
            <div className={classes.greenBorder}>Remarks</div>

            <div style={{ padding: "5px 20px" }}>
              <form noValidate autoComplete="off">
                {/* <p>
                Document re-upload required?
                <Checkbox
                  checked={checked}
                  // onChange={handleChange}
                  onChange={() => setChecked(!checked)}
                  color="primary"
                />
              </p> */}
                {checked && (
                  <TextField
                    id="outlined-error-helper-text"
                    select
                    className={classes.select}
                    variant="outlined"
                    label="Select a Document"
                    name="DocumentId"
                    value={document || ""}
                    onChange={(e) => selectDocument(e)}
                    disabled={loading}
                  >
                    {docTypes.map((option) => (
                      <MenuItem key={option.id} value={option.id || ""}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}

                <TextField
                  rows={4}
                  multiline
                  onChange={handleCommentChange}
                  className={classes.inputField}
                  label="Remarks"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid black",
                    background: "white",
                    padding: "5px 10px",
                  }}
                >
                  <TextField
                    select
                    onChange={handleSend}
                    variant="filled"
                    className={classes.textField}
                    fullWidth
                    style={{ fontStyle: "10px", background: "white" }}
                    InputProps={{ disableUnderline: true }}
                  >
                    {approvalList.map((list) => (
                      <MenuItem value={list.approval_instance_id}>
                        {list.approval_type}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <Button
                  // autoFocus
                  disabled={sendLoading}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={approval}
                  className={classes.button}
                  size="large"
                >
                  {sendLoading ? (
                    <div className={classes.spinner}>
                      <CircularProgress
                        style={{ height: "30px", width: "30px", margin: 0 }}
                      />
                    </div>
                  ) : (
                    "Send"
                  )}
                </Button>
                {/* {approvalLoading ? (
                <Button>Loading...</Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="secondary"
                >
                  Submit
                </Button>
              )} */}
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnterpriseAppInfo;
