import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CircularProgress,
  LinearProgress,
  Typography,
  Box,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ZoneAdminEntIcon from "../../images/zoneAdminTotalEnt.svg";
import ZoneAdminPendingIcon from "../../images/zoneAdminPendingIcon.svg";
import ZoneAdminApprovedIcon from "../../images/zoneAdminApprovedIcon.svg";
import ZoneAdminCertifiedIcon from "../../images/zoneAdminCertifiedIcon.svg";
import GeneratedRevenueIcon from "../../images/generatedRevenueIcon.svg";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Tables from "./NepzaTable";
import ArrowDownIcon from "../../images/arrowdown.svg";
import { Link, useLocation } from "react-router-dom";

import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart";
import { formatAsMoney } from "utils/money";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "3rem",
  },
  cardGrid: {
    marginBottom: "20px",
  },
  stats: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 2rem",
    margin: "2rem 0",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    minHeight: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    fontSize: "30px",
    alignItems: "center",
    gap: "1rem",
    paddingTop: "20px",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: "#B0AEAE",
    fontSize: "0.9rem",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  select: {
    padding: "0.8rem 2.5rem",
    border: "1px solid #B0AEAE",
    borderRadius: "5px",
    color: "#B0AEAE",
    appearance: "none",
    backgroundImage: `url(${ArrowDownIcon})`,
    backgroundSize: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 8px) center",
    outline: "none",
  },
}));

const TotalRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: blue[500] },
}))(LinearProgress);
const ProcessedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: green[500] },
}))(LinearProgress);
const InProgreeRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: purple[500] },
}))(LinearProgress);
const DeclinedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: red[500] },
}))(LinearProgress);

const icons = [
  ZoneAdminEntIcon,
  ZoneAdminPendingIcon,
  ZoneAdminApprovedIcon,
  ZoneAdminCertifiedIcon,
  GeneratedRevenueIcon,
];

export default () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState({});
  const [charts, setCharts] = useState([]);

  const user = localStorage.getItem("userDetails");

  const userData = JSON.parse(user);
  const classes = useStyles();

  //get zone admin dashboard cards
  const getStats = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/zone-admin/dashboard`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status) {
        setStat(data);
        // setCharts(data.charts);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to load record", "error");
      setLoading(false);
    }
  };

  //get charts data
  const getChartsData = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/zone-admin/chart`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status) {
        setCharts(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getChartsData();
  }, []);

  console.log("CHART DATA", charts);

  const formatMoney = (money) => {
    return `$ ${money.toLocaleString()}`;
  };

  React.useEffect(() => {
    getStats();
  }, []);

  console.log("STATS", stat);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  const statCards = Object.keys(stat);

  console.log("STAT__CARDS", statCards);

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        <Link
          to={{
            pathname: "/admin-account/zone-admin-ent",
            state: {
              activeTab: "total_enterprise",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.cards}>
                <img src={ZoneAdminEntIcon} alt="icon" />
                <div>
                  <Typography
                    variant="h4"
                    style={{ color: "black", fontSize: "1.2rem" }}
                  >
                    {stat?.total_enterprise}
                  </Typography>
                  <div className={classes.cardContent}>
                    <Typography className={classes.text}>
                      Total Enterprise
                    </Typography>

                    <div style={{ marginLeft: "20px" }}>
                      <KeyboardArrowRightIcon
                        style={{ fontSize: 20, color: "#B0AEAE" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Link>

        <Link
          to={{
            pathname: "/admin-account/zone-admin-ent",
            state: {
              activeTab: "pending_enterprise",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.cards}>
                <img src={ZoneAdminPendingIcon} alt="icon" />
                <div>
                  <Typography
                    variant="h4"
                    style={{ color: "black", fontSize: "1.2rem" }}
                  >
                    {stat?.pending_enterprise}
                  </Typography>
                  <div className={classes.cardContent}>
                    <Typography className={classes.text}>
                      Pending enterprise
                    </Typography>

                    <div style={{ marginLeft: "20px" }}>
                      <KeyboardArrowRightIcon
                        style={{ fontSize: 20, color: "#B0AEAE" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Link>

        <Link
          to={{
            pathname: "/admin-account/zone-admin-ent",
            state: {
              activeTab: "approved_enterprise",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.cards}>
                <img src={ZoneAdminApprovedIcon} alt="icon" />
                <div>
                  <Typography
                    variant="h4"
                    style={{ color: "black", fontSize: "1.2rem" }}
                  >
                    {stat?.approved_enterprise}
                  </Typography>
                  <div className={classes.cardContent}>
                    <Typography className={classes.text}>
                      Approved enterprises
                    </Typography>

                    <div style={{ marginLeft: "20px" }}>
                      <KeyboardArrowRightIcon
                        style={{ fontSize: 20, color: "#B0AEAE" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Link>

        <Link
          to={{
            pathname: "/admin-account/zone-admin-ent",
            state: {
              activeTab: "certified_enterprise",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.cards}>
                <img src={ZoneAdminCertifiedIcon} alt="icon" />
                <div>
                  <Typography
                    variant="h4"
                    style={{ color: "black", fontSize: "1.2rem" }}
                  >
                    {stat?.certified_enterprise}
                  </Typography>
                  <div className={classes.cardContent}>
                    <Typography className={classes.text}>
                      Certified enterprises
                    </Typography>

                    <div style={{ marginLeft: "20px" }}>
                      <KeyboardArrowRightIcon
                        style={{ fontSize: 20, color: "#B0AEAE" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Link>

        <Link
          to={{
            pathname: "/admin-account/zone-admin-ent",
            state: {
              activeTab: "revenue",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.paper} elevation={0}>
              <div className={classes.cards}>
                <img src={GeneratedRevenueIcon} alt="icon" />
                <div>
                  <Typography
                    variant="h4"
                    style={{ color: "black", fontSize: "1.2rem" }}
                  >
                    ${stat?.revenue}
                  </Typography>
                  <div className={classes.cardContent}>
                    <Typography className={classes.text}>
                      Generated revenues
                    </Typography>

                    <div style={{ marginLeft: "20px" }}>
                      <KeyboardArrowRightIcon
                        style={{ fontSize: 20, color: "#B0AEAE" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Link>
      </Grid>

      <Box>
        <Paper className={classes.paper}>
          <div className={classes.stats}>
            <div>
              <Typography>Enterprise statistics</Typography>
            </div>
            <div>
              <form>
                <select id="cat" name="cat" className={classes.select}>
                  <option value="volvo">Sort by ...</option>
                  <option value="name">Name</option>
                  <option value="date">Date</option>
                </select>
              </form>
            </div>
          </div>

          <div>
            <Chart id="chart" dataSource={charts} style={{ padding: "0 2rem" }}>
              <ArgumentAxis tickInterval={5}>
                <Label format="decimal" />
              </ArgumentAxis>
              <Series
                valueField="val"
                argumentField="month"
                // name={item.title}
                type="bar"
                color="#ffaa66"
              />
              <Legend visible={false} />
            </Chart>
          </div>
        </Paper>
      </Box>
    </div>
  );
};
