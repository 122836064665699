import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, CircularProgress, Grid, colors,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import axiosInstance from '../../Api/Api.js';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableFont: {
        fontSize: '18px',
    },
    tableHeader: {
        paddingLeft: '0',
        fontSize: '18px',
        width: '100%',
        marginLeft: '10px',
    },
    footer: {
        textAlign: 'right',
        marginTop: 8,
        paddingBottom: 8
    },
    btn: {
        marginLeft: 3
    },
    pageInput: {
        border: '1px solid #48BB78',
        width: 70
    }
});

export default function DataList({ type, reference }) {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [allRequests, setAllRequests] = useState([]);
    const [allData, setAllData] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [lastPage, setLastPage] = useState('')
    const [currentPage, setCurrentPage] = useState('')
    const [toPage, setToPage] = useState('')
    const [inputError, setInputError] = useState(false)

    const history = useHistory();

    const formatDate = (date) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };
        if (date) {
            return new Date(date).toLocaleDateString(undefined, options);
        }
        return null;
    };

    const showRequestDetails = (rowId) => {
        console.log(rowId);
        history.push(`/admin-account/request-action/${rowId}`);
    };

    const handlePage = (next) => {
        if (next && pageNumber < lastPage) {
            setPageNumber(prevPage => prevPage + 1)
        } else if (pageNumber > 1) {
            setPageNumber(prevPage => prevPage - 1)
        }
    }

    const goToPage = () => {
        if (toPage >= 1 && toPage <= lastPage) setPageNumber(toPage)
        else setInputError(true)
    }

    useEffect(() => {
        fetchAllRequests();
    }, [pageNumber]);

    const fetchAllRequests = () => {
        setInputError(false)
        let newValue = allData.find(item => item.current_page === pageNumber)
        if (newValue) {
            setAllRequests(newValue.data);
            setLastPage(newValue.last_page)
            setCurrentPage(newValue.current_page)
            return
        }
        setLoading(true);
        let url
        type == 'search' ? url = `/application/search?reference=${reference}&page=${pageNumber}` : url = `/application/list?status=${type}&page=${pageNumber}`
        axiosInstance
            // .get('/application/list')
            // .get(`/application/list?page=${pageNumber}`)
            .get(url)
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response);
                    setAllRequests(response.data.data.data);
                    setLastPage(response.data.data.last_page)
                    setCurrentPage(response.data.data.current_page)
                    setAllData(prevData => [...prevData, response.data.data])
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchAllRequests()
    }, []);

    return loading ? (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: '100%' }}
        >
            <CircularProgress color="primary" />
        </Grid>
    ) : !allRequests.length ? (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: '100%' }}
        >
            <Typography variant="h6">
                No {type === 'all' ? null : type === 'search' ? 'such' : type} application found
            </Typography>
        </Grid>
    ) : (
        <>
            <TableContainer component={Paper} variant="outlined">
                <Table className={classes.table} aria-label="data-table">
                    <TableHead>
                        <TableRow className={classes.tableFont}>
                            <TableCell className={classes.tableFont}>S/N</TableCell>
                            <TableCell className={classes.tableFont}>Reference No</TableCell>
                            <TableCell className={classes.tableFont}>
                                Application Date
                            </TableCell>
                            <TableCell className={classes.tableFont}>
                                Service Requested
                            </TableCell>
                            <TableCell className={classes.tableFont}>
                                Processing Fee
                             </TableCell>
                            <TableCell className={classes.tableFont}>Actual Fee</TableCell>
                            <TableCell className={classes.tableFont}>Remitta Code</TableCell>
                            <TableCell className={classes.tableFont}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allRequests.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.reference}
                                </TableCell>
                                <TableCell>{formatDate(row.application_date)}</TableCell>
                                {/* <TableCell>{row.service.name}</TableCell> */}
                                <TableCell>{row?.service_name || row?.service?.name}</TableCell>
                                <TableCell>
                                    {row.processing_fee
                                        ? '$' +
                                        new Number(row.processing_fee).toLocaleString('en-US')
                                        : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {'$' + new Number(row.fee).toLocaleString('en-US')}
                                </TableCell>

                                <TableCell>
                                    {row.remitta_code ? row.remitta_code : 'Not Generated'}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => showRequestDetails(row.id)}
                                    >
                                        View Details
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.footer}>
                <Button className={classes.btn} color="primary" variant="outlined" onClick={() => handlePage(false)}>Previous</Button>
                <Button className={classes.btn} color="primary" variant="outlined" onClick={() => handlePage(true)}>Next</Button>
                <p>page {currentPage} of {lastPage}</p>
                <p>
                    {inputError ? <Alert severity="warning">Please input a valid page number</Alert> : null}
                    <input type="number" className={classes.pageInput} onChange={(e) => setToPage(e.target.value)} placeholder="Go to... " />
                    <Button className={classes.btn} color="primary" size="small" variant="outlined" onClick={goToPage}>Go</Button>
                </p>
            </div>

        </>
    );
};



