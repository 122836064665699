import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { AppConfig } from "app-config";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { PendingResponse } from "./Response";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    color: "#B0AEAE",
  },
  editButton: {
    border: "none",
    textTransform: "capitalize",
    background: "#47BB78",
    color: "white",
    "&:hover": {
      border: "none",
      textTransform: "capitalize",
      background: "#47BB78",
    },
  },
  deleteButton: {
    border: "1px solid #EB4B4B",
    background: "#EB4B4B",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      background: "#EB4B4B",
      color: "white",
    },
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
});

function createData(name, amount, status, date, actions) {
  return { name, amount, status, date, actions };
}

const ActionButton = () => {
  const classes = useStyles();
  return (
    <div>
      <Button className={classes.editButton}>Edit</Button>
      <Button className={classes.deleteButton}>Delete</Button>
    </div>
  );
};

const rows = [
  createData(
    "Semira enterprise",
    "893860592RW",
    "Oil and gas",
    "05/07/2022",
    <ActionButton />
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Industrial park",
    "05/07/2022",
    <ActionButton />
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Oil and gas",
    "05/07/2022",
    <ActionButton />
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Fabrication and waxing",
    "05/07/2022",
    <ActionButton />
  ),
  createData(
    "Semira enterprise",
    "893860592RW",
    "Oil and gas",
    "05/07/2022",
    <ActionButton />
  ),
];

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PendingRequestsTab = () => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [statusColor, setStatusColor] = React.useState("#47BB78");
  const [statusBackground, setStatusBackground] = React.useState(
    "rgba(71, 187, 120, 0.3)"
  );
  const [pendingStatusBackground, setPendingStatusBackground] = React.useState(
    "rgba(239, 163, 75, 0.3)"
  );
  const [pendingColor, setPendingColor] = React.useState("#EFA34B");
  const [totalRequest, setTotalRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mssg, setMssg] = useState("");

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (rows.status === "Pending") {
    // setStatusBackground("rgba(239, 163, 75, 0.3)")
    setStatusColor("#EFA34B");
  }

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  //format as money code
  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  //get total application requests
  const totalRequestInventory = async () => {
    setLoading(true);
    let url = `${AppConfig.URL}/zone-admin/request-inventory?status=pending`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setLoading(false);
        console.log("Total Request Mssg", message);
        setTotalRequest(data?.data);
        console.log("MYTOTALREQ", totalRequest);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    totalRequestInventory();
  }, []);

  //handle delete button
  const handleButtonDelete = (id) => {
    const newList = totalRequest?.filter((list) => list.id !== id);
    console.log("New List", newList);
    console.log("Clicked ID", id);
    setTotalRequest(newList);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMssg(PendingResponse.text);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading && (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )}
      {/* use this when endpoints are ready*/}

      {totalRequest?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  Enterprise Name
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  RCI Number
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Enterprise speciality
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Renewal Date
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? totalRequest?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : totalRequest
              )?.map((row) => (
                <TableRow key={row?.id}>
                  <TableCell align="left" component="th" scope="row">
                    {row?.name}
                  </TableCell>
                  <TableCell align="left">
                    {row?.rc_no ? row?.rc_no : `N/A`}
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      style={{
                        color:
                          row?.status === "Completed"
                            ? statusColor
                            : row?.status === "Declined"
                            ? "#EB4B4B"
                            : "#EFA34B",
                        backgroundColor:
                          row?.status === "Completed"
                            ? statusBackground
                            : row?.status === "Declined"
                            ? "#EB4B4B4D"
                            : "rgba(239, 163, 75, 0.3)",
                        textAlign: "center",
                        fontSize: "13px",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {row?.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {moment(row?.opl_renewal_date).format("M/D/YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    <Button className={classes.editButton}>View details</Button>
                    {/* <Button
                      className={classes.deleteButton}
                      onClick={() => handleButtonDelete(row?.id)}
                    >
                      Delete
                    </Button> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={totalRequest?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Typography>{mssg}</Typography>
      )}

      {/* <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                Application type
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                Amount
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                Status
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                Date of request
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          

            {(rowsPerPage > 0
              ? rows?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : rows
            )?.map((row) => (
              <TableRow key={row?.id}>
                <TableCell component="th" scope="row">
                  {row?.name}
                </TableCell>
                <TableCell align="left">{row?.amount}</TableCell>
                <TableCell align="right">
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "13px",

                      textTransform: "capitalize",
                    }}
                  >
                    {row?.status}
                  </Typography>
                </TableCell>
                <TableCell align="center">{row?.date}</TableCell>
                <TableCell align="center">
                  <Button className={classes.editButton}>View deatils</Button>
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer> */}
    </>
  );
};

export default PendingRequestsTab;
