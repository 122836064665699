import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RequestDetailCard from "./RequestDetailCard";
import AdminViewDetailsCard from "./AdminViewDetailsCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { useParams, useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";

import axiosInstance from "../../Api/Api.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .makeStyles-paper-24": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .makeStyles-paper-25": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .makeStyles-paper-28": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
  },
  formRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  buttonal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  uploadLabel: {
    textAlign: "left !important",
    marginLeft: "10px",
  },
  requestDetail: {
    maxWidth: "100% !important",
  },
}));

export default function ApprovalAction(props) {
  const classes = useStyles();

  const [applicationId, setApplicationId] = useState(null);
  const [requestDetails, setRequestDetails] = useState("");

  const [loading, setLoading] = useState(false);

  const [applicationHistory, setApplicationHistory] = useState([]);

  const params = useParams();

  const history = useHistory();

  const fetchRequestDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`/application/get/${params.rowId}`)
      .then(function (response) {
        if (response.status === 200) {
          setApplicationHistory(response.data.data.history);
          setRequestDetails(response.data.data.application);
          console.log(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {})
      .finally(() => setLoading(false));
  };

  const [open, setOpen] = useState(false);
  const [erorrMessage, setErrorMessage] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setApplicationId(params.rowId);
    fetchRequestDetails();
  }, []);

  return loading ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <CircularProgress color="secondary" />
    </Grid>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h6">
              REQUEST DETAILS
              <Divider />
            </Typography>
            <AdminViewDetailsCard
              className={classes.requestDetail}
              requestDetails={requestDetails}
              applicationHistory={applicationHistory}
            />
          </Paper>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
