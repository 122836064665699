import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";

import axiosInstance from "../../Api/Api.js";
import Swal from "sweetalert2";
import { Grid, Switch, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      vertical="top"
      color="primary"
      variant="filled"
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  btn: {
    marginTop: "2rem",
  },
}));

export default function EditCreateProcessFlow(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    approval_stage_id: "",
    process_no: "",
    processmodule_id: "",
    ProcessFlow_id: "",
    status: false,
  });

  const [open, setOpen] = useState(false);
  const [submitting, isSubmitting] = useState(false);

  const [erorrMessage, setErrorMessage] = useState("");
  const [approvalStages, setApprovalStages] = useState([]);
  const [modules, setModules] = useState([]);
  const [zoneDetails, setZoneDetails] = useState([]);

  const params = useParams();
  const history = useHistory();
  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleToggle = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };
  const fetchApprovalStages = () => {
    setLoading(true);

    axiosInstance
      .get("/approval_stages/list")
      .then(function (response) {
        if (response.status === 200) setApprovalStages(response.data.data.data);
        else Swal.fire("Something went wrong", "Unable to fetch approval stages", "info");
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Something went wrong", "Unable to fetch approval stages", "info");
      })
      .finally(() => setLoading(false));
  };

  const fetchModules = () => {
    setLoading(true);

    axiosInstance
      .get("/approval_processmodule/list")
      .then(function (response) {
        if (response.status === 200) {
          setModules(response.data.data.data);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Something went wrong", "Unable to fetch process modules", "info");
      })
      .finally(() => setLoading(false));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const fetchFlowDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`/approval_processflow/get/${params.rowId}`)
      .then(function (response) {
        if (response.status === 200) {
          const {
            approval_stage_id,
            process_no,
            processmodule_id,
            ProcessFlow_id,
            status,
          } = response.data.data;

          setValues({
            // ...values,
            approval_stage_id,
            process_no,
            processmodule_id,
            ProcessFlow_id,
            status: status === 1 ? true : false,
          });
          console.log(response.data.data);
        } else {
          Swal.fire("Something went wrong", "Unable to get process flow", "info");
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Something went wrong", "Unable to get process", "error");
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isSubmitting(true);
    const {
      approval_stage_id,
      process_no,
      processmodule_id,
      ProcessFlow_id,
      status,
    } = values;
    const endpoint =
      params.rowId === "create"
        ? "/approval_processflow/add"
        : "/approval_processflow/update";
    const body =
      params.rowId === "create"
        ? {
            approval_stage_id,
            process_no,
            processmodule_id,
          }
        : {
            approval_stage_id,
            process_no,
            processmodule_id,
            process_flow_id: ProcessFlow_id,
            status: status ? 1 : 0,
          };
    const method = params.rowId === "create" ? "post" : "put";
    axiosInstance[method](endpoint, body)
      .then(function (response) {
        if (response.status === 200) {
          params.rowId === "create" &&
            setValues({
              approval_stage_id: "",
              process_no: "",
              processmodule_id: "",
            });
          setErrorMessage(response.data.message);
          setOpen(true);
          navigateToProcessFlows();
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => isSubmitting(false));
  };

  const navigateToProcessFlows = () => {
    history.push(`/admin-account/process-flow`);
  };

  useEffect(() => {
    fetchModules();
    fetchApprovalStages();
    if (params.rowId !== "create") {
      fetchFlowDetails();
    }
  }, []);

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div style={{ fontWeight: "bold" }}>
        {params.rowId === "create"
          ? "Create Process Flow"
          : "Edit Process Flow"}
      </div>
      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        label="Process Number"
        value={values.process_no}
        onChange={handleChange("process_no")}
      />

      <TextField
        select
        InputLabelProps={{
          shrink: true,
        }}
        label="Aprroval Stage"
        value={values.approval_stage_id}
        onChange={handleChange("approval_stage_id")}
      >
        {approvalStages.map((option, index) => (
          <MenuItem
            key={option.approval_stage_id}
            value={option.approval_stage_id}
          >
            {option.approval_stage}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        InputLabelProps={{
          shrink: true,
        }}
        label="Process Module"
        value={values.processmodule_id}
        onChange={handleChange("processmodule_id")}
      >
        {" "}
        {modules.map((option) => (
          <MenuItem
            key={option.processmodule_id}
            value={option.processmodule_id}
          >
            {option.processmodule}
          </MenuItem>
        ))}
      </TextField>
      {params.rowId !== "create" && (
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>In-active</Grid>
            <Grid item>
              <CustomizedSwitch
                checked={values.status}
                onChange={handleToggle}
                name="status"
              />
            </Grid>
            <Grid item>Active</Grid>
          </Grid>
        </Typography>
      )}

      <div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          className={classes.btn}
          disabled={
            !(
              values.process_no &&
              values.approval_stage_id &&
              values.processmodule_id
            )
          }
        >
          {submitting ? (
            <CircularProgress color="secondary" />
          ) : params.rowId === "create" ? (
            "Create Process Flow"
          ) : (
            "Save"
          )}
        </Button>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </form>
  );
}

const CustomizedSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: "white",
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "white",
  },
  checked: {},
}))(Switch);
