import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppConfig } from "app-config";
import { Typography, Button } from "@material-ui/core";
import logo from "../../images/nepza-logo.jpg";
import arms from "../../images/coat-arms.png";
import PdfDownloader from "../../utils/PdfDownloader";
import nepza from "../../images/nepza-certificate.png";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const useStyles = makeStyles({
  mainContainer: {
    backgroundImage: `url(${nepza})`,
    minHeight: "200px",
    overflow: "auto",
    // backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "900px",
    height: "1300px",
    position: "relative",
    fontFamily: "Inter",
  },
  heading: {
    fontSize: "1.3rem",
    color: "#45B675",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "4rem",
  },
  // download: {
  //   position: "absolute",
  //   left: "90%",
  //   top: "20px",
  // },
  reg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // backgroundColor: "white",

    padding: "2rem",
    // background: `url(${logo})`,
  },
  title: {
    color: "green",
    fontWeight: "bold",
    fontSize: "2rem",
  },
  cert: {
    color: "#000",
    fontSize: "2rem",
    // marginTop: "9rem",
    fontWeight: "bold",
  },
  delcare: {
    fontSize: "2rem",
    marginTop: "1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  details: {
    margin: "5rem",
    textAlign: "left",
    lineHeight: "30px",
    color: "black !important",
  },
  name: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginTop: "3rem",
  },
  imageContainer: {
    marginBottom: "6rem",
    height: "150px",
  },
  image: {
    height: "100%",
  },
  date: {
    fontWeight: "bold",
    marginTop: "3rem",
  },
  signature: {
    textAlign: "center",
    marginTop: "6rem",
  },
});

const ViewCertificateOfRegistration = () => {
  const [cert, setCert] = useState({});
  const pdfRef = useRef();
  const [applicationId, setApplicationId] = useState(null);
  const [paymentData, setPaymentData] = useState({});
  const [services, setServices] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [fee, setFee] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [expirationDate, setExpirationDate] = useState("");

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  //ref
  const buttonRef = useRef(null);

  //ref button function
  const btnFunc = () => {
    buttonRef.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  };

  let service_name =
    userData.data.role.role_id === 14
      ? "ZMC OPL RENEWAL"
      : "Enterprise OPL Renewal";

  console.log("SERVICENAME", service_name);

  const servicesURL = `${AppConfig.URL}/services/list?service_name=${service_name}`;

  const getServices = async () => {
    try {
      const response = await axios.get(servicesURL);
      console.log("services response", response);
      setServices(response?.data);
      setServiceId(response?.data?.data[0].id);
      setFee(response?.data?.data[0].actual_fee);
      setServiceName(response?.data?.data[0].name);
      setApplicationId(response?.data?.data[0].id);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const handleButtonClick = async (e) => {
    e.preventDefault();

    try {
      let info;

      let getUrl = AppConfig.URL + "/application/add";

      info = {
        company_id: userData["data"].company_id,
        service_id: serviceId,
        amount: fee.toString(),
      };

      let fetchData = await fetch(getUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },

        body: JSON.stringify(info),
      }).then((res) => res.json());

      console.log("FETCHED DaTA", fetchData);

      info = {
        application_id: fetchData?.data?.id,
        zone_id:
          userData.data.role.role_id === 14
            ? userData.data.company.zmc_profile.zone_id
            : userData["data"]?.last_success_search?.company?.zone?.zone_id,
        isRenewal: 1,
        zmc_profile_id:
          userData.data.role.role_id === 14
            ? userData.data.company.zmc_profile.id
            : userData["data"].last_success_search.company.zone.zmc_profile_id,
      };

      let companyType =
        userData.data.role.role_id === 14 ? "zmc" : "enterprise";

      let submitUrl = `${AppConfig.URL}/submit-reg/opl-application/${companyType}`;

      let submitData = await fetch(submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },

        body: JSON.stringify(info),
      }).then((res) => res.json());
      if (submitData.status) {
        setPaymentData(submitData);
        Swal.fire({
          title: "Submission was Received",
          text: `${submitData.message}  ${submitData.data.rrr}`,
          confirmButtonColor: "#48BB78",
          confirmButtonText: "Proceed to pay",
          iconHtml: `<img src='/check.png'/>`,
          showCancelButton: true,
          cancelButtonText: "",
        }).then((res) => (res.isConfirmed ? btnFunc() : null));
      }
    } catch (err) {
      console.log(err);
    }
  };

  let url = `${AppConfig.URL}/opl-reg/status`;

  const getCert = async () => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },
      });

      const { status, message, data } = await response.json();
      if ((data["cert_of_reg"] === true) | (data["opl_certificate"] === true)) {
        setCert(data.opl_cert);
        setExpirationDate(data["opl_cert"]["expiring_date"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCert();
  }, []);

  console.log(cert, "erg");
  console.log("expiration", expirationDate);

  const dateToCompare = new Date(expirationDate);
  console.log("dateToCompare", dateToCompare);

  const currentDate = new Date();

  //format date
  const formatDate = (date) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (date) {
      return new Date(date).toLocaleDateString(undefined, options);
    }
    return null;
  };

  //download function
  const downloadPDF = (e) => {
    e.preventDefault();
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("certificate.pdf");
    });
  };

  const roleId = userData.data.role_id;
  const classes = useStyles();
  if (roleId === 14 || roleId === 8) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "2px",
          }}
          ref={pdfRef}
        >
          <Button
            onClick={downloadPDF}
            style={{
              background: "#46BA77",
              color: "#fff",
              marginBottom: "75rem",
            }}
          >
            Dowload Certificate
          </Button>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={classes.mainContainer}>
              {/* <PdfDownloader
            downloadFileName="CustomPdf" 
            rootElementId="doctId" 
          /> */}

              {/* <div className={classes.download}>
          <CloudDownloadIcon />
        </div> */}

              <span
                style={{ position: "absolute", top: "200px", right: "120px" }}
              >
                NEPZA/OPL: {cert.rcc_number}
              </span>

              <Typography className={classes.heading}>
                NIGERIA EXPORT PROCESSING ZONES AUTHORITY (NEPZA)
              </Typography>

              <div className={classes.reg} id="doctId">
                <div className={classes.imageContainer}>
                  <img src={logo} alt="logo" className={classes.image} />
                </div>

                <Typography className={classes.cert}>
                  NATIONAL OPERATING LICENSE
                </Typography>

                <Typography className={classes.delcare}>
                  I hereby Certify that
                </Typography>

                <Typography className={classes.name}>{cert?.bearer}</Typography>

                <Typography className={classes.details}>
                  Is this day licensed under the NIGERIA EXPORT PROCESSING ZONES
                  AUTHORITY Act 63 of 1992 as{" "}
                  <strong>FZE in {cert?.zone?.zone_name}</strong> Subject to
                  among other conditions, the mandatory requirment of resolving
                  all disputes claims, or differences arising from, relating to
                  or connected with the operation and management of all Zones or
                  approved activities within the Zones under the NEPZA
                  Arbitration Rules and Mediation Guidelines.
                </Typography>

                <div style={{ textAlign: "left" }}>
                  <Typography>
                    Date this{" "}
                    <span style={{ textDecoration: "underline" }}>
                      {formatDate(cert?.date_approved)}
                    </span>
                  </Typography>
                  <Typography
                    style={{ marginRight: "20rem", fontFamily: "Inter" }}
                  >
                    This License Expires on{" "}
                    <span style={{ textDecoration: "underline" }}>
                      {formatDate(cert?.expiring_date)}
                    </span>
                  </Typography>
                </div>

                <Typography
                  className={classes.signature}
                  style={{ textAlign: "center", marginTop: "3rem" }}
                >
                  Authorised Signature
                </Typography>
              </div>
            </div>
          </div>
          {/* {dateToCompare < currentDate ? ( */}
          <Button
            style={{
              marginTop: "1rem",
              background: "#46BA77",
              color: "#fff",
              marginBottom: "75rem",
            }}
            onClick={handleButtonClick}
          >
            Renewal OPL
          </Button>
          {/* ) : null} */}
        </div>

        <form
          enctype="multipart/form-data"
          method="post"
          name="SubmitRemitaForm"
          // action={`https://remitademo.net/remita/onepage/api/v1/payment.spa`}
          action={`${paymentData?.data?.action}/finalize.reg`}
          className={classes.formContainer}
          target="_blank"
        >
          <input
            type="hidden"
            value={`${paymentData?.data?.merchantId}`}
            name="merchantId"
          />
          <input
            type="hidden"
            value={`${paymentData?.data?.hash}`}
            name="hash"
          />
          <input type="hidden" value={`${paymentData?.data?.rrr}`} name="rrr" />
          {/* <input type="hidden" value="https://nepzaopms.com/finalize" name="responseurl" /> */}
          <input
            type="hidden"
            value={`${window.location.origin}/finalize?amount=${fee}&name=${serviceName}`}
            name="responseurl"
          />
          <input
            type="submit"
            className={classes.button}
            value="Pay Now"
            name="submit_btn"
            style={{ display: "none" }}
            ref={buttonRef}
          />
        </form>
      </>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={classes.mainContainer}>
        {/* <PdfDownloader
          downloadFileName="CustomPdf" 
          rootElementId="doctId" 
        /> */}

        {/* <div className={classes.download}>
        <CloudDownloadIcon />
      </div> */}

        <span style={{ position: "absolute", top: "200px", right: "120px" }}>
          NEPZA/OP/L: 00012/2
        </span>

        <Typography className={classes.heading}>
          NIGERIA EXPORT PROCESSING ZONES AUTHORITY (NEPZA)
        </Typography>

        <div className={classes.reg} id="doctId">
          <div className={classes.imageContainer}>
            <img src={logo} alt="logo" className={classes.image} />
          </div>

          <Typography className={classes.cert}>
            NATIONAL OPERATING LICENSE
          </Typography>

          <Typography className={classes.delcare}>
            I hereby Certify that
          </Typography>

          <Typography className={classes.name}>
            {cert?.certificate?.bearer_corporate_name}
          </Typography>

          <Typography className={classes.details}>
            Is this day licensed under the NIGERIA EXPORT PROCESSING ZONES
            AUTHORITY Act 63 of 1992 as{" "}
            <strong>FZE in {cert?.zone?.zone_name}</strong> Subject to among
            other conditions, the mandatory requirment of resolving all disputes
            claims, or differences arising from, relating to or connected with
            the operation and management of all Zones or approved activities
            within the Zones under the NEPZA Arbitration Rules and Mediation
            Guidelines.
          </Typography>

          <Typography style={{ marginTop: "6rem" }}>
            Date this{" "}
            <span style={{ textDecoration: "underline" }}>May 27th, 2023</span>
          </Typography>
          <Typography>
            This License Expires on{" "}
            <span style={{ textDecoration: "underline" }}>May 27th, 2024</span>
          </Typography>
          <Typography className={classes.signature}>
            Authorised Signature
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ViewCertificateOfRegistration;
