import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CircularProgress,
  LinearProgress,
  Typography,
  Button,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ZoneAdminEntIcon from "../../../images/zoneAdminTotalEnt.svg";
import ZoneAdminPendingIcon from "../../../images/zoneAdminPendingIcon.svg";
import ZoneAdminApprovedIcon from "../../../images/zoneAdminApprovedIcon.svg";
import ZoneAdminCertifiedIcon from "../../../images/zoneAdminCertifiedIcon.svg";
import GeneratedRevenueIcon from "../../../images/generatedRevenueIcon.svg";
import TotalZmcReqIcon from "../../../images/totalZmcReq.svg";
import ArrowDownIcon from "../../../images/arrowdown.svg";
// import SelectBox from "devextreme-react/select-box";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart";
import { ValidationRule } from "devextreme-react/gantt";
import Charts from "../Charts";
import { PieChartSharp } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
  },
  cardGrid: {
    marginBottom: "20px",
  },
  stats: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 2rem",
    margin: "2rem 0",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    minHeight: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    fontSize: "30px",
    alignItems: "center",
    gap: "1rem",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inline: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    background: "rgba(245, 244, 244, 0.8)",
    marginBottom: "51px",
  },
  select: {
    padding: "0.8rem 2.5rem",
    border: "1px solid #B0AEAE",
    borderRadius: "5px",
    color: "#B0AEAE",
    appearance: "none",
    backgroundImage: `url(${ArrowDownIcon})`,
    backgroundSize: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 8px) center",
    outline: "none",
  },
}));

const TotalRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: blue[500] },
}))(LinearProgress);
const ProcessedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: green[500] },
}))(LinearProgress);
const InProgreeRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: purple[500] },
}))(LinearProgress);
const DeclinedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: red[500] },
}))(LinearProgress);

const icons = [
  ZoneAdminEntIcon,
  TotalZmcReqIcon,
  ZoneAdminApprovedIcon,
  ZoneAdminCertifiedIcon,
  GeneratedRevenueIcon,
];

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState([]);
  const [charts, setCharts] = useState({});
  const [filter, setFilter] = useState("private");
  const user = localStorage.getItem("userDetails");

  const history = useHistory();

  const userData = JSON.parse(user);
  const classes = useStyles();

  //get dashboard stats
  const getStats = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/zone-admin/zone-manager-dashboard`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (response.status === 200) {
        setStat(data?.cards);
        setCharts(data?.charts);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to load record", "error");
      setLoading(false);
    }
  };

  const formatMoney = (money) => {
    return `$ ${money.toLocaleString()}`;
  };

  React.useEffect(() => {
    getStats();
  }, []);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  const route = "/admin-account/zone-manager-table";

  const routeHandler = (item) => {
    let route;
    if (item.title === "Total FZC Requests") {
      route = "/admin-account/free-zone-applications";
    } else if (item.title === "Total ZMC Requests") {
      route = "/admin-account/zone-management-requests-applications";
    } else if (item.title === "Generated Revenues") {
      route = "/admin-account/free-zone-revenues";
    } else route = "/admin-account/zone-manager-table";
    return route;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {stat
          ? stat?.map((item, index) => (
              <Grid key={`item${index}`} item xs={12} sm={6} md={3}>
                <Paper
                  className={classes.paper}
                  onClick={() => {
                    localStorage.setItem("url", item.link);
                    console.log("ITEMLINF", item.link);
                    history.push(routeHandler(item));
                  }}
                >
                  <div className={classes.cards}>
                    <img src={icons[index]} alt="icon" />
                    <Typography
                      variant="h4"
                      style={{ color: "black", fontSize: "1.3rem" }}
                    >
                      {item.isMoney ? formatMoney(item.data) : item.data}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>{item.title}</Typography>
                    <ChevronRightIcon style={{ color: "#B0AEAE" }} />
                  </div>
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>

      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <div className={classes.stats}>
            <div>
              <div className={classes.inline}>
                <Button
                  variant={filter == "private" && "contained"}
                  color={filter == "private" ? "primary" : ""}
                  onClick={() => {
                    setFilter("private");
                  }}
                >
                  ZMCs requests
                </Button>
                <Button
                  variant={filter == "public" && "contained"}
                  color={filter == "public" ? "primary" : ""}
                  onClick={() => {
                    setFilter("public");
                  }}
                >
                  Free Zones requests
                </Button>
              </div>
            </div>
            <div>
              <form>
                <select id="cat" name="cat" className={classes.select}>
                  <option value="volvo">Sort by ...</option>
                  <option value="name">Name</option>
                  <option value="date">Date</option>
                </select>
              </form>
            </div>
          </div>
          <Grid item xs={12} sm={12}>
            <div>
              {/* <Charts charts={charts} /> */}
              <Chart
                id="chart"
                dataSource={filter == "private" ? charts.zmc : charts.fzc}
                style={{ padding: "0 2rem" }}
              >
                <ArgumentAxis tickInterval={5}>
                  <Label format="decimal" />
                </ArgumentAxis>
                <Series
                  valueField="val"
                  argumentField="month"
                  type="bar"
                  color="#ffaa66"
                />
                <Legend visible={false} />
              </Chart>
            </div>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
};

export default Dashboard;
