import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    Container,
    Typography,
    TextField,
    CircularProgress,
    Select,
    MenuItem,
    Button,
    List,
    ListItem
} from '@material-ui/core';
import { useParams } from 'react-router-dom'
import axiosInstance from '../Api/Api'
// import ZMCDocuments from './ZMCDocuments'
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    inputField: {
        marginBottom: '20px',
        width: '100%'
    },
    flexDetail: {
        display: 'flex'

    },
    teamMember: {
        width: "100%",
        padding: '20px'
    },
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    memberImage: {
        height: "100%",
        width: "150px"

    }
}));

export default function AutoGrid() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const params = useParams();
    const [promoterDetails, setPromoterDetails] = useState({})
    const [zones, setZones] = useState([])
    const [open, setOpen] = useState(false)
    const [erorrMessage, setErrorMessage] = useState('')
    const history = useHistory();





    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };


    const fetchPromoterDetails = async () => {
        setLoading(true);
        axiosInstance
            .get(`/company/get/${params.rowId}`)
            .then(function (response) {
                response = response.data
                if (response.status == true) {
                    setPromoterDetails(response.data)
                    setZones(response.data.zones)
                } else {
                    console.log("Flag an error message")
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            .finally(() => setLoading(false));
    };




    useEffect(() => {
        fetchPromoterDetails()
    }, [])

    if (loading) {
        return <div className={classes.root}><CircularProgress /></div>
    }

    return (
        <div className={classes.root}>
            <Container>

                <Grid container spacing={1}>
                    <Grid item xs>
                        <Paper variant="outlined" className={classes.paper}>
                            <Typography variant="h6" >
                                Promoter
                           </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                Name: {promoterDetails && promoterDetails.name}
                            </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                First Name: {promoterDetails && promoterDetails.first_name}
                            </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                Last Name: {promoterDetails && promoterDetails.last_name}
                            </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                Email: {promoterDetails && promoterDetails.email}
                            </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                Address: {promoterDetails && promoterDetails.address}
                            </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                Phone: {promoterDetails && promoterDetails.phone}
                            </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                Rc Number: {promoterDetails && promoterDetails.rc_no}
                            </Typography>
                            <Typography className={classes.flexDetail} variant="body2" gutterBottom>
                                Username: {promoterDetails && promoterDetails.username}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>

                    <Grid item xs={6}>
                        <Paper variant="outlined" className={classes.paper}>
                            <Typography variant="h6" >
                                Zone (s)
                           </Typography>

                            {
                                zones.map((zone, index) => (
                                    <List key={index}>
                                        <ListItem>
                                            <Paper className={classes.teamMember} variant="outlined">
                                                <div className={classes.flexContainer}>
                                                    <div className={classes.memberDetails}>
                                                        <Typography>Name: {zone.name}</Typography>
                                                        <Typography>Address: {zone.address}</Typography>
                                                        <Typography>State: {zone.state}</Typography>
                                                        <Typography>Approval Stage: {zone.approve == 1 ? "Approved" : "Declined"}</Typography>
                                                        <Typography>LGA: {zone.lga}</Typography>
                                                    </div>
                                                    {/* <div className={classes.memberImage}><img className={classes.memberImage} src={member?.passport_url} /></div> */}
                                                </div>
                                            </Paper>
                                        </ListItem>
                                    </List>
                                ))
                            }
                        </Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper variant="outlined" className={classes.paper}>
                            <Typography variant="h6" >
                                Zone Management Company
                           </Typography>


                            {
                                zones.map((zone, index) =>
                                (
                                    <List key={index}>
                                        <ListItem>
                                            <Paper className={classes.teamMember} variant="outlined">
                                                <div className={classes.flexContainer}>
                                                    <div className={classes.memberDetails}>
                                                        <Typography>Name: {zone.zmc_name}</Typography>
                                                        <Typography>Address: {zone.zmc_address}</Typography>
                                                        <Typography>State: {zone.zmc_email}</Typography>
                                                        <Typography>State: {zone.zmc_phone}</Typography>
                                                        <Typography>Register Date: {zone.zmc_registered_at}</Typography>
                                                        <Typography>Approval Date: {zone.zmc_approved_at ? zone.zmc_approved_at : "Not approved"}</Typography>
                                                    </div>
                                                    {/* <div className={classes.memberImage}><img className={classes.memberImage} src={member?.passport_url} /></div> */}
                                                </div>
                                            </Paper>
                                        </ListItem>
                                    </List>
                                ))
                            }

                            {/* <ZMCDocuments documents={zmcDetails?.data?.documents} /> */}
                        </Paper>
                    </Grid>

                </Grid>
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {erorrMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
