import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ZoneAdminEntIcon from "../../../images/zoneAdminTotalEnt.svg";
import ZoneAdminPendingIcon from "../../../images/zoneAdminPendingIcon.svg";
import ZoneAdminApprovedIcon from "../../../images/zoneAdminApprovedIcon.svg";
import ZoneAdminCertifiedIcon from "../../../images/zoneAdminCertifiedIcon.svg";
import GeneratedRevenueIcon from "../../../images/generatedRevenueIcon.svg";
import TotalZmcReqIcon from "../../../images/totalZmcReq.svg";
// import SelectBox from "devextreme-react/select-box";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "20px",
  },
  stats: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 2rem",
    margin: "2rem 0",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    minHeight: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-between",
    fontSize: "30px",
    alignItems: "center",
    gap: "1rem",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  whiteBg: {
    background: "white",
    padding: "35px",
  },
  inline: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    marginBottom: "51px",
  },
}));



const icons = [
  ZoneAdminEntIcon,
  TotalZmcReqIcon,
  ZoneAdminApprovedIcon,
  ZoneAdminCertifiedIcon,
  GeneratedRevenueIcon,
];

const FreeZoneRequestsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState([]);
  const [charts, setCharts] = useState([]);

  const user = localStorage.getItem("userDetails");

  const userData = JSON.parse(user);
  const classes = useStyles();

  //get dashboard stats
  const getStats = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/zone-admin/free-zone-request-dashboard`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (response.status === 200) {
        setStat(data.cards);
        setCharts(data.charts.fzc);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to load record", "error");
      setLoading(false);
    }
  };

  const formatMoney = (money) => {
    return `$ ${money.toLocaleString()}`;
  };

  React.useEffect(() => {
    getStats();
  }, []);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  return (
    <div style={{ marginTop: "3rem" }}>
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.cardGrid}>
          {stat
            ? stat.map((item, index) => (
                <Grid key={`item${index}`} item xs={12} sm={6} md={3}>
                  <Paper className={classes.paper}>
                    <div className={classes.cards}>
                      <img src={icons[index]} alt="icon" />
                      <Typography
                        variant="h4"
                        style={{ color: "black", fontSize: "1.3rem" }}
                      >
                        {item.isMoney ? formatMoney(item.data) : item.data}
                      </Typography>
                      {/* <CheckCircleOutlinedIcon style={{ color: blue[500] }} /> */}
                    </div>
                    <div>
                      <Typography>{item.title}</Typography>
                    </div>
                  </Paper>
                </Grid>
              ))
            : null}
        </Grid>

        <div className={classes.whiteBg}>
          <div className={classes.inline}>
            <Typography
              style={{ color: "black", fontSize: "20px", fontWeight: 600 }}
            >
              Request statistics
            </Typography>
          </div>
          <Grid item xs={12} sm={12}>
            <div>
              <Chart
                id="chart"
                dataSource={charts}
                style={{ padding: "0 2rem" }}
              >
                <ArgumentAxis tickInterval={5}>
                  <Label format="decimal" />
                </ArgumentAxis>
                <Series
                  valueField="val"
                  argumentField="month"
                  // name={item.title}
                  type="bar"
                  color="#ffaa66"
                />
                <Legend visible={false} />
              </Chart>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default FreeZoneRequestsDashboard;
