import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Typography, Button } from "@material-ui/core";
import { AppConfig } from "app-config";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDownIcon from "../../../images/arrowdown.svg";

const columns = [
  { id: "name", label: "Applicant Name", minWidth: 170 },
  { id: "application", label: "Applicant type", minWidth: 100 },
  {
    id: "date",
    label: "Application date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Amount paid",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, application, date, status, action) {
  return { name, application, date, status, action };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  ent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    maxHeight: 440,
    marginTop: "2rem",
  },
  paper: {
    // padding: theme.spacing(2),
    color: "black",
    // minHeight: "150px",
    width: "100%",
    padding: "1rem 2rem",
  },
  editButton: {
    background: "#47BB78",
    border: "none",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      background: "#47BB78",
      border: "none",
      color: "white",
    },
  },
  tableCell: {
    textTransform: "capitalize",
  },
  paginated: {
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: "60px",
    paddingBlock: "18px",
  },
  btn: {
    border: "1px solid black",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  select: {
    padding: "0.8rem 2.5rem",
    border: "1px solid #B0AEAE",
    borderRadius: "5px",
    color: "#B0AEAE",
    appearance: "none",
    backgroundImage: `url(${ArrowDownIcon})`,
    backgroundSize: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 8px) center",
    outline: "none",
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [paginated, setPaginated] = useState(false);
  const [step, setStep] = useState(1);
  const type = "all";

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };

  let history = useHistory();

  const user = localStorage.getItem("userDetails");

  const userData = JSON.parse(user);

  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  //show application detail
  const showRequestDetails = (id) => {
    console.log(id);
    history.push(`/admin-account/finance-applications/${id}`);
  };

  const getApplicationDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/super-admin/request-inventory?status=${type}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, message, data } = await response.json();
      console.log(status);
      console.log("FA DATA", data);
      if (status === true) {
        setLoading(false);
        setApplication(data?.data);
        setLastPage(data.last_page);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplicationDetails();
  }, [page]);

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Box className={classes.ent}>
          <>
            <Typography style={{ fontSize: "1.2rem" }}>Applications</Typography>
          </>
          <>
            <form>
              <select id="cat" name="cat" className={classes.select}>
                <option value="volvo">Sort by ...</option>
                <option value="name">Name</option>
                <option value="date">Date</option>
              </select>
            </form>
          </>
        </Box>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, color: "#B0AEAE" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {loading ? (
              <Box className={classes.spinner}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TableBody>
                  {application?.map((row) => (
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ textTransform: "capitalize" }}
                        key={row?.id}
                      >
                        {row?.name}
                      </TableCell>

                      <TableCell
                        component="th"
                        align="left"
                        className={classes.tableCell}
                      >
                        {row?.application_type}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row?.application_date).format("M/D/YYYY")}
                      </TableCell>
                      <TableCell align="right">
                        <Typography>{formatAsMoney(row?.fee)}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          className={classes.editButton}
                          onClick={() =>
                            showRequestDetails(row?.application_id)
                          }
                        >
                          View request
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </Paper>

      <Box className={classes.paginated}>
        <Button
          color="default"
          className={classes.btn}
          onClick={() => {
            setStep(step - 1);
          }}
        >
          <span style={{ marginRight: "5px" }}>
            <ArrowBackIcon />
          </span>
          Previous
        </Button>
        <Pagination
          component="Box"
          count={lastPage}
          onChange={handleChangePage}
          page={page}
          classes={{ ul: classes.ul }}
          color="standard"
        />
        <Button
          color="default"
          className={classes.btn}
          onClick={() => {
            setStep(step + 1);
          }}
        >
          Next
          <span>
            <ArrowForwardIcon />
          </span>
        </Button>
      </Box>
    </>
  );
}
