import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DocumentList({ documents }) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {documents?.map((item, index) => (
        <ListItem key={item.id}>
          <a
            href={item.upload_url}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <ListItemAvatar>
              <Avatar>
                <CloudDownloadIcon />
              </Avatar>
            </ListItemAvatar>
          </a>
          <ListItemText
            primary={item?.file_title}
            secondary={item?.upload_date}
          />
        </ListItem>
      ))}
    </List>
  );
}
