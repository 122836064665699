import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Paper,
  Typography,
  Chip,
  TextField,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import circleCheck from "images/check-circle.svg";
import downoadCloud from "images/download-cloud.svg";
import avatar from "images/avatar.svg";
import { useState } from "react";
import EnterpriseAppInfo from "./EnterpriseAppInfo";
import EnterpriseApplicationTable from "../Tables/EnterpriseApplicationTable";
import ZmcInfo from "./ZmcInfo";
import ZMCApplicationTable from "../Tables/ZMCApplicationTable";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: "3rem",
    paddingInline: "2rem",
    background: "white",
    display: "flex",
    gap: "2em",
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  heading: {
    textAlign: "center",
    paddingTop: 32,
  },
  greyText: {
    fontWeight: "500",
    fontSize: "16px",
    color: "#B0AEAE",
    marginTop: "16px",
  },
  blackText: {
    fontWeight: "500",
    fontSize: "14px",
    display: "block",
    color: "black",
    marginTop: "12px",
  },
  greenBorder: {
    borderLeft: "3px solid #47BB78",
    padding: "10px",
    color: "#47BB78",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "18px",
  },
  boxIndent: {
    paddingInline: "31px",
  },
  greyBg: {
    background: "rgba(250, 250, 250, 0.8)",
    marginBottom: "14px",
  },
  halfWidth: {
    width: "50%",
  },
  documentRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "20px",
  },
  boldText: {
    fontWeight: "600",
    fontSize: "14px",
    display: "block",
    color: "black",
  },

  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  chip: {
    background: "rgba(71, 187, 120, 0.3)",
    borderRadius: "29px",
    color: " #47BB78",
  },
  textarea: {
    padding: "10px 0px 10px 17px",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
  },
  button: {
    marginTop: "27px",
  },
}));

const ZoneApplications = () => {
  const classes = useStyles();
  const [view, setView] = useState(null);
  console.log(view);
  return (
    <div>
      {view ? (
        <ZmcInfo id={view} setView={setView} />
      ) : (
        <ZMCApplicationTable view={view} setView={setView} />
      )}
    </div>
  );
};

export default ZoneApplications;
