/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import axios from "axios";
import { MenuItem, InputAdornment, Container } from "@material-ui/core";
import { AppConfig } from "../app-config";
import Header from "./SigninHeader";
import { useHistory } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import Popover from "@material-ui/core/Popover";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  label: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "55px",
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    marginTop: "0.5em",
    // justifyContent: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "auto",
    // alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "2rem 86px",
  },
  loginLink: {
    color: "gray",
    marginTop: "20px",
  },
  text: {
    width: "100%",
    marginBottom: "1.4rem",
  },
  button: {
    marginTop: "100px",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    boxShadow: "none",
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginBottom: "1rem",
    height: "100%",
  },
  textDiv: {
    width: "90%",
    // marginBottom: "1.4rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  textField: {
    width: "48%",
  },
  textFieldLift: {
    width: "48%",
    position: "relative",
    bottom: 4,
  },
  adornment: {
    color: "grey",
    position: "relative",
    left: -45,
    bottom: -5,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    flexGrow: "1",
  },
  info: {
    position: "relative",
    top: -20,
    left: -20,
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function Search() {
  const [Company, setCompany] = React.useState({
    name: "",
    email: "",
    phone: "",
    organization: "",
    reason: "",
    person_names: "",
  });
  const [error, setError] = React.useState({
    name: "",
    email: "",
    phone: "",
    organization: "",
    reason: "",
    person_names: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [alert, showAlert] = React.useState(false);
  const [dataForPayment, setDataForPayment] = React.useState({});
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [entityName, setEntityName] = React.useState("");
  const [showTag, setShowTag] = React.useState(false);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneChange = (value) => {
    setError({ ...error, phone: "" });
    setCompany({
      ...Company,
      phone: value,
    });
  };
  const history = useHistory();

  const areTruthy = Object.values(Company).every((value) => value);

  const paymentDataIsEmpty =
    Object.getOwnPropertyNames(dataForPayment).length === 0;

  const handleSubmit = async () => {
    try {
      setLoading(true);

      let reqMethod = "POST";
      let url = `${AppConfig.URL}/search/init`;

      const { status, message, data } = await axios
        .post(url, {
          name_searched: Company.name,
          reason: Company.reason,
          user_email: Company.email,
          user_phone: Company.phone,
          user_org: Company.organization,
          person_names: Company.person_names,
        })
        .then((res) => res.data);
      if (status) {
        console.log(data);
        setLoading(false);
        setDataForPayment(data);
        Swal.fire({
          title: "Submission was received",
          html: `
          <p style='font-weight: 400;
          font-size: 18px;
          line-height: 165%;
          text-align: center;
          letter-spacing: -0.03em;
          color: #898989;' >Your RRR Code  for the payment transaction is  </p>
          <p style='font-weight: 600;
          font-size: 20px;
          display:flex;
          justify-content:center;
          line-height: 165%;
          text-align: center;
          letter-spacing: -0.03em;
          margin-top:10px;
          align-items:center;
          color: #434242;'>${data?.rrr} <img src='/layer.png' style='height1.2em; margin-left:1em'/></p>
          <form className={classes.textDiv} class='frm_input_srt' style={{ flexDirection: "column" }} enctype="multipart/form-data" method="post" name="SubmitRemitaForm" target="_blank" action='${data?.action}/finalize.reg'
      >
        <input
          type="hidden"
          value='${data?.merchantId}'
          name="merchantId"
        />
        <input
          type="hidden"
          value='${data?.hash}'
          name="hash"
        />
        <input
          type="hidden"
          value='${data?.rrr}'
          name="rrr"
        />
        <input
          type="hidden"
          value='${window.location.origin}/register-enterprise?rrr=${data?.rrr}'
          name="responseurl"
        />

      </form>`,
          confirmButtonColor: "#48BB78",
          icon: "success",
          iconHtml: `<img src='/check.png'/>`,
          confirmButtonText: "Proceed to pay",
        }).then((res) => {
          if (res.isConfirmed) {
            document.querySelector(".frm_input_srt").submit();
          }
        });
        showAlert(true);
      } else {
        console.log(data);
        Swal.fire("Something went wrong", message, "info");
        setLoading(false);
      }
    } catch (err) {
      Swal.fire("Something went wrong", "Unable to process request", "info");
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      {/* <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="success">
          Company Created Successfully!
        </Alert>
      </Snackbar> */}

      <Container className={classes.container}>
        <div style={{ width: "50%", height: "100vh" }}>
          <img
            src="/search.png"
            style={{
              // height: "85vh",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.form}>
            <a href="/register">
              <div
                style={{ marginBottom: "40px", height: "40px" }}
                // onClick={() => setStep(step - 1)}
              >
                <img src="/ArrowLeft.png" style={{ cursor: "pointer" }} />
              </div>
            </a>
            <Typography
              variant="h4"
              style={{ fontSize: "35px", fontWeight: "700" }}
            >
              Registry search
            </Typography>

            <h3 style={{ marginTop: "15px", marginBottom: "41px" }}>
              Fill in the following details below to initiate search
            </h3>
            <div className={classes.textDiv}>
              <TextField
                label="Proposed name(Name search)"
                name="name"
                fullWidth
                error={!!error.name}
                id={
                  !error.name ? "outlined-basic" : "outlined-error-helper-text"
                }
                required
                // className={classes.textField}
                helperText={error.name ? error.name : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className={classes.textDiv}>
              <TextField
                label="Full name"
                name="person_names"
                fullWidth
                error={!!error.person_names}
                id={
                  !error.person_names
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.person_names ? error.person_names : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                label="Name of organization"
                name="organization"
                fullWidth
                className={classes.textField}
                error={!!error.organization}
                id={
                  !error.organization
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                // className={classes.textField}
                helperText={error.organization ? error.organization : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className={classes.textDiv}>
              <TextField
                label="Email"
                name="email"
                error={!!error.email}
                id={
                  !error.email ? "outlined-basic" : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.email ? error.email : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              />
              <MuiPhoneNumber
                label="Phone"
                name="phone"
                defaultCountry={"ng"}
                error={!!error.phone}
                id={
                  !error.phone ? "outlined-basic" : "outlined-error-helper-text"
                }
                required
                className={classes.textField}
                helperText={error.phone ? error.phone : ""}
                margin="normal"
                type="text"
                value={Company?.phone}
                variant="outlined"
                onChange={handlePhoneChange}
              />
            </div>

            {/* <div className={classes.textDiv}>
              
            </div> */}

            <div className={classes.textDiv}>
              <TextField
                label="Reason for search"
                select
                name="reason"
                fullWidth
                error={!!error.reason}
                id={
                  !error.reason
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                // className={classes.textField}
                helperText={error.reason ? error.reason : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value={"due_diligence"}>{"Due diligence"}</MenuItem>
                <MenuItem value={"Registry search"}>
                  {"Enterprise registration"}
                </MenuItem>
              </TextField>
            </div>

            <div className={classes.textDiv}>
              <TextField
                label="amount"
                name="amount"
                fullWidth
                disabled
                value={"$100"}
                // error={!!error.reason}
                id={
                  !error.reason
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                // className={classes.textField}
                // helperText={error.reason ? error.reason : ""}
                margin="normal"
                type="text"
                variant="outlined"
                // onChange={(e) => handleChange(e)}
              />
            </div>
            {paymentDataIsEmpty && (
              <div className={classes.textDiv}>
                <Button
                  // autoFocus
                  onClick={() => {
                    handleSubmit();
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={handleSubmit}
                  className={classes.button}
                  size="large"
                  disabled={loading || !areTruthy}
                >
                  {loading ? (
                    <div className={classes.spinner}>
                      <CircularProgress
                        style={{ height: "30px", width: "30px", margin: 0 }}
                      />
                    </div>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            )}

            {paymentDataIsEmpty && (
              <p
                className={classes.loginLink}
                tw="mt-8 text-sm text-gray-400 text-center"
              >
                Already have an account?{" "}
                <a
                  href={"/login"}
                  tw="border-b border-gray-500 border-dotted"
                  style={{ color: "#48BB78" }}
                >
                  Sign In
                </a>
              </p>
            )}

            {!paymentDataIsEmpty && (
              <form
                className={classes.textDiv}
                style={{ flexDirection: "column" }}
                enctype="multipart/form-data"
                method="post"
                name="SubmitRemitaForm"
                target="_blank"
                action={`${dataForPayment?.action}/finalize.reg`}
              >
                <input
                  type="hidden"
                  value={`${dataForPayment?.merchantId}`}
                  name="merchantId"
                />
                <input
                  type="hidden"
                  value={`${dataForPayment?.hash}`}
                  name="hash"
                />
                <input
                  type="hidden"
                  value={`${dataForPayment?.rrr}`}
                  name="rrr"
                />
                {/* <input type="hidden" value="https://nepzaopms.com/finalize" name="responseurl" /> */}
                <input
                  type="hidden"
                  value={`${window.location.origin}/register-enterprise?rrr=${dataForPayment?.rrr}`}
                  name="responseurl"
                />
                <Button
                  // autoFocus
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={handleSubmit}
                  className={classes.button}
                  size="large"
                  disabled={loading}
                >
                  Pay now
                </Button>
                <p
                  className={classes.loginLink}
                  tw="mt-8 text-sm text-gray-400 text-center"
                >
                  Already have an account?{" "}
                  <a
                    href={"/login"}
                    tw="border-b border-gray-500 border-dotted"
                    style={{ color: "#48BB78" }}
                  >
                    Sign In
                  </a>
                </p>
                <p
                  style={{
                    maxWidth: "35rem",
                    textAlign: "left",
                    color: "red",
                    marginTop: "59px",
                  }}
                  onClick={() =>
                    navigator.clipboard.writeText(dataForPayment?.rrr)
                  }
                >
                  <strong> Note:</strong>Your RRR Code for the above transaction
                  is {`${dataForPayment?.rrr}`} You can also decide to use other
                  payment method on the Remitta platform. Click to copy code
                </p>
              </form>
            )}

            {/* <p
              className={classes.loginLink}
              tw="mt-8 text-sm text-gray-400 text-center"
            >
              Already have an account?{" "}
              <a href={"/login"} tw="border-b border-gray-500 border-dotted">
                Sign In
              </a>
            </p> */}
          </div>
          {/* {loading && (
            <div className={classes.spinner}>
              <CircularProgress />
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="initial"
              >
                Submiting Company...
              </Typography>
            </div>
          )} */}
        </Paper>
      </Container>
    </div>
  );
}
