import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import ZoneAdminApplicationTable from "./ZoneAdminApplicationTable";
import { AppConfig } from "app-config";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  ent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    color: "black",
    minHeight: "150px",
    width: "100%",
    padding: "1rem 2rem",
  },
}));

const ZoneAdminApplication = () => {
  const [loading, setLoading] = useState(false);
  const [totalApplication, setTotalApplication] = useState([]);

  const classes = useStyles();
  return (
    <div style={{ marginTop: "2rem" }}>
      <ZoneAdminApplicationTable />
    </div>
  );
};

export default ZoneAdminApplication;
