import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import TotalRequestIcon from "../../../../images/totalRequestIcon.svg";
import TotalRequestAmountIcon from "../../../../images/totalRequestAmountIcon.svg";
import PendingRequestIcon from "../../../../images/pendingRequestIcon.svg";
import ApprovedRequestIcon from "../../../../images/approvedRequestIcon.svg";
import DeclinedRequestIcon from "../../../../images/declinedRequestIcon.svg";
import blueLocation from "../../../../images/blue-location.svg";
import orangeLocation from "../../../../images/orange-location.svg";
import greenTv from "../../../../images/green-tv.svg";
import greenBox from "../../../../images/green-box.svg";
import greenWallet from "../../../../images/green-wallet.svg";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "35px",
    marginTop: "20px",
  },
  bottomData: {
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    minHeight: "112px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "white",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    background: "rgba(245, 244, 244, 0.8)",
    flexGrow: 0,
  },
  whiteBg: {
    background: "white",
    padding: "35px",
  },
  inline: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    marginBottom: "51px",
  },
}));

const TotalRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: blue[500] },
}))(LinearProgress);
const ProcessedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: green[500] },
}))(LinearProgress);
const InProgreeRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: purple[500] },
}))(LinearProgress);
const DeclinedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: red[500] },
}))(LinearProgress);

const icons = [blueLocation, orangeLocation, greenTv, greenBox, greenWallet];

export default () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState({});
  const [cards, setCards] = useState([]);

  const user = localStorage.getItem("userDetails");
  const [charts, setCharts] = useState([]);

  const [filter, setFilter] = useState("private");

  const userData = JSON.parse(user);
  const classes = useStyles();

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/super-admin/chart?entity_type=freezone`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        // setCharts(data);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  const formatMoney = (money) => {
    return `$ ${money.toLocaleString()}`;
  };

  const getDashboard = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/zone-admin/zone-manager-dashboard`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(status);
        setCards(data?.cards);
        setCharts(data?.charts?.fzc);
        // console.log(data.cards?.map(info => ()));
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getDashboard();
    getCategories();
  }, []);

  console.log("Here is cards info", cards);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {cards?.map((card, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <div className={classes.paper}>
              <div className={classes.cards}>
                <img src={icons[index]} />
                <div>
                  <Typography
                    variant="h5"
                    style={{ color: "black", marginBottom: "12px" }}
                  >
                    {card.isMoney ? formatMoney(card.data) : card.data}
                  </Typography>
                  <Typography>{card.title}</Typography>
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>

      <div className={classes.whiteBg}>
        <div className={classes.inline}>
          <Typography
            style={{ color: "black", fontSize: "20px", fontWeight: 600 }}
          >
            Request statistics
          </Typography>
        </div>
        <Grid item xs={12} sm={12}>
          <div>
            <Chart id="chart" dataSource={charts} style={{ padding: "0 2rem" }}>
              <ArgumentAxis tickInterval={5}>
                <Label format="decimal" />
              </ArgumentAxis>
              <Series
                valueField="val"
                argumentField="month"
                // name={item.title}
                type="bar"
                color="#ffaa66"
              />
              <Legend visible={false} />
            </Chart>
          </div>
        </Grid>
      </div>
    </div>
  );
};
