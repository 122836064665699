import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Grid,
  colors,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../Api/Api.js";
import Alert from "@material-ui/lab/Alert";
import { AppConfig } from "app-config.js";
import moment from "moment";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableFont: {
    fontSize: "0.875rem",
    color: "#B0AEAE",
  },
  tableHeader: {
    paddingLeft: "0",
    fontSize: "18px",
    width: "100%",
    marginLeft: "10px",
  },
  footer: {
    textAlign: "right",
    marginTop: 8,
    paddingBottom: 8,
  },
  btn: {
    marginLeft: 3,
  },
  pageInput: {
    border: "1px solid #48BB78",
    width: 70,
  },
  link: {
    background: "#47BB78",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  paginated: {
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: "60px",
    paddingBlock: "18px",
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function DataList({ type, reference }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [allRequests, setAllRequests] = useState([]);
  const [allData, setAllData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [toPage, setToPage] = useState("");
  const [inputError, setInputError] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [paginated, setPaginated] = useState(false);

  const history = useHistory();

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  const formatDate = (date) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (date) {
      return new Date(date).toLocaleDateString(undefined, options);
    }
    return null;
  };

  const showRequestDetails = (rowId) => {
    console.log(rowId);
    history.push(`/admin-account/action-request/${rowId}`);
  };

  const handlePage = (next) => {
    if (next && pageNumber < lastPage) {
      setPageNumber((prevPage) => prevPage + 1);
    } else if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };
  console.log({ page });
  const goToPage = () => {
    if (toPage >= 1 && toPage <= lastPage) setPageNumber(toPage);
    else setInputError(true);
  };

  //get total application requests
  const totalRequestInventory = async () => {
    setLoading(true);
    let url = `${AppConfig.URL}/zone-admin/request-inventory?status=${type}&page=${page}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setLoading(false);
        console.log("Total Request Mssg", message);
        setAllRequests(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    totalRequestInventory();
  }, [page]);

  return loading ? (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <CircularProgress color="primary" />
    </Grid>
  ) : !allRequests?.data?.length ? (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Typography style={{ maringTop: "2rem" }} variant="h6">
        No {type === "all" ? null : type === "search" ? "such" : type}{" "}
        application found
      </Typography>
    </Grid>
  ) : (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="data-table">
          <TableHead>
            <TableRow className={classes.tableFont}>
              <TableCell className={classes.tableFont}>
                Enterprise Name
              </TableCell>
              <TableCell className={classes.tableFont}>RCI Number</TableCell>
              <TableCell className={classes.tableFont}>
                Enterprise Speciality
              </TableCell>
              <TableCell className={classes.tableFont}>Renewal Date</TableCell>
              <TableCell
                className={classes.tableFont}
                style={{ textAlign: "center" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? allRequests?.data?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : allRequests
            )?.map((row) => (
              <TableRow key={row?.id}>
                <TableCell align="left" component="th" scope="row">
                  {row?.name}
                </TableCell>
                <TableCell align="left">
                  {row?.rc_no ? row?.rc_no : "Pending"}
                </TableCell>
                <TableCell align="right">
                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {row?.status ? row?.status : "Pending"}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {moment(row?.renewal_date).format("M/D/YYYY")}
                </TableCell>
                <TableCell align="center">
                  <button
                    className={classes.link}
                    onClick={() => showRequestDetails(row?.application_id)}
                  >
                    View details
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginated}>
        <Button color="grey">Previous</Button>
        <Pagination
          component="div"
          count={allRequests.last_page}
          onChange={handleChangePage}
          page={page}
          classes={{ ul: classes.ul }}
        />
        <Button color="grey">Next</Button>
      </div>
    </>
  );
}
