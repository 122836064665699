import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { HistoryTwoTone } from "@material-ui/icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosInstance from "../../Api/Api.js";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableFont: {
    // fontWeight: "bold",
    fontSize: "18px",
  },
  tableHeader: {
    paddingLeft: "0",
    fontSize: "18px",
    width: "100%",
    marginLeft: "10px",
  },
  headerContainer: {
    marginBottom: "10px"
  }
});

export default function BasicTable() {
  const classes = useStyles();
  const history = useHistory();
  const [allRequests, setAllRequests] = useState([]);
  const [loading, setLoading] = useState([]);

  const showDetails = (rowId) => {
    console.log(rowId);
    history.push(`/admin-account/services/edit-create/${rowId}`);
  };

  const fetchAllPendingRequests = () => {
    setLoading(true)
    axiosInstance
      .get("/services/list")
      .then(function (response) {
        if (response.status === 200) {
          setAllRequests(response.data.data.data);
          console.log(response.data.data.data, allRequests);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchAllPendingRequests();
  }, []);

  const formatDate = (date) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (date) {
      return new Date(date).toLocaleDateString(undefined, options);
    }
    return null;
  };

  return loading ? (
    <Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
  style={{  height: "100%" }}

>
<CircularProgress color="primary" />
    </Grid>
  ) : ( !allRequests.length?
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    style={{  height: "100%" }}
  >
  <Typography variant="h6">No Record found</Typography>
      </Grid> :
    <div>
      <Grid
  container
  direction="row"
  justify="space-between"
  className={classes.headerContainer}
 alignItems="flex-end"
>
      <div><Typography className={classes.tableHeader}>Services</Typography></div>
      <Link to="/admin-account/services/edit-create/create">
      <Button  variant="contained" color="primary">
  Add new
</Button>
</Link>
      </Grid>
      <TableContainer component={Paper} variant="outlined">
        <Table className={classes.table} aria-label="data-table">
          <TableHead>
            <TableRow className={classes.tableFont}>
              <TableCell className={classes.tableFont}>S/N</TableCell>
              <TableCell className={classes.tableFont}>
            Service Name
              </TableCell>
              <TableCell className={classes.tableFont}>Actual Fee</TableCell>
              <TableCell className={classes.tableFont}>Renewal Fee </TableCell>
              <TableCell className={classes.tableFont}>
               Commision
              </TableCell>
              <TableCell className={classes.tableFont}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRequests.map((row, index) => (
              <TableRow key={row.zone_id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.actual_fee}</TableCell>
                <TableCell>{row.renewal_fee}</TableCell>
                <TableCell>{row.commision}</TableCell>
                <TableCell>
                  {" "}
                  <Link href="#" onClick={() => showDetails(row.id)}>
                    View
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
