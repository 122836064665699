import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Paper,
  Input,
  Button,
  TextField,
  LinkTab,
} from "@material-ui/core";
// import ZoneAdminDataList from "./ZoneAdminDataList";
import ZoneAdminDataList from "./ZoneAdminDataList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3rem",
    flexGrow: 2,
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "1rem",
  },
  textfield: {
    marginRight: 2,
    marginTop: 2,
  },
  label: {
    textTransform: "capitalize",
    margin: "18px",
    "& .makeStyles-label-3": {
      marginRight: 0,
    },
    "&.makeStyles-label-49": {
      background: "none",
    },
    "& .MuiTab-textColorInherit": {
      opacity: 1,
    },
  },
  tabePanel: {
    "&.MuiTab-textColorInherit.Mui-selected": {
      width: "1%",
    },
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}));

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default function BasicTable() {
  const classes = useStyles();
  const [tab, setTab] = useState("all");
  const [search, setSearch] = useState(false);
  const [value, setValue] = useState(0);
  const [reference, setReference] = useState("");
  const [active, setActive] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabsChange = (event, value) => {
    setSearch(false);
    setTab(value);
  };
  const handleChange = (e) => {
    setReference(e.target.value);
  };

  const handleClick = (e) => {
    //   setTimeout(() => setSearch(true), 50);
    //   setSearch(false);
    setActive(e);
  };

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

  const tabs = [
    { value: "all", label: "Total" },
    { value: "approved", label: "Approved" },
    { value: "certified", label: "Certified" },
    { value: "pending", label: "Pending" },
    { value: "declined", label: "Declined" },
  ];

  if (!tab) {
    return <Redirect to={"/admin-account"} />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Tabs
            className={classes.tabs}
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={value}
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {tabs.map((tab, index) => (
              // <LinkTab key={tab.value} label={tab.label} value={tab.value} />
              <LinkTab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                className={classes.label}
                {...a11yProps(0)}
                style={{
                  color: active == index ? "white" : "black",
                  backgroundColor:
                    active == index ? "#47BB78" : "rgba(245, 244, 244, 0.8)",
                  opacity: 1,
                  // width: "1%",
                }}
                onClick={() => handleClick(index)}
              />
            ))}
          </Tabs>
        </div>
        <div style={{ marginTop: "40px", marginRight: "10px" }}>
          <form>
            <select
              id="cat"
              name="cat"
              style={{
                padding: "0.8rem 1.2rem",
                border: "1px solid black",
              }}
            >
              <option value="volvo">Sort by ...</option>
              <option value="name">Name</option>
              <option value="date">Date</option>
            </select>
          </form>
        </div>
      </div>

      <div className={classes.content}>
        {search && <ZoneAdminDataList type={"search"} reference={reference} />}
        {!search && tab === "all" && (
          <ZoneAdminDataList type={"all"} reference={reference} />
        )}
        {!search && tab === "approved" && (
          <ZoneAdminDataList type={"approved"} reference={reference} />
        )}
        {!search && tab === "certified" && (
          <ZoneAdminDataList type={"certified"} reference={reference} />
        )}
        {!search && tab === "pending" && (
          <ZoneAdminDataList type={"pending"} reference={reference} />
        )}
        {!search && tab === "declined" && (
          <ZoneAdminDataList type={"declined"} reference={reference} />
        )}
      </div>
    </div>
  );
}
