import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import FolderIcon from "@material-ui/icons/Folder";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Avatar from "@material-ui/core/Avatar";



const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const ListItemAvatarStyled = withStyles((theme) => ({

  root: {
    border: "1px solid gray",
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'center',
    marginLeft: '10px',
    width: '50%'
  }
}))(ListItemAvatar);

const TypographyStyled = withStyles((theme) => ({
  root: {
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}))(Typography);



export default function ApprovalHistory({ approvalHistory }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  console.log(approvalHistory)
  return !approvalHistory.data.length ? (
    <h1>No approval history found</h1>
  ) : (
    <div>
      {approvalHistory.data.map((history, index) => (
        <Accordion
          square
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          key={history.date_approve}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <ListItemText
              align="left"
              primary={history.user.fullname}
              secondary={
                history.approval_instance.approval_stage.approval_stage
              }
            />
            <ListItemIcon>
              <Chip
                label={(history.approval_instance.approval_type.approval_type == "Request Information") ?
                  history.approval_instance.approval_type.approval_type :
                  history.approval_instance.approval_stage_id != '8' &&
                    history.approval_instance.approval_type.approval_type == 'Approve' ?
                    'Approve to Proceed' :
                    `${history.approval_instance.approval_type.approval_type}d`
                }
              />
            </ListItemIcon>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={new Date(history.date_approve).toLocaleString()}
                  secondary={"Date"}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={history.comment} secondary={"Remarks"} />
                <ListItemAvatarStyled >
                  <a href={history?.approval_asset?.upload_url} target="_blank" download>
                    <Avatar>
                      <CloudDownloadIcon />
                    </Avatar>
                  </a>
                  <div style={{ height: "50px", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {/* <TypographyStyled nowrap > */}
                    <TypographyStyled>
                      {history?.approval_asset?.file_title}
                    </TypographyStyled>
                  </div>
                </ListItemAvatarStyled>
              </ListItem>
              {history?.requested_information?.conversations.map((item, index) =>
              (<ListItem key={item.id}>
                <ListItemText primary={item.comment} secondary={"Applicant comment"} />
                <ListItemAvatar>
                  <a href={item.upload_url} target="_blank" download>
                    <Avatar>
                      <CloudDownloadIcon />
                    </Avatar>
                  </a>
                </ListItemAvatar>
              </ListItem>)
              )}
              <ListItem>
                <ListItemText
                  primary={
                    history.approval_instance.approval_type.approval_type
                  }
                  secondary={"Approval Action"}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
