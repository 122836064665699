import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Grid,
    Container,
    Typography,
    TextField,
    CircularProgress,
    Select,
    MenuItem,
    Button,
    List,
    ListItem,
    Checkbox
} from '@material-ui/core';
import { useParams } from 'react-router-dom'
import axiosInstance from '../../Api/Api'
import ZMCDocuments from './ZMCDocuments'
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Tooltip from '@material-ui/core/Tooltip';
import { AlertTitle } from '@material-ui/lab';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '100%'
    },
    inputField: {
        marginBottom: '20px',
        width: '100%'
    },
    flexDetail: {
        display: 'flex'

    },
    teamMember: {
        width: "100%",
        padding: '20px'
    },
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    memberImage: {
        height: "100%",
        width: "150px"

    },
    truncate: {
        width: "120px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    select: {
        width: "90%",
        maxWidth: 375,
        margin: "1.4rem",
        backgroundColor: '#E8E8E8'
    },
    zmcHead: {
        marginBottom: 16
    },
    dark: {
        color: "#000"
    }
}));

export default function AutoGrid() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const params = useParams();
    const [zmcDetails, setZmcDetails] = useState(null)
    const [open, setOpen] = useState(false)
    const [approvalLoading, setApprovalLoading] = useState(false)
    const [erorrMessage, setErrorMessage] = useState('')
    const history = useHistory();
    const [comment, setComment] = useState('')
    const [type, setType] = useState('')
    const [fileTitle, setFileTitle] = useState('')
    const [file, setFile] = useState('')
    const [docTypes, setDocTypes] = useState([])
    const [document, setDocument] = useState('')
    const [checked, setChecked] = useState(false)



    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const fetchZMCRequestDetails = () => {
        setLoading(true);
        axiosInstance
            .get(`/zmc/admin/details-pending/${params.rowId}`)
            .then(function (response) {
                if (response.status === 200) {
                    setZmcDetails(response.data)
                    // console.log(response.data);
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            .finally(() => setLoading(false));
    };

    const selectDocument = (e) => {
        setDocument(e.target.value);
    }

    const getDocTypes = () => {
        setLoading(true)
        axiosInstance
            .get(`/zmc/required-documents`)
            .then(response => {
                if (response.status === 200) {
                    setDocTypes(response.data.data);
                    // console.log(response.data.data)
                } else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false));
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };
    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleTitleChange = (event) => {
        setFileTitle(event.target.value);
    };
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = (e) => {

        setApprovalLoading(true);
        const formData = new FormData()
        formData.append("type", type);
        formData.append("comment", comment);
        formData.append("zmc_profile_id", params.rowId);
        if (file) {
            formData.append("file_title", fileTitle)
            formData.append("file", file);
        }
        formData.append("replace_document", checked)
        if (document) {
            formData.append("document_id", document)
        }



        axiosInstance
            .post("/zmc/admin/approve-account", formData
            )
            .then(function (response) {
                if (response.status === 200) {
                    return history.push("/admin-account/action-submitted");

                }
            })
            .catch(function (error) {
                if (error.response === undefined) {
                    setErrorMessage('Something went wrong');
                    setOpen(true);
                } else {
                    console.log(error.response.data.message);
                    const { message } = error.response.data;
                    setErrorMessage(message);
                    setOpen(true);
                }
            })
            .finally(() => setApprovalLoading(false));
    };



    useEffect(() => {
        fetchZMCRequestDetails()
        getDocTypes()
    }, [])

    if (loading) {
        return <div className={classes.root}><CircularProgress /></div>
    }

    return (
        <div className={classes.root}>
            <Container>

                <Grid container spacing={1}>
                    <Grid item xs className={classes.itm}>
                        <Paper variant="outlined" className={classes.paper}>
                            <Typography variant="h6" color="textPrimary" className={classes.zmcHead}>
                                Zone Management Company
                           </Typography>
                            <Typography className={classes.flexDetail} color="textPrimary" variant="body1" gutterBottom>
                                Name: {zmcDetails?.data?.name}
                            </Typography>
                            <Typography className={classes.flexDetail} color="textPrimary" variant="body1" gutterBottom>
                                Email: {zmcDetails?.data?.company?.email}
                            </Typography>
                            <Typography className={classes.flexDetail} color="textPrimary" variant="body1" gutterBottom>
                                Address: {zmcDetails?.data?.company?.address}
                            </Typography>
                            <Typography className={classes.flexDetail} color="textPrimary" variant="body1" gutterBottom>
                                Phone: {zmcDetails?.data?.company?.phone}
                            </Typography>
                            <Typography className={classes.flexDetail} color="textPrimary" variant="body1" gutterBottom>
                                Rc Number: {zmcDetails?.data?.company?.rc_no}
                            </Typography>
                            <Typography className={classes.flexDetail} color="textPrimary" variant="body1" gutterBottom>
                                Username: {zmcDetails?.data?.company?.username}
                            </Typography>
                            <Typography className={classes.flexDetail} color="textPrimary" variant="body1" gutterBottom>
                                Application Date: {(new Date(zmcDetails?.data?.created_at)).toLocaleString()}
                            </Typography>
                        </Paper>
                    </Grid>
                    {!zmcDetails?.data?.approved ?
                        <Grid item xs>
                            <Paper className={classes.paper}>
                                {!zmcDetails?.data?.approved ? (
                                    <Alert variant="standard" severity="info">
                                        <AlertTitle>
                                            <strong>Final Approval</strong>
                                        </AlertTitle>
                                    Please ensure that this application meets all requirements
                                    before this final approval.
                                    </Alert>
                                ) : null}
                                <Typography variant="h6" color="textPrimary">
                                    Approval Form
                                </Typography>

                                <form noValidate autoComplete="off">

                                    <TextField
                                        select
                                        className={classes.inputField}
                                        onChange={handleTypeChange}
                                        variant="filled"
                                        label="Select Approval Action"
                                        defaultValue=""
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {zmcDetails?.data?.documents.length == 0 && zmcDetails?.data?.management_team_members.length == 0 ?
                                            <MenuItem value="approved" disabled>Approve</MenuItem> : <MenuItem value="approved" >Approve</MenuItem>
                                        }
                                        <MenuItem value="request_information">Request for Information</MenuItem>
                                        <MenuItem value="declined">Decline</MenuItem>
                                    </TextField>
                                    {/* <TextField
                                        className={classes.inputField}
                                        type="text"
                                        label="File Title"
                                        onChange={handleTitleChange}
                                        variant="filled"
                                    />
                                    <div>File Upload(optional)</div>
                                    <TextField
                                        className={classes.inputField}
                                        type="File"
                                        onChange={handleFileChange}
                                        variant="filled"
                                    /> */}
                                    {/* <p>Document re-upload required?
                                        <Checkbox
                                            checked={checked}
                                            // onChange={handleChange}
                                            onChange={() => setChecked(!checked)}
                                            color="primary"
                                        />
                                    </p> */}
                                    {checked &&
                                        <TextField
                                            id="outlined-error-helper-text"
                                            select
                                            className={classes.select}
                                            variant="outlined"
                                            label="Select a Document"
                                            name="DocumentId"
                                            value={document || ''}
                                            onChange={(e) => selectDocument(e)}
                                            disabled={loading}
                                        >
                                            {docTypes.map((option) => (
                                                <MenuItem key={option.id} value={option.id || ''}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }


                                    <TextField rows={4} multiline onChange={handleCommentChange} className={classes.inputField} label="Remarks" variant="filled" />
                                    {approvalLoading ? <Button>Loading...</Button> :
                                        <Button onClick={handleSubmit} variant="contained" color="secondary">
                                            Submit
                                </Button>}
                                </form>
                            </Paper>
                        </Grid>

                        : null}
                </Grid>
                <Grid container spacing={1}>

                    <Grid item xs>
                        <Paper variant="outlined" className={classes.paper}>
                            <Typography variant="h6" color="textPrimary">
                                ZMC Director(s)
                           </Typography>

                            {zmcDetails?.data?.management_team_members?.map((member, index) => (<List key={member.firstname + index}>
                                <ListItem>
                                    <Paper className={classes.teamMember} variant="outlined">
                                        <div className={classes.flexContainer}>
                                            <div className={classes.memberDetails}>
                                                <Typography>First name: {member.firstname}</Typography>
                                                <Typography>Surname: {member.surname}</Typography>
                                                <Typography>Status: {member.status}</Typography>
                                                <Typography>Date of Birth: {member.dob}</Typography>
                                                <Typography>Nationality: {member.nationality}</Typography>
                                                <Typography>Year Appointed: {member.year_appointed}</Typography>
                                            </div><div className={classes.memberImage}><img className={classes.memberImage} src={member?.passport_url} /></div>
                                        </div>
                                    </Paper>
                                </ListItem>
                            </List>))}
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper variant="outlined" className={classes.paper}>
                            <Typography variant="h6" color="textPrimary">
                                Uploaded documents
                           </Typography>
                            <ZMCDocuments documents={zmcDetails?.data?.documents} />
                        </Paper>
                    </Grid>

                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <Paper variant="outlined" className={classes.paper}>
                            <Typography variant="h6" color="textPrimary">Messages</Typography>
                            {<div className={classes.demo}>
                                <List dense={false}>
                                    {zmcDetails?.data.approvals.map((document, index) => (
                                        <div key={index + "approval"}>
                                            <ListItem className={classes.dark}>
                                                <ListItemText
                                                    primary={"Admin Remarks"}
                                                    secondary={document.comment}
                                                />

                                                {document.file_url ?
                                                    <ListItemText className={classes.fileTitle}>
                                                        <a href={document?.file_url} target="_blank" download>

                                                            <IconButton edge="end" aria-label="download">
                                                                <CloudDownloadIcon />
                                                            </IconButton>
                                                        </a>
                                                        <Tooltip title={document?.file_title}>
                                                            <div className={classes.truncate}>{document?.file_title}</div>
                                                        </Tooltip>
                                                    </ListItemText>

                                                    : null}
                                            </ListItem>
                                            {document?.conversations ? document?.conversations.map((item, index) => (
                                                <ListItem key={item.comment + index} className={classes.dark}>
                                                    <ListItemText
                                                        primary={"User Response"}
                                                        secondary={item.comment}
                                                    />
                                                    {/* <ListItemSecondaryAction>
                                                        <a href={item.file_url} target="_blank" download>
                                                            <IconButton edge="end" aria-label="download">
                                                                <CloudDownloadIcon />
                                                            </IconButton>
                                                        </a>
                                                        <Tooltip title={item?.file_title}>
                                                            <div className={classes.truncate}>{item.file_title}</div>
                                                        </Tooltip>
                                                    </ListItemSecondaryAction> */}
                                                </ListItem>
                                            )

                                            ) : null}
                                            <hr />
                                        </div>)
                                    )}
                                </List>
                            </div>}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {erorrMessage}
                </Alert>
            </Snackbar>
        </div >
    );
}
