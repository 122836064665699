import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  colors,
  capitalize,
  Menu,
  MenuItem,
  Box,
  Chip,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppConfig } from "app-config";
import Swal from "sweetalert2";
import { green, grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { Pagination } from "@material-ui/lab";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    color: "#B0AEAE",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: grey[50],
  //   },
  // },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
  table: {
    minWidth: 700,
    paddingInline: "65px",
    paddingTop: "33px",
    paddingBottom: "80px",
    backgroundColor: "white",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  delBtn: {
    color: "red",
  },
  norec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    flexDirection: "column",
  },
  action: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  payBtn: {
    color: colors.green[600],
  },
  head: {
    // backgroundColor: grey[200],
  },
  bold: {
    fontWeight: "bold",
  },
  headText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  chip: {
    width: "125px",
    height: "34px",

    background: "#FFECD7",
    borderRadius: "29px",
    // fontFamily: "Plus Jakarta Sans",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#EFA34B",
  },
  paginated: {
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: "60px",
    paddingBlock: "18px",
  },
}));

export default function EnterpriseRequest() {
  const type = "all";
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginated, setPaginated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const user = localStorage.getItem("userDetails");
  const [open, setOpen] = useState(false);

  const userData = JSON.parse(user);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getZones = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/super-admin/request-inventory?entity_type=freezone&status=${type}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        }
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data);
        setZones(data.data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to get zones", "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    getZones();
  }, []);

  if (!loading && zones.length === 0) {
    return (
      <div className={classes.norec}>
        <Typography component="h1" variant="h3">
          No record found
        </Typography>

        <Button
          className={classes.button}
          color="primary"
          onClick={getZones}
          variant="contained"
        >
          Go Back
        </Button>
      </div>
    );
  } else if ((loading && !paginated) || !zones.length) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }

  const handleView = (id) => {
    console.log(id);
    history.push(`/admin-account/request-detail/${id}`);
  };

  return (
    <>
      {/* <Typography color="textSecondary" gutterBottom variant="body2">
        Page {page + 1} of {Math.ceil(zones.length / 10)}
      </Typography> */}
      <Box></Box>

      <TableContainer className={classes.table}>
        <Box style={{ marginBottom: "3em" }}>
          <Typography gutterBottom variant="h4" className={classes.headText}>
            Enterprises
          </Typography>
        </Box>
        <Table stickyHeader aria-label="customized table">
          <TableHead className={classes.head}>
            <TableRow>
              <StyledTableCell>Enterprise Name</StyledTableCell>
              <StyledTableCell>AssociatedFZC</StyledTableCell>
              <StyledTableCell>FZE number</StyledTableCell>
              <StyledTableCell>Renewal Timeline</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
              {/* <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Promoter</StyledTableCell> */}
              {/* <StyledTableCell>Action</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {zones &&
              zones
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((zone, index) => (
                  <StyledTableRow key={zone.id}>
                    <StyledTableCell component="th" scope="row">
                      {zone.name}
                    </StyledTableCell>
                    {/* <TableCell>{index + 1}</TableCell> */}
                    <TableCell>{zone.name}</TableCell>
                    <TableCell align="left">
                      <Chip label={zone.address} className={classes.chip} />
                    </TableCell>
                    <TableCell align="left">{zone.state}</TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        View details
                      </Button>
                    </TableCell>
                    {/* <TableCell align="left">{zone.promoter_name}</TableCell> */}
                    {/* <TableCell>
                                        {<Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleView(zone.id)}
                                            className={classes.button}
                                            size="large"
                                            disabled={loading}
                                        >
                                            View
                                        </Button>}
                                    </TableCell> */}
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginated}>
        <Button variant="outlined" color="grey">
          Previous
        </Button>
        <Pagination
          component="div"
          count={zones.length}
          onChange={handleChangePage}
          page={page}
          // rowsPerPage={rowsPerPage}
          // rowsPerPageOptions={[10, 20, 50, 100]
        />
        <Button variant="outlined" color="grey">
          Next
        </Button>
      </div>
    </>
  );
}
