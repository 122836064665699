import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Tables from "./NepzaTable";
// import SelectBox from "devextreme-react/select-box";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    minHeight: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "30px",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TotalRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: blue[500] },
}))(LinearProgress);
const ProcessedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: green[500] },
}))(LinearProgress);
const InProgreeRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: purple[500] },
}))(LinearProgress);
const DeclinedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: red[500] },
}))(LinearProgress);

export default () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState({});
  const [charts, setCharts] = useState([]);

  const user = localStorage.getItem("userDetails");

  const userData = JSON.parse(user);
  const classes = useStyles();
  const getStats = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/application/admin_stats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (response.status === 200) {
        setStat(data);
        setCharts(data.charts);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to load record", "error");
      setLoading(false);
    }
  };

  const formatMoney = (money) => {
    return `$ ${money.toLocaleString()}`;
  };

  React.useEffect(() => {
    getStats();
  }, []);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {stat.cards
          ? stat.cards.map((item, index) => (
              <Grid key={`item${index}`} item xs={12} sm={6} md={3}>
                <Paper className={classes.paper}>
                  <div className={classes.cards}>
                    <Typography variant="h4" style={{ color: blue[500] }}>
                      {item.isMoney ? formatMoney(item.data) : item.data}
                    </Typography>
                    <CheckCircleOutlinedIcon style={{ color: blue[500] }} />
                  </div>
                  <div>
                    <Typography>{item.title}</Typography>
                  </div>
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>
      {charts.length
        ? charts.map((item, index) => (
            <Grid key={index + item.title} item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Chart id="chart" dataSource={item.data}>
                  <Series
                    valueField="occurrence"
                    argumentField="name"
                    name={item.title}
                    type="bar"
                    color="#ffaa66"
                  />
                </Chart>
              </Paper>
            </Grid>
          ))
        : null}
      {/* <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
          <Typography>Request Amount</Typography>
          <Tables list={stat?.upcoming_renewals} renewal={true} />
          </Paper>
        </Grid> */}
    </div>
  );
};
