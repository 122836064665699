import React from "react";
import ZoneManagementApplicationTable from "./ZoneManagementApplicationTable";

const ZoneManagementApplication = () => {
  return (
    <div>
      <ZoneManagementApplicationTable />
    </div>
  );
};

export default ZoneManagementApplication;
