import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import UserProfileModal from "../components/UserProfileModal";
import NewUserProfileModal from "../components/NewUserProfileModal";
import axios from "axios";
import { AppConfig } from "app-config";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "600px",
    margin: "80px 200px",
  },

  field: {
    marginBottom: "2rem",
  },

  avatar: {
    backgroundColor: "gray",
    color: "blue",
  },
  btn: {
    // position: "absolute",
    // right: "20px",
    // top: "5px",
    marginLeft: "900px",
  },
  dialog: {
    minWidth: "400px",
    padding: "3rem",
  },
  profileImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50%",
    cursor: "pointer",
    marginBottom: "3rem",
  },
  simpledialog: {
    padding: "1rem 2rem",
  },
});

const emails = ["username@gmail.com", "user02@gmail.com"];

const UserProfile = () => {
  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const [file, setFile] = useState("");
  const [modal, setModal] = useState(false);
  const [profile, setProfile] = useState(null);

  const classes = useStyles();

  const id = userData.data.id;

  //get enterprise detail
  const getEnterprise = async (e) => {
    try {
      const response = await axios.get(`${AppConfig.URL}/users/${id}/find`, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, message, data } = response;

      if (status) {
        setProfile(data);
        console.log("User Profile Data", data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //update profile
  const updateProfile = (userId, newProfile) => {
    const formData = new FormData();
    formData.append("fullname", newProfile.fullname);
    formData.append("email", newProfile.email);
    formData.append("phone", newProfile.phone);
    formData.append("address", newProfile.address);
    formData.append("profile_image", newProfile.profile_image);
    axios
      .post(`${AppConfig.URL}/users/update/${userId}`, formData, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      })
      .then((response) => {
        setProfile(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEnterprise();
  }, []);

  console.log("PROFILE", profile?.data);

  return (
    <div
      style={{
        marginTop: "4rem",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <NewUserProfileModal
          setModal={setModal}
          updateProfile={updateProfile}
          profile={profile}
          userId={profile?.data.id}
        />

        <Card className={classes.root}>
          <CardContent>
            <p style={{ textAlign: "center" }}>Update Profile</p>
            <form>
              <div className={classes.formInput}>
                <label htmlFor="file">
                  <img
                    src={
                      profile?.data?.profile_image
                        ? profile?.data?.profile_image
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt="profilepic"
                    className={classes.profileImage}
                  />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", mb: "2rem" }}
              >
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Name"
                  variant="outlined"
                  typ="text"
                  className={classes.field}
                  value={`${profile?.data?.fullname}`}
                />
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Email"
                  variant="outlined"
                  type="email"
                  className={classes.field}
                  value={`${profile?.data?.email}`}
                />
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Phone"
                  variant="outlined"
                  type="text"
                  className={classes.field}
                  value={`${profile?.data?.company?.phone}`}
                />

                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Address"
                  variant="outlined"
                  type="text"
                  className={classes.field}
                  value={`${profile?.data?.company?.address}`}
                />
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default UserProfile;
