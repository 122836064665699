import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, CircularProgress, TablePagination, colors, capitalize, Menu, MenuItem
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { AppConfig } from 'app-config';
import Swal from 'sweetalert2';
import { green, grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: green[200],
        color: theme.palette.common.black
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: grey[50]
        }
    }
}))(TableRow);

const useStyles = makeStyles(theme => ({
    button: {
        margin: '1rem',
        backgroundColor: '#3f51b5',
        color: 'white',
        fontSize: '16px',
        width: '12rem',
        padding: '1rem',
        borderRadius: '8px'
    },
    table: {
        minWidth: 700
    },
    spinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    delBtn: {
        color: 'red'
    },
    norec: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
        flexDirection: 'column'
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2)
    },
    action: {
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    payBtn: {
        color: colors.green[600]
    },
    head: {
        backgroundColor: grey[200]
    },
    bold: {
        fontWeight: "bold"
    }
}));

export default function AdminZonesList() {

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginated, setPaginated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [promoters, setPromoters] = useState([])
    const user = localStorage.getItem('userDetails');
    const [open, setOpen] = useState(false);

    const userData = JSON.parse(user)

    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPaginated(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const getPromoters = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${AppConfig.URL}/company/list?type=promoter`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userData.access_token}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                console.log(data)
                setPromoters(data.data)
            } else {
                Swal.fire('', message, 'info');
            }
            setLoading(false)
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to get promoters', 'error');
            setLoading(false)
        }
    }

    useEffect(() => {
        getPromoters();
    }, []);

    if (!loading && promoters.length === 0) {
        return (
            <div className={classes.norec}>
                <Typography component="h1" variant="h3">
                    No record found
        </Typography>

                <Button
                    className={classes.button}
                    color="primary"
                    onClick={getPromoters}
                    variant="contained">
                    Go Back
                </Button>
            </div>
        );
    } else if ((loading && !paginated) || !promoters.length) {
        return (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        );
    }

    const handleView = (id) => {
        history.push(`/admin-account/promoter-detail/${id}`)
    }

    return (
        <>
            <Typography color="textSecondary" gutterBottom variant="body2">
                Page {page + 1} of{' '}
                {Math.ceil(promoters.length / 10)}
            </Typography>
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="customized table" className={classes.table}>
                    <TableHead className={classes.head}>
                        <TableRow>
                            <StyledTableCell>S/N</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Address</StyledTableCell>
                            <StyledTableCell>Phone</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            {/* <StyledTableCell>RC Number</StyledTableCell> */}
                            <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {promoters &&
                            promoters.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((promoter, index) => (

                                <StyledTableRow key={promoter.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {page * 10 + index + 1}
                                    </StyledTableCell>
                                    {/* <TableCell>{index + 1}</TableCell> */}
                                    <TableCell>{promoter.name || "N/A"}</TableCell>
                                    <TableCell align="left">{promoter.address || "N/A"}</TableCell>
                                    <TableCell align="left">{promoter.phone || "N/A"}</TableCell>
                                    <TableCell align="left">{promoter.email || "N/A"}</TableCell>
                                    {/* <TableCell align="left">{promoter.rc_no || "N/A"}</TableCell> */}
                                    <TableCell>
                                        {<Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleView(promoter.id)}
                                            className={classes.button}
                                            size="large"
                                            disabled={loading}
                                        >
                                            View
                                        </Button>}
                                    </TableCell>

                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={promoters.length}
                    onChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>

        </>
    );
};



