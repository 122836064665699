import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Paper,
  Typography,
  Chip,
  TextField,
  TextareaAutosize,
  Button,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import { AppConfig } from "app-config";
import circleCheck from "images/check-circle.svg";
import downoadCloud from "images/download-cloud.svg";
import avatar from "images/avatar.svg";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment/moment";
import axiosInstance from "Api/Api";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: "3rem",
    paddingInline: "2rem",
    background: "white",
    display: "flex",
    gap: "2em",
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  heading: {
    textAlign: "center",
    paddingTop: 32,
  },
  greyText: {
    fontWeight: "500",
    fontSize: "16px",
    color: "#B0AEAE",
    marginTop: "16px",
  },
  blackText: {
    fontWeight: "500",
    fontSize: "14px",
    display: "block",
    color: "black",
    marginTop: "12px",
  },
  blackTextRow: {
    fontWeight: "500",
    fontSize: "14px",
    color: "black",
    marginTop: "12px",
  },
  greenBorder: {
    borderLeft: "3px solid #47BB78",
    padding: "10px",
    color: "#47BB78",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "18px",
  },
  boxIndent: {
    paddingInline: "31px",
  },
  greyBg: {
    background: "rgba(250, 250, 250, 0.8)",
    marginBottom: "14px",
  },
  halfWidth: {
    width: "50%",
  },
  quarterWidth: {
    width: "30%",
  },
  documentRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "20px",
  },
  boldText: {
    fontWeight: "600",
    fontSize: "14px",
    display: "block",
    color: "black",
  },

  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  chip: {
    background: "rgba(71, 187, 120, 0.3)",
    borderRadius: "29px",
    color: " #47BB78",
  },
  textarea: {
    padding: "10px 0px 10px 17px",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
  },
  button: {
    marginTop: "27px",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginBottom: "1rem",
    height: "100%",
  },
  textField: {
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      marginBottom: "10px",
      backgroundColor: "white",
    },
  },
}));

const FinanceAppInfo = ({ setView, id }) => {
  const classes = useStyles();
  const [approvalList, setApprovalList] = useState([]);
  const [loading, setLoading] = useState([]);
  const [zones, setZones] = useState([]);
  const [comment, setComment] = useState("");
  const [defaultValue, setDefaultValue] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);

  const params = useParams();

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  const getZones = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/application_get/${params.rowId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getAdmin().access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data);
        setZones(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to get zones", "error");
      setLoading(false);
    }
  };

  const getApprovalList = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/approval_instances/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAdmin().access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setApprovalList(data);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to approval actions", "error");
    }
  };

  const handleSend = (e) => {
    setDefaultValue(e.target.value);
  };

  const approval = async () => {
    setSendLoading(true);
    axiosInstance

      .post(`/application_approval/add`, {
        comment,
        approval_instance_id: defaultValue,
        application_id: params.rowId,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire(
            "Approval recorded",
            "Your approval has been recorded successfully",
            "success",
          ).then((res) => (res.isConfirmed ? setView(null) : null));
        } else {
          Swal.fire("", response.message, "info");
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire(
          "Your approval could not be processed",
          error.message,
          "error",
        );
      })
      .finally(() => setSendLoading(false));
  };

  useEffect(() => {
    getZones();
    getApprovalList();
  }, []);

  if (!loading && zones.length === 0) {
    return (
      <div className={classes.norec}>
        <Typography component="h1" variant="h3">
          No record found
        </Typography>

        <Button
          className={classes.button}
          color="primary"
          onClick={getZones}
          variant="contained"
        >
          Go Back
        </Button>
      </div>
    );
  } else if (loading || !zones.length) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Application information</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Enterprise name
              <span className={classes.blackText}>
                {zones[0]?.company?.name}
              </span>
            </p>
            {/* <p className={classes.greyText}>
              RCI number
              <span className={classes.blackText}>
                {" "}
                {zones[0]?.company?.rc_no}
              </span>
            </p> */}
            <p className={classes.greyText}>
              Enterprise address
              <span className={classes.blackText}>
                {" "}
                {zones[0]?.company?.address}
              </span>
            </p>
            {/* <p className={classes.greyText}>
               FZC
              <span className={classes.blackText}>
                {zones[0]?.company?.email}
              </span>
            </p> */}
            <p className={classes.greyText}>
              Email
              <span className={classes.blackText}>
                {zones[0]?.company?.email}
              </span>
            </p>
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Application details</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Application type
              <span className={classes.blackText}>OPL application</span>
            </p>
            <p className={classes.greyText}>
              Remitta code
              <span className={classes.blackText}>
                {zones[0]?.remitta_code}
              </span>
            </p>
            <p className={classes.greyText}>
              Application fee
              <span className={classes.blackText}>
                {formatAsMoney(zones[0]?.fee)}
              </span>
            </p>
            <p className={classes.greyText}>
              Application date
              <span className={classes.blackText}>
                {" "}
                {moment(zones[0]?.application_date).format("DD/MM/YY")}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.quarterWidth}>
        <div
          className={classes.greyBg}
          style={{ overflowY: "scroll", height: "20rem" }}
        >
          <div className={classes.greenBorder}>Approval Trail</div>
          <div className={classes.boxIndent}>
            {zones[0].application_approvals.map((approvals) => (
              <>
                <div
                  key={approvals.application_id}
                  className={classes.documentRow}
                >
                  <img src={circleCheck} alt="circle-icon" />
                  {approvals?.comment}
                </div>
              </>
            ))}
          </div>
        </div>

        <div
          className={classes.greyBg}
          style={{ overflowY: "scroll", height: "20rem" }}
        >
          <div className={classes.greenBorder}>Authority remarks</div>
          <div className={classes.boxIndent}>
            {zones[0].application_approvals.map((item) => (
              <>
                <div className={classes.documentRow}>
                  <img src={avatar} alt="circle-icon" />
                  {item?.approval_instance?.approval_stage?.approval_stage}
                </div>
                <span className={classes.greyText}>{item?.comment}</span>
              </>
            ))}
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Payment confirmation</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Amount Paid:
              <span className={classes.blackTextRow}>
                {formatAsMoney(zones[0]?.fee)}
              </span>
            </p>
            <p className={classes.greyText}>
              Remitta Code:
              <span className={classes.blackTextRow}>
                {zones[0]?.remitta_code}
              </span>
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid black",
                background: "white",
                padding: "5px 10px",
                marginTop: "1em",
              }}
            >
              <TextField
                select
                onChange={handleSend}
                variant="filled"
                className={classes.textField}
                fullWidth
                style={{ fontStyle: "10px", background: "white" }}
                InputProps={{ disableUnderline: true }}
              >
                {approvalList.map((list, index) => (
                  <MenuItem key={index} value={list.approval_instance_id}>
                    {list.approval_type}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Remarks</div>
          <div className={classes.boxIndent}>
            <TextareaAutosize
              className={classes.textarea}
              maxRows={4}
              onChange={handleCommentChange}
              aria-label="maximum height"
              placeholder="Remarks"
              style={{ width: "100%" }}
            />
          </div>
          <Button
            // autoFocus
            fullWidth
            variant="contained"
            color="primary"
            onClick={approval}
            // onClick={handleSubmit}
            className={classes.button}
            size="large"
          >
            {sendLoading ? (
              <div className={classes.spinner}>
                <CircularProgress
                  style={{ height: "30px", width: "30px", margin: 0 }}
                />
              </div>
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FinanceAppInfo;
