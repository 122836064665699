import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Paper,
  Typography,
  Chip,
  TextField,
  TextareaAutosize,
  Button,
  Checkbox,
  MenuItem,
} from "@material-ui/core";
import circleCheck from "images/check-circle.svg";
import downoadCloud from "images/download-cloud.svg";
import avatar from "images/avatar.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: "3rem",
    paddingInline: "2rem",
    background: "white",
    display: "flex",
    gap: "2em",
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  heading: {
    textAlign: "center",
    paddingTop: 32,
  },
  greyText: {
    fontWeight: "500",
    fontSize: "16px",
    color: "#B0AEAE",
    marginTop: "16px",
  },
  blackText: {
    fontWeight: "500",
    fontSize: "14px",
    display: "block",
    color: "black",
    marginTop: "12px",
  },
  greenBorder: {
    borderLeft: "3px solid #47BB78",
    padding: "10px",
    color: "#47BB78",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "18px",
  },
  select: {
    width: "90%",
    maxWidth: 375,
    margin: "1.4rem",
    backgroundColor: "white",
  },
  boxIndent: {
    paddingInline: "31px",
  },
  greyBg: {
    background: "rgba(250, 250, 250, 0.8)",
    marginBottom: "14px",
  },
  halfWidth: {
    width: "50%",
  },
  documentRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "20px",
  },
  boldText: {
    fontWeight: "600",
    fontSize: "14px",
    display: "block",
    color: "black",
  },
  inputField: {
    marginBottom: "20px",
    width: "100%",
    background: "white",
    border: "1px solid black",
    "& .MuiFilledInput-multiline": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
      marginBottom: "10px",
    },
  },

  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  chip: {
    background: "rgba(71, 187, 120, 0.3)",
    borderRadius: "29px",
    color: " #47BB78",
  },
  textarea: {
    padding: "10px 0px 10px 17px",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
  },
  button: {
    marginTop: "27px",
    textTransform: "capitalize",
  },
  textField: {
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      marginBottom: "10px",
      backgroundColor: "white",
    },
  },
}));

const ZoneApplicationDetail = () => {
  const classes = useStyles();
  const [docTypes, setDocTypes] = useState([]);
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);
  const [document, setDocument] = useState("");
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState("MD");

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const selectDocument = (e) => {
    setDocument(e.target.value);
  };

  const handleSend = (e) => {
    setDefaultValue(e.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Zone management information</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              ZMC names
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              RCI number
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              ZMC address
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              FZC
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              Email
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Application details</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Application type
              <span className={classes.blackText}>OPL application</span>
            </p>
            <p className={classes.greyText}>
              Remitta code
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              Application fee
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              Application date
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
          </div>
        </div>
      </div>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Uploaded documents</div>
          <div className={classes.boxIndent}>
            <div className={classes.documentRow}>
              <img src={circleCheck} alt="circle-icon" />
              <span className={classes.boldText}>Semira enterprise</span>
              <img src={downoadCloud} alt="circle-icon" />
            </div>

            <div className={classes.documentRow}>
              <img src={circleCheck} alt="circle-icon" />
              <span className={classes.boldText}>Semira enterprise</span>
              <img src={downoadCloud} alt="circle-icon" />
            </div>

            <div className={classes.documentRow}>
              <img src={circleCheck} alt="circle-icon" />
              <span className={classes.boldText}>Semira enterprise</span>
              <img src={downoadCloud} alt="circle-icon" />
            </div>
          </div>
        </div>
        {/* <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Approval Trail</div>
          <div className={classes.boxIndent}>
            <div className={classes.space}>
              <div className={classes.documentRow}>
                <img src={circleCheck} alt="circle-icon" />

                <span className={classes.boldText}>
                  Certificate of occupancy site
                </span>
                <img src={circleCheck} alt="circle-icon" />
              </div>
              <Chip label={"Approved"} className={classes.chip} />
            </div>
          </div>
        </div> */}
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Approval form</div>

          <div style={{ padding: "5px 20px" }}>
            <form noValidate autoComplete="off">
              <TextField
                select
                className={classes.inputField}
                onChange={handleTypeChange}
                variant="filled"
                // label="Select Approval Action"
                defaultValue="select"
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem value="select" style={{ background: "white" }}>
                  <em>Select</em>
                </MenuItem>
                {/* {zmcDetails?.data?.documents.length == 0 && zmcDetails?.data?.management_team_members.length == 0 ?
        <MenuItem value="approved" disabled>Approve</MenuItem> : <MenuItem value="approved" >Approve</MenuItem>
    } */}
                <MenuItem value="approval">Approval Action</MenuItem>
                <MenuItem value="approved">Approve</MenuItem>
                <MenuItem value="request_information">
                  Request for Information
                </MenuItem>
                <MenuItem value="declined">Decline</MenuItem>
              </TextField>

              {/* <p>
                Document re-upload required?
                <Checkbox
                  checked={checked}
                  // onChange={handleChange}
                  onChange={() => setChecked(!checked)}
                  color="primary"
                />
              </p> */}
              {checked && (
                <TextField
                  id="outlined-error-helper-text"
                  select
                  className={classes.select}
                  variant="outlined"
                  label="Select a Document"
                  name="DocumentId"
                  value={document || ""}
                  onChange={(e) => selectDocument(e)}
                  disabled={loading}
                >
                  {docTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id || ""}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                rows={4}
                multiline
                onChange={handleCommentChange}
                className={classes.inputField}
                label="Remarks"
                variant="filled"
                InputProps={{ disableUnderline: true }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid black",
                  background: "white",
                  padding: "5px 10px",
                }}
              >
                <p>Send to:</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={avatar} alt="avatar" />
                  <TextField
                    select
                    onChange={handleSend}
                    variant="filled"
                    defaultValue="md"
                    className={classes.textField}
                    style={{ fontStyle: "10px" }}
                    InputProps={{ disableUnderline: true }}
                  >
                    <MenuItem value="md">MD</MenuItem>
                    <MenuItem value="finance">Finance</MenuItem>
                    <MenuItem value="legal">Legal</MenuItem>
                  </TextField>
                </div>
              </div>

              {/* {approvalLoading ? (
                <Button>Loading...</Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="secondary"
                >
                  Submit
                </Button>
              )} */}
            </form>
          </div>
        </div>
        <div className={classes.greyBg}>
          {/* <div className={classes.greenBorder}>Remarks</div>
          <div className={classes.boxIndent}>
            <TextareaAutosize
              className={classes.textarea}
              maxRows={4}
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
      ut labore et dolore magna aliqua."
              style={{ width: "100%" }}
            />
          </div> */}
          {approvalLoading ? (
            <Button>Loading...</Button>
          ) : (
            <Button
              // autoFocus
              fullWidth
              variant="contained"
              color="primary"
              // onClick={handleSubmit}
              className={classes.button}
              size="large"
            >
              Send
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ZoneApplicationDetail;
