import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Tables from "../Dashboard/Table";
import TotalRequestIcon from "../../images/totalRequestIcon.svg";
import TotalRequestAmountIcon from "../../images/totalRequestAmountIcon.svg";
import PendingRequestIcon from "../../images/pendingRequestIcon.svg";
import ApprovedRequestIcon from "../../images/approvedRequestIcon.svg";
import DeclinedRequestIcon from "../../images/declinedRequestIcon.svg";
import blueLocation from "../../images/blue-location.svg";
import orangeLocation from "../../images/orange-location.svg";
import greenTv from "../../images/green-tv.svg";
import greenBox from "../../images/green-box.svg";
import greenWallet from "../../images/green-wallet.svg";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "35px",
    marginTop: "20px",
  },
  bottomData: {
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "white",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    background: "rgba(245, 244, 244, 0.8)",
    flexGrow: 0,
  },
  whiteBg: {
    background: "white",
    padding: "35px",
  },
  inline: {
    display: "inline-block",
    padding: "8px 8px 8px 16px",
    background: "rgba(245, 244, 244, 0.8)",
    marginBottom: "51px",
  },
}));

const icons = [
  blueLocation,
  orangeLocation,
  greenTv,
  greenBox,
  TotalRequestAmountIcon,
];


export default () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState({});

  const user = localStorage.getItem("userDetails");
  const [charts, setCharts] = useState({});

  const [filter, setFilter] = useState("private");

  const userData = JSON.parse(user);

  console.log(userData);
  const classes = useStyles();
  
  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/application/admin_stats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setStat(data);
        setCharts(data.charts);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  const getRevenue = async () => {
    // setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/super-admin/revenue`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  const formatMoney = (money) => {
    return `$ ${money.toLocaleString()}`;
  };

  React.useEffect(() => {
    getCategories();
    getRevenue();
  }, []);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  const history = useHistory();

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {stat.cards
          ? stat.cards.map((item, index) => (
              <Grid
                key={`item${index}`}
                item
                xs={12}
                sm={6}
                md={4}
                onClick={() => {
                  history.push("/admin-account/company-info?type=1" + index);
                }}
              >
                <div className={classes.paper}>
                  <div className={classes.cards}>
                    <img src={icons[index]} alt="icon" />
                    <div>
                      <Typography
                        variant="h5"
                        style={{ color: "black", marginBottom: "12px" }}
                      >
                        {item.isMoney ? formatMoney(item.data) : item.data}
                      </Typography>
                      <Typography style={{ color: "#B0AEAE" }}>
                        {item.title}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Grid>
            ))
          : null}
      </Grid>
      <div className={classes.whiteBg}>
        <div className={classes.inline}>
          <Button
            variant={filter == "private" && "contained"}
            color={filter == "private" ? "primary" : ""}
            onClick={() => {
              setFilter("private");
            }}
          >
            Private Zones
          </Button>
          <Button
            variant={filter == "public" && "contained"}
            color={filter == "public" ? "primary" : ""}
            onClick={() => {
              setFilter("public");
            }}
          >
            Public Zones
          </Button>
        </div>
        <Grid item xs={12} sm={12}>
          <Chart
            id="chart"
            dataSource={filter == "private" ? charts.private : charts.public}
          >
            <Series
              valueField="amount"
              argumentField="month"
              name={filter}
              type="bar"
              color="#ffaa66"
            />
          </Chart>
        </Grid>
      </div>
    </div>
  );
};
