import {
  Box,
  Button,
  Fade,
  Modal,
  Typography,
  Backdrop,
} from "@material-ui/core";
import React, { useState } from "react";
import DoneIcon from "@material-ui/icons/Done";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  p: 4,
};

const Success = () => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          // onClick={handleOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: { xs: "300px", sm: "500px", md: "600px" },
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#1976D2",
                borderRadius: "100%",
                width: "100px",
                height: "100px",
                color: "white",
              }}
            >
              <DoneIcon fontSize="large" fontWeight="bold" />
            </div>
            <Typography
              id="modal-modal-description"
              sx={{
                mt: 2,
                color: "#242424",
                fontFamily: "Avenir Roman",
                fontWeight: 800,
                fontSize: "1.5rem",
              }}
            >
              Message Sent!
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "Avenir Roman",
                fontWeight: 400,
                fontSize: "1rem",
                mt: 2,
              }}
            >
              Thanks for filling this form, we will get back to you shortly
            </Typography>
            {/* <Button
              variant="contained"
              sx={{
                px: "50px",
                marginTop: "0.8rem",
                borderRadius: "20px",
                fontFamily: "Avenir Roman",
                fontWeight: 400,
                fontSize: "1rem",
                backgroundColor: "#1976d2",
                "& .MuiButton-root": {
                  textTransform: "capitalize",
                  color: "red",
                },
              }}
              className="Button"
              href="/"
            >
              Back To Homepage
            </Button> */}
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Success;
