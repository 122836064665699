import HomeIcon from "@material-ui/icons/Home";
import Homepage from "./pages/Homepage";
import AddBoxIcon from "@material-ui/icons/AddBox";
import ZMCCreateApplication from "./pages/ZmcDashboard/StartZmcApplication";
import CreateApplication from "./pages/StartRequest";
import StartRegistration from "./pages/StartRegistration";
import { AccountCircle, People, CheckBox } from "@material-ui/icons";
import RoomIcon from "@material-ui/icons/Room";
import Tariffs from "./pages/TariffList";
import ProfileUpdate from "./pages/ProfileUpdate";
import Users from "./pages/Users";
import TeamCreate from "./pages/TeamCreate";
import Teams from "./pages/Teams";
import Zones from "./pages/Zones";
import ZmcHomepage from "./pages/ZmcHomepage";
import ZoneManagementChecklist from "./pages/ZoneManagementChecklist";
import CheckList from "./pages/checklist/CheckList";
import DashboardIcon from "./images/dashboardIcon.svg";
import PlusIcon from "./images/plus.png";
import RequestInventoryIcon from "./images/requestInventory.svg";
import RegistrationRequirementIcon from "./images/document-text.svg";
import TarrifIcon from "./images/empty-wallet-change.svg";
import UserIcon from "./images/userIcon.svg";
import TrainingIcon from "./images/trainingIcon.svg";
import Trainings from "./pages/EnterpriseApplications/Trainings";
import ViewCertificateIcon from "./images/viewCertificate.svg";
import nepzaServiceIcon from "./images/nepzaServices.svg";
import directorsIcon from "./images/directorsIcon.svg";
import EnterpriseRegistration from "./pages/EnterpriseApplications/EnterpriseRegistration";
import StartEnterpriseRequest from "./pages/EnterpriseApplications/StartEnterpriseRequest";
import RequestInventory from "./pages/EnterpriseApplications/RequestInventory";
import OplApplication from "./pages/EnterpriseApplications/OplApplication";
import NameChange from "./pages/EnterpriseApplications/NameChange";
import ZMCOplApplication from "./pages/ZMC/OplApplication";
import { AppConfig } from "app-config";
import Inventory from "pages/ZmcDashboard/Inventory/Inventory";
import StartCertificateApplication from "pages/FZC/ZmcCertificateOfApplication/StartCertificateApplication";
import Enterprises from "pages/ZmcDashboard/enterprises/Enterprise";
import EnterpriseDetails from "pages/ZMC/EnterpriseDetails";
import NepzaServices from "pages/ZMC/nepzaServices/NepzaServices";
import EnterpriseApplications from "pages/ZmcDashboard/enterprises/EnterpriseApplication";
import ViewCertificateOfRegistration from "./pages/EnterpriseApplications/ViewCertificateOfRegistration";
import OplCertificate from "./pages/EnterpriseApplications/OplCertificate";
import ZMCEnterpriseDashboard from "pages/ZmcDashboard/enterprises/Dashboard";
import EnterpriseInventories from "pages/ZmcDashboard/Inventory/enterpriseInventories";
import UserProfile from "./pages/UserProfile";
import axios from "axios";
import TotalRequestAmount from "pages/ZmcDashboard/requests/TotalRequestAmount";
import GeneratedRevenue from "pages/ZmcDashboard/requests/GeneratedRevenue";

const getUserDetails = () => {
  return JSON.parse(localStorage.getItem("userDetails"));
};

export const fzcItems = [
  {
    path: "/me/zmc-dashboard",
    title: "Dashboard",
    icon: DashboardIcon,
    // component: ZmcDashboard
    component: ZmcHomepage,
  },
  // {
  //   path: "/me/request/zmc",
  //   title: "Start Registration",
  //   icon: PlusIcon,
  //   component: ZMCCreateApplication,
  // },
  {
    path: "/me/profile",
    component: UserProfile,
  },
  {
    path: "/me/request/certificate",
    title: "Start Registration",
    icon: PlusIcon,
    component: StartCertificateApplication,
  },

  {
    path: "/me/inventory",
    title: "Request Inventory",
    icon: RequestInventoryIcon,
    component: Inventory,
  },
  {
    path: "/me/checklist-zone",
    title: "Registration Requirements",
    icon: RegistrationRequirementIcon,
    component: ZoneManagementChecklist,
  },
  {
    path: "/me/request/zmc",
    title: "ZDL registration",
    icon: PlusIcon,
    component: ZMCCreateApplication,
  },
  // {
  //   path: "/me/zmc-profile",
  //   title: "Profile",
  //   icon: AccountCircle,
  //   component: ProfileUpdate,
  // },
  // {
  //   path: "/me/users",
  //   title: "Users",
  //   icon: People,
  //   component: Users,
  // },
  // {
  //   path: "/me/teams",
  //   title: "Directors",
  //   icon: People,
  //   component: Teams,
  // },
  // {
  //   path: "/me/team/create",
  //   title: "TeamCreate",
  //   icon: People,
  //   component: TeamCreate,
  //   hidden: true,
  // },
  {
    path: "/me/zmc-zones",
    title: "My Enterprises",
    icon: UserIcon,
    component: Enterprises,
  },
];
export const zmcItems = [
  {
    path: "/me",
    title: "Dashboard",
    icon: DashboardIcon,
    component: ZmcHomepage,
  },
  {
    path: "/me/profile",
    component: UserProfile,
  },

  {
    path: "/me/request/opl-application",
    title: "OPL Application",
    icon: PlusIcon,
    component: ZMCOplApplication,
  },
  {
    path: "/me/inventory",
    title: "Request Inventory",
    icon: RequestInventoryIcon,
    component: Inventory,
  },
  {
    path: "/me/zmc-enterprise-request",
    component: EnterpriseInventories,
  },
  {
    path: "/me/zmc-total-requests-amount",
    component: TotalRequestAmount,
  },
  {
    path: "/me/zmc-generated-revenue",
    component: GeneratedRevenue,
  },
  {
    path: "/me/opl-cert",
    title: "View OPL Certificate",
    icon: TrainingIcon,
    component: OplCertificate,
  },
  // {
  //   path: "/me/zmc-zones",
  //   title: "My Enterprises",
  //   icon: UserIcon,
  //   component: Enterprises,
  //   groups: [{name: "My details"}, {name: "My Enterprises"}],
  // },
  {
    path: "/me/enterprise-det/:id",
    component: EnterpriseDetails,
  },
  {
    path: "/me/EnterpriseDashboard",
    component: ZMCEnterpriseDashboard,
  },
  {
    path: "/me/checklist-zone",
    title: "OPL Requirements",
    icon: RegistrationRequirementIcon,
    component: ZoneManagementChecklist,
  },
  {
    path: "/me/enterpriseApplication",
    // title: "OPL Requirements",
    // icon: RegistrationRequirementIcon,
    component: EnterpriseApplications,
  },
  {
    path: "/me/teams",
    title: "Directors",
    icon: directorsIcon,
    component: Teams,
  },
  {
    path: "/me/nepza-services",
    title: "Nepza Services",
    icon: nepzaServiceIcon,
    component: NepzaServices,
  },
];

export const partnerItems = [
  {
    path: "/me",
    title: "Dashboard",
    icon: DashboardIcon,
    component: Homepage,
  },
  {
    path: "/me/profile",
    component: UserProfile,
  },
  {
    path: "/me/register",
    title: "Start Registration",
    icon: PlusIcon,
    component: StartRegistration,
  },
  {
    path: "/me/request",
    title: "Request Inventory",
    icon: RequestInventoryIcon,
    component: CreateApplication,
  },

  {
    path: "/me/checklist",
    title: "Registration Requirements",
    icon: RegistrationRequirementIcon,
    component: CheckList,
  },
  {
    path: "/me/tariffs",
    title: "Tariffs",
    icon: TarrifIcon,
    component: Tariffs,
  },

  {
    path: "/me/users",
    title: "Users",
    icon: UserIcon,
    component: Users,
  },
];

export const enterpriseItems = [
  {
    path: "/me",
    title: "Dashboard",
    icon: DashboardIcon,
    component: Homepage,
  },
  {
    path: "/me/profile",
    component: UserProfile,
  },
  {
    path: "/me/registration",
    title: "Start Registration",
    icon: PlusIcon,
    component: StartEnterpriseRequest,
  },

  {
    path: "/me/enterprise-request",
    title: "Request Inventory",
    icon: RequestInventoryIcon,
    component: RequestInventory,
  },

  {
    path: "/me/checklist",
    title: "Registration Requirements",
    icon: RegistrationRequirementIcon,
    component: CheckList,
  },
];

export const otherEnterpriseItems = [
  {
    path: "/me",
    title: "Dashboard",
    icon: DashboardIcon,
    component: Homepage,
  },
  {
    path: "/me/profile",
    component: UserProfile,
  },

  {
    path: "/me/view-certificate",
    title: "View Certificate",
    icon: ViewCertificateIcon,
    component: ViewCertificateOfRegistration,
  },
  {
    path: "/me/enterprise-request",
    title: "Request Inventory",
    icon: RequestInventoryIcon,
    component: RequestInventory,
  },
  {
    path: "/me/opl-reg",
    title: "OPL Registration",
    icon: TrainingIcon,
    component: OplApplication,
  },
  {
    path: "/me/checklist",
    title: "Registration Requirements",
    icon: RegistrationRequirementIcon,
    component: CheckList,
  },
  // {
  //   path: "/me/namechange",
  //   title: "Change of Name",
  //   icon: RegistrationRequirementIcon,
  //   component: NameChange,
  // },
];

export const oplCertifiedEnterpriseItems = [
  {
    path: "/me",
    title: "Dashboard",
    icon: DashboardIcon,
    component: Homepage,
  },
  {
    path: "/me/profile",
    component: UserProfile,
  },

  {
    path: "/me/view-certificate",
    title: "View Regristration Certificate",
    icon: ViewCertificateIcon,
    component: ViewCertificateOfRegistration,
  },
  {
    path: "/me/enterprise-request",
    title: "Request Inventory",
    icon: RequestInventoryIcon,
    component: RequestInventory,
  },
  {
    path: "/me/opl-cert",
    title: "View Operating License",
    icon: TrainingIcon,
    component: OplCertificate,
  },
  {
    path: "/me/checklist",
    title: "Registration Requirements",
    icon: RegistrationRequirementIcon,
    component: CheckList,
  },
  // {
  //   path: "/me/namechange",
  //   title: "Change of Name",
  //   icon: RegistrationRequirementIcon,
  //   component: NameChange,
  // },
];

// const items = (getUserDetails()?.data?.company?.entity_type_id == 3) ? zmcItems : partnerItems;

export default {
  items: [
    ...partnerItems,
    ...fzcItems,
    ...enterpriseItems,
    ...zmcItems,
    ...otherEnterpriseItems,
    ...oplCertifiedEnterpriseItems,
  ],
};
