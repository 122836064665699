import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ApprovalHistory from "./ApprovalHistory";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
  },
  demo: {
    backgroundColor: theme.palette.background.default,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  primary: {
    color: "gray",
  },
  secondary: {
    color: "rgba(0, 0, 0, 0.87)",
    display: "block",
    fontSize: "18px",
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

export default function RequestDetails({ requestDetails, applicationHistory }) {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    requestDetails && (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Company Details
            </Typography>
            <div className={classes.demo}>
              <List dense>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails.company.name}
                      </span>
                    }
                    primary={
                      <span className={classes.primary}>Company Name:</span>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span
                        className={classes.secondary}
                      >{`${requestDetails.company.first_name} ${requestDetails.company.last_name}`}</span>
                    }
                    primary={"Full Name:"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails.company.rc_no}
                      </span>
                    }
                    primary={"RC Number"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails.company.address}
                      </span>
                    }
                    primary={"Address"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails.company.phone}
                      </span>
                    }
                    primary={"Phone Number"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails.company.email}
                      </span>
                    }
                    primary={"Email"}
                  />
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Service Request
            </Typography>
            <div className={classes.demo}>
              <List dense>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails.service.name}
                      </span>
                    }
                    primary="Service Requested:"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {requestDetails?.reference}
                      </span>
                    }
                    primary="Reference: "
                  />
                </ListItem>

                {requestDetails.service.fee_type === "fixed" ? null : (
                  <ListItem>
                    <ListItemText
                      secondary={
                        <span className={classes.secondary}>
                          {"$" +
                            new Number(requestDetails.value).toLocaleString(
                              "en-US"
                            )}
                        </span>
                      }
                      primary={
                        requestDetails.service.name === "Legal" ||
                        requestDetails.service.name === "Equitable"
                          ? "Property Value: "
                          : requestDetails.service.name === "Debenture"
                          ? "Value of Instrument"
                          : requestDetails.service.name ===
                            "Long Term Fixed Asset"
                          ? "Asset Value"
                          : "Value"
                      }
                    />
                  </ListItem>
                )}
                {requestDetails?.service?.processing_fee ? (
                  <ListItem>
                    <ListItemText
                      secondary={
                        <span className={classes.secondary}>
                          {!requestDetails?.service?.processing_fee
                            ? "N/A"
                            : requestDetails?.service?.processing_fee}
                        </span>
                      }
                      primary={
                        requestDetails.isProcessingFeePaid &&
                        requestDetails?.service?.processing_fee
                          ? "Processing fee | PAID:"
                          : "Processing fee:"
                      }
                    />
                  </ListItem>
                ) : null}
                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>{`$ ${new Number(
                        requestDetails.fee
                      ).toLocaleString("en-US")} `}</span>
                    }
                    primary={`Actual Fee | ${
                      requestDetails.service.payBefore
                        ? `Before Approval: ${
                            requestDetails.isPaid ? "PAID" : "UNPAID"
                          } `
                        : `After Approval: ${
                            requestDetails.isPaid ? "PAID" : "UNPAID"
                          } `
                    }`}
                  />
                  {/* <Chip label="paid" /> */}
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={
                      <span className={classes.secondary}>
                        {new Date(
                          requestDetails.application_date
                        ).toLocaleString()}
                      </span>
                    }
                    primary="Application Date: "
                  />
                </ListItem>
                {requestDetails?.remitta_code ? (
                  <ListItem>
                    <ListItemText
                      secondary={
                        <span className={classes.secondary}>
                          {requestDetails.remitta_code}{" "}
                        </span>
                      }
                      primary="Remitta code:"
                    />
                  </ListItem>
                ) : null}
              </List>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Uploaded Documents
            </Typography>
            {!requestDetails.application_attachments.length ? (
              <h1>No document found</h1>
            ) : (
              <div className={classes.demo}>
                <List dense={dense}>
                  {requestDetails.application_attachments.map(
                    (document, index) => (
                      <ListItem key={index + document.upload_date}>
                        <ListItemAvatar>
                          <Avatar>
                            <FolderIcon />
                          </Avatar>
                        </ListItemAvatar>

                        {!document.document ? null : (
                          <ListItemText
                            primary={document.document.name}
                            secondary={document.document.description}
                          />
                        )}

                        <a href={document.upload_url} target="_blank" download>
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="download">
                              <CloudDownloadIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </a>
                      </ListItem>
                    )
                  )}
                </List>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Approval History
            </Typography>
            <div className={classes.demo}>
              <List dense>
                <ApprovalHistory approvalHistory={applicationHistory} />
              </List>
            </div>
          </Grid>
          {requestDetails?.service?.name === "Zone OPL" ||
          requestDetails?.service?.name === "Zone OPL Renewal Fees" ||
          requestDetails?.service?.name === "Free Zone" ||
          requestDetails?.service?.name === "Industrial Park" ? (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.title}>
                  Zone Details
                </Typography>
                {requestDetails.zone !== null ? (
                  <div className={classes.demo}>
                    <List dense>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zone?.name}
                            </span>
                          }
                          primary="Name:"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zone?.address}
                            </span>
                          }
                          primary="Address: "
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zone?.zone_type}
                            </span>
                          }
                          primary="Zone Type: "
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zone?.state}
                            </span>
                          }
                          primary="State: "
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zone?.lga}
                            </span>
                          }
                          primary="LGA: "
                        />
                      </ListItem>
                    </List>
                  </div>
                ) : (
                  <p>No zone details found</p>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" className={classes.title}>
                  ZMC Details
                </Typography>
                {requestDetails.zmc !== null ? (
                  <div className={classes.demo}>
                    <List dense>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zmc?.name}
                            </span>
                          }
                          primary="Name:"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zmc?.address}
                            </span>
                          }
                          primary="Address: "
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zmc?.email}
                            </span>
                          }
                          primary="Email: "
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          secondary={
                            <span className={classes.secondary}>
                              {requestDetails?.zmc?.phone}
                            </span>
                          }
                          primary="Phone: "
                        />
                      </ListItem>
                    </List>
                  </div>
                ) : (
                  <p>No ZMC details found</p>
                )}
              </Grid>
            </>
          ) : null}
          {/* <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            Requested Information
          </Typography>
          <div className={classes.demo}>
            <List dense>
              {<ListItem>
        <ListItemAvatar>
          <Avatar>
            <CloudDownloadIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Photos" secondary="Jan 9, 2014" />
      </ListItem>}
            </List>
          </div>
        </Grid> */}
        </Grid>
      </div>
    )
  );
}
