import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  colors,
  TextField,
  Button,
  MenuItem,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import CircleCheck from "images/check-circle.svg";
import downoadCloud from "images/download-cloud.svg";
import avatar from "images/avatar.svg";
import { AppConfig } from "app-config";
import axiosInstance from "Api/Api";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import FolderIcon from "@material-ui/icons/Folder";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: "3rem",
    paddingInline: "2rem",
    background: "white",
    display: "flex",
    gap: "2em",
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  heading: {
    textAlign: "center",
    paddingTop: 32,
  },
  greyText: {
    fontWeight: "500",
    fontSize: "14px",
    color: "#B0AEAE",
    marginTop: "16px",
    fontFamily: "Jakarta",
  },
  blackText: {
    fontWeight: "500",
    fontSize: "14px",
    display: "block",
    color: "black",
    marginTop: "12px",
  },
  greenBorder: {
    borderLeft: "3px solid #47BB78",
    padding: "10px",
    color: "#47BB78",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "18px",
  },
  select: {
    width: "90%",
    maxWidth: 375,
    margin: "1.4rem",
    backgroundColor: "white",
  },
  boxIndent: {
    paddingInline: "31px",
  },
  greyBg: {
    background: "rgba(250, 250, 250, 0.8)",
    marginBottom: "14px",
  },
  halfWidth: {
    width: "50%",
  },
  documentRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "20px",
    justifyContent: "space-between",
  },
  boldText: {
    fontSize: "14px",
    display: "block",
    color: "black",
  },
  inputField: {
    marginBottom: "20px",
    width: "100%",
    background: "white",
    border: "1px solid black",
    "& .MuiFilledInput-multiline": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
      marginBottom: "10px",
    },
  },

  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  chip: {
    background: "rgba(71, 187, 120, 0.3)",
    borderRadius: "29px",
    color: " #47BB78",
  },
  textarea: {
    padding: "10px 0px 10px 17px",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
  },
  button: {
    marginTop: "27px",
    textTransform: "capitalize",
  },
  textField: {
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      marginBottom: "10px",
      backgroundColor: "white",
    },
  },

  formRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  demo: {
    // maxHeight: "100%",
    overflowY: "auto",
    position: "relative",
    maxHeight: "500px",
  },
}));

const SingleApplication = () => {
  const classes = useStyles();

  const [values, setValues] = useState({
    approval_instance_id: "",
    comment: "",
    document_id: "",
  });
  const [dense, setDense] = React.useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);
  const [document, setDocument] = useState("");
  const [comment, setComment] = useState("");
  const [defaultValue, setDefaultValue] = useState("MD");
  const [applicationId, setApplicationId] = useState(null);
  const [requestDetails, setRequestDetails] = useState({});
  const [documentId, setDocumentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [action, setAction] = useState("");
  const [applicationHistory, setApplicationHistory] = useState([]);
  const [approvalFile, setApprovalFile] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [approvalIntances, setApprovalIntances] = useState([]);
  const [open, setOpen] = useState(false);
  const [erorrMessage, setErrorMessage] = useState("");
  const [hasLicense, setHasLicense] = useState(null);
  const [service, setService] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [approvalList, setApprovalList] = useState([]);
  const [applicationID, setApplicationID] = useState(null);

  const params = useParams();
  const history = useHistory();

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const selectDocument = (e) => {
    setDocument(e.target.value);
  };

  const handleSend = (e) => {
    setDefaultValue(e.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //fetch request details
  const fetchRequestDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`/application/get/${params.id}`)
      .then(function (response) {
        if (response.status === 200) {
          console.log("BUG!!!", response);
          setApplicationHistory(response.data.data.history);
          setRequestDetails(response.data.data.application);
          applicationState(response.data.data);
          setApplicationID(response?.data?.application?.id);
          setDocumentId(response.data.data.application_attachments);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApprovalLoading(true);
    const { approval_instance_id, comment } = values;
    const formData = new FormData();
    formData.append("approval_instance_id", approval_instance_id);
    formData.append("comment", comment);
    formData.append("replace_document", checked);
    if (checked) {
      formData.append("document_id", values.document_id);
    }
    formData.append("application_id", applicationId);
    if (approvalFile) {
      formData.append("file_title", fileTitle);
      formData.append("file", approvalFile);
    }

    axiosInstance
      .post("/application_approval/add", formData)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);

          Swal.fire(
            "Approval recorded",
            "Your approval has been recorded successfully",
            "success"
          ).then((result) => {
            return result.isConfirmed
              ? history.push(`/admin-account/zone-admin-applications`)
              : null;
          });
        }
      })
      .catch(function (error) {
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setApprovalLoading(false));
  };

  const fetchAllApprovalInstances = () => {
    setLoading(true);

    axiosInstance
      .get("/approval_instances/list")
      .then(function (response) {
        if (response.status === 200) {
          setApprovalIntances(response.data.data);
          // console.log(approvalIntances);
          // console.log(props.match.params);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const applicationState = async (data) => {
    // setApprovalLoading(true);
    console.log("ZMCAPPDATA", data?.application?.service?.service_type_id);
    const service = data?.application?.service?.service_type_id;
    setService(service);
    console.log({ service });
    try {
      const response = await fetch(
        `${AppConfig.URL}/license/list/${params.id}/${service}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${getAdmin().access_token}`,
          },
        }
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data, message);
        setHasLicense(data.has_license);
      } else {
        Swal.fire("", message, "info");
      }
      // setApprovalLoading(false);
    } catch (error) {
      // Swal.fire("Something went wrong", "Unable to get zones", "error");
      // setApprovalLoading(false);
    }
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const generateLicense = async () => {
    
    setSendLoading(true);
    axiosInstance

      // .post(`/license/generate/${params.id}/${service}`)
      .post(`/application_approval/add`, {
        application_id: applicationId,
        approval_instance_id: 4,
        comment: "Approved by legal",
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire(
            "License generated",
            "Your request was processed successfully",
            "success"
          ).then((res) =>
            res.isConfirmed
              ? history.push("/admin-account/zone-applications")
              : null
          );
        } else {
          Swal.fire("", response.message, "info");
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire(
          "Sorry this action could not be completed",
          error.message,
          "error"
        );
      })
      .finally(() => setSendLoading(false));
  };

  // const generateLicense = async () => {
  //   console.log("LOG__ID", applicationID);
  //   setSendLoading(true);
  //   axiosInstance

  //     // .post(`/license/generate/${Number(id)}/${service}`)
  //     .post(`/application_approval/add`, {
  //       approval_instance_id: 4,
  //       comment: "approved by legal",
  //       application_id: applicationID,
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         Swal.fire(
  //           "License generated",
  //           "Your request was processed successfully",
  //           "success",
  //         ).then((res) =>
  //           res.isConfirmed
  //             ? history.push("/admin-account/zone-applications")
  //             : null,
  //         );
  //       } else {
  //         Swal.fire("", response.message, "info");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       Swal.fire(
  //         "Sorry this action could not be completed",
  //         error.message,
  //         "error",
  //       );
  //     })
  //     .finally(() => setSendLoading(false));
  // };

  //handle close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getApprovalList = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/approval_instances/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAdmin().access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setApprovalList(data);
        console.log(data);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to approval actions", "error");
    }
  };

  const approval = async () => {
    setSendLoading(true);
    axiosInstance

      .post(`/application_approval/add`, {
        comment,
        approval_instance_id: defaultValue,
        application_id: params.id,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire(
            "Approval recorded",
            "Your approval has been recorded successfully",
            "success"
          ).then((res) =>
            res.isConfirmed
              ? history.push("/admin-account/zone-applications")
              : null
          );
        } else {
          Swal.fire("", response.message, "info");
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire(
          "Your approval could not be processed",
          error.message,
          "error"
        );
      })
      .finally(() => setSendLoading(false));
  };

  useEffect(() => {
    setApplicationId(params.id);
    fetchRequestDetails();
    fetchAllApprovalInstances();
    getApprovalList();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Zone management information</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              ZMC name
              <span className={classes.blackText}>
                {requestDetails?.company?.name}
              </span>
            </p>
            <p className={classes.greyText}>
              RCI number
              <span className={classes.blackText}>
                {requestDetails?.company?.rc_no
                  ? requestDetails?.company?.name
                  : null}
              </span>
            </p>
            <p className={classes.greyText}>
              ZMC address
              <span className={classes.blackText}>
                {requestDetails?.company?.address}
              </span>
            </p>
            {/* <p className={classes.greyText}>
              FZC
              <span className={classes.blackText}>Semira enterprise</span>
            </p> */}
            <p className={classes.greyText}>
              Email
              <span className={classes.blackText}>
                {requestDetails?.company?.email}
              </span>
            </p>
          </div>
        </div>

        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Application details</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Application type
              <span className={classes.blackText}>
                {requestDetails?.service?.name}
              </span>
            </p>
            <p className={classes.greyText}>
              Remitta code
              <span className={classes.blackText}>
                {requestDetails?.remitta_code}
              </span>
            </p>
            <p className={classes.greyText}>
              Application fee
              <span className={classes.blackText}>${requestDetails?.fee}</span>
            </p>
            <p className={classes.greyText}>
              Application date
              <span className={classes.blackText}>
                {moment(requestDetails?.application_date).format("M/D/YYYY")}
                {/* {moment(row?.application_date).format("M/D/YYYY")} */}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Uploaded documents</div>

          {requestDetails?.application_attachments?.length === 0 ? (
            <h1>No document found</h1>
          ) : (
            <div className={classes.demo}>
              <List dense={dense}>
                {requestDetails?.application_attachments?.map(
                  (document, index) => (
                    <ListItem key={index + document.upload_date}>
                      <div>
                        <img src={CircleCheck} alt="circle-icon" />
                      </div>

                      {!document.document ? null : (
                        <ListItemText
                          primary={document.document.name}
                          // secondary={document.document.description}
                        />
                      )}

                      <a href={document.upload_url} target="_blank" download>
                        <ListItemSecondaryAction>
                          <img src={downoadCloud} alt="download-icon" />
                          {/* <IconButton edge="end" aria-label="download">
                            <CloudDownloadIcon />
                          </IconButton> */}
                        </ListItemSecondaryAction>
                      </a>
                    </ListItem>
                  )
                )}
              </List>
            </div>
          )}
        </div>

        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Approval Trial</div>
          <div className={classes.boxIndent}>
            {requestDetails?.application_approvals?.map((app) => (
              <div className={classes.space}>
                <div className={classes.documentRow}>
                  <div>
                    <img src={CircleCheck} alt="circle-icon" />
                  </div>
                  <div>{app?.approval_role_stage?.approval_stage}</div>
                </div>

                <Chip label={"Approved"} className={classes.chip} />
              </div>
            ))}
          </div>
        </div>

        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Authority Remarks</div>
          <div className={classes.boxIndent}>
            {requestDetails?.application_approvals?.map((app) => (
              <div className={classes.space}>
                <div className={classes.documentRow}>
                  <div>
                    <img src={CircleCheck} alt="circle-icon" />
                  </div>
                  <div>{app.comment}</div>
                </div>

                <Chip label={"Approved"} className={classes.chip} />
              </div>
            ))}
          </div>
        </div>

        <div className={classes.greyBg}>
          {/* <div className={classes.greyBg}>
            <div className={classes.greenBorder}>Approval form</div>
            <div style={{ padding: "5px 20px" }}>
              <form className={classes.formRoot} noValidate autoComplete="off">
                <div>
                  {approvalIntances.length ? (
                    <TextField
                      id="standard-secondary"
                      label="Approval Action"
                      variant="outlined"
                      select
                      value={values.approval_instance_id}
                      onChange={handleChange("approval_instance_id")}
                    >
                      {approvalIntances.map((option) => (
                        <MenuItem
                          key={option.approval_instance_id}
                          value={option.approval_instance_id}
                        >
                          {option.approval_type === "Approve" &&
                          requestDetails?.reached_final_approval
                            ? "Approve"
                            : option.approval_type === "Approve" &&
                              !requestDetails?.reached_final_approval
                            ? "Approve to Proceed"
                            : option.approval_type}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}
                </div>

                <div className={classes.checkbox}>
                  

                  <Checkbox
                    onChange={handleCheck}
                    color="primary"
                    checked={checked}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
                {checked ? (
                  <TextField
                    id="standard-secondary"
                    label="Documents"
                    variant="outlined"
                    select
                    value={values.document_id}
                    onChange={handleChange("document_id")}
                  >
                    {requestDetails?.application_attachments?.map((option) => (
                      <MenuItem
                        key={option.document.id}
                        value={option.document_id}
                      >
                        {option.document.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}
              </form>
            </div>
          </div> */}
          {/* <div className={classes.greyBg}>
            {approvalLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Button
                // autoFocus
                fullWidth
                variant="contained"
                color="primary"
                // onClick={handleSubmit}
                className={classes.button}
                size="large"
                onClick={handleSubmit}
              >
                Send
              </Button>
            )}
          </div> */}

          {getAdmin().data.role_id == 5 ? (
            <>
              <div className={classes.greyBg}>
                <Button
                  fullWidth
                  variant={"contained"}
                  color="primary"
                  disabled={sendLoading}
                  onClick={generateLicense}
                  className={classes.button}
                  size="large"
                >
                  {sendLoading ? (
                    <div className={classes.spinner}>
                      <CircularProgress
                        style={{ height: "30px", width: "30px", margin: 0 }}
                      />
                    </div>
                  ) : hasLicense == "no" ? (
                    "Issue certificate/License"
                  ) : (
                    "Renewal service"
                  )}
                </Button>
              </div>
            </>
          ) : (
            <div className={classes.greyBg}>
              <div className={classes.greenBorder}>Remarks</div>

              <div style={{ padding: "5px 20px" }}>
                <form noValidate autoComplete="off">
                  {checked && (
                    <TextField
                      id="outlined-error-helper-text"
                      select
                      className={classes.select}
                      variant="outlined"
                      label="Select a Document"
                      name="DocumentId"
                      value={document || ""}
                      onChange={(e) => selectDocument(e)}
                      disabled={loading}
                    >
                      {docTypes.map((option) => (
                        <MenuItem key={option.id} value={option.id || ""}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}

                  <TextField
                    rows={4}
                    multiline
                    onChange={handleCommentChange}
                    className={classes.inputField}
                    label="Remarks"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid black",
                      background: "white",
                      padding: "5px 10px",
                    }}
                  >
                    <TextField
                      select
                      onChange={handleSend}
                      variant="filled"
                      className={classes.textField}
                      fullWidth
                      style={{ fontStyle: "10px", background: "white" }}
                      InputProps={{ disableUnderline: true }}
                    >
                      {approvalList.map((list) => (
                        <MenuItem value={list.approval_instance_id}>
                          {list.approval_type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <Button
                    disabled={sendLoading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={approval}
                    className={classes.button}
                    size="large"
                  >
                    {sendLoading ? (
                      <div className={classes.spinner}>
                        <CircularProgress
                          style={{ height: "30px", width: "30px", margin: 0 }}
                        />
                      </div>
                    ) : (
                      "Send"
                    )}
                  </Button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SingleApplication;
