import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Modal,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
// import CallBackCloseButton from "./CallBackCloseButton";
import Success from "./Success";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { AppConfig } from "app-config";

const useStyles = makeStyles({
  submitBtn: {
    marginTop: "2rem",
    background: "#1976D2",
    color: "white",
    "&:hover": { background: "#1976D2", focus: "black" },
    padding: {
      xs: "10px 100px",
      sm: "10px 150px",
    },
    fontFamily: "Avenir Roman",
    borderRadius: "20px",
    textTransform: "capitalize",
  },
  modalTitle: {
    fontSize: {
      xs: "1.125rem",
      md: "1.4rem",
    },
    fontWeight: 400,
    paddingBottom: "1rem",
  },
  btn: {
    marginLeft: "90%",
  },
  profileImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50%",
    cursor: "pointer",
    marginBottom: "3rem",
  },
  label: {
    marginRight: "20rem",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "20px",
};

export default function NestedModal({
  modal,
  userID,
  setModal,
  username,
  userEmail,
  userPhone,
  userAddress,
  entUser,
  setEntUser,
}) {
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const inputRef = useRef(null);
  const [seletectedData, setSeletectedData] = useState(null);
  const classes = useStyles();

  // const userID = id;
  // const username = fullname;
  // const userEmail = email;
  // const userPhone = phone;
  // const userAddress = address;

  const initialValues = {
    id: entUser?.id,
    fullname: entUser?.fullname,
    email: entUser?.email,
    phone: entUser?.company?.phone,
    address: entUser?.company?.address,
    profileImage: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [profileImage, setProfileImage] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [open, setOpen] = React.useState(false);

  const { id, fullname, email, phone, address } = formValues;

  // const id = formValues?.id;
  // const fullname = formValues?.fullname;
  // const email = formValues?.email;
  // const phone = formValues?.company?.phone;
  // const address = formValues?.company?.address;

  console.log("USER EMAIL", userEmail);
  console.log("Initial Values", initialValues);
  console.log("Form Values", formValues);
  console.log("ENT USER", entUser);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle the profile image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormValues((prevFormData) => ({
      ...prevFormData,
      // profileImage: file,
    }));
  };

  //get enterprise detail
  const getEnterprise = async (e) => {
    try {
      const response = await axios.get(`${AppConfig.URL}/users/${id}/find`, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, message, data } = response;

      if (status) {
        setEntUser(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handle user profile update
  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    try {
      setModal(true);
      setOpen(false);
      const profile_image = new FormData();
      profile_image.append("profileImage", profileImage);
      const updatedUser = {
        fullname,
        email,
        phone,
        address,
        // profile_image,
      };
      const response = await axios.post(
        `${AppConfig.URL}/users/update/${id}`,
        updatedUser,
        {
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        }
      );

      const { status, message, data } = response;

      if (status) {
        setFormValues((prev) => ({
          ...prev,
          fullname: response.data?.fullname,
          email: response.data.email,
          phone: response.data.phone,
          address: response.data.address,
        }));
        console.log(response.data, fullname, email);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getEnterprise();
  }, []);

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        className={classes.btn}
      >
        Edit Profile
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: {
              xs: 300,
              sm: 450,
            },
            textAlign: "center",
            fontFamily: "Avenir Roman",
            fontSize: "1.2rem",
          }}
        >
          {/* <Box
            sx={{
              position: "relative",
              float: "right",
              cursor: "pointer",
            }}
          >
            <CallBackCloseButton onClose={handleClose} />
          </Box> */}
          <Typography component="h2" className={classes.modalTitle}>
            Edit your Profile
          </Typography>
          <form onSubmit={handleProfileUpdate}>
            <label htmlFor="file">
              <img
                src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                alt="noimage"
                className={classes.profileImage}
              />
              <input
                type="file"
                ref={inputRef}
                accept="image/*"
                name="profileImage"
              />
            </label>
            <Grid
              container
              spacing={4}
              sx={{ marginBottom: { xs: "0.75rem", sm: "1.5rem" } }}
            >
              <Grid item xs={12}>
                <label className={classes.label}>Name</label>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  placeholder="Full Name"
                  // value={`${userData.data.company.first_name} ${userData.data.company.last_name}`}
                  value={fullname}
                  type="text"
                  name="fullname"
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      marginTop: "1rem",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "-10px" }}>
                <label className={classes.label}>Email</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={handleChange}
                  sx={{
                    "& .MuiGrid-item": {
                      paddingTop: "16px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "-10px" }}>
                <label
                  className={classes.label}
                  style={{ marginRight: "15rem" }}
                >
                  Phone Number
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Phone Number"
                  type="text"
                  value={phone}
                  name="phone"
                  onChange={handleChange}
                  sx={{
                    "& .MuiGrid-item": {
                      paddingTop: "16px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "-10px" }}>
                <label className={classes.label}>Address</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Address"
                  type="text"
                  value={address}
                  name="address"
                  onChange={handleChange}
                  sx={{
                    "& .MuiGrid-item": {
                      paddingTop: "16px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              className={classes.submitBtn}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      {isSuccessful && <Success />}
    </div>
  );
}
