import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputBase,
} from "@material-ui/core";
import TotalRequestsTab from "./TotalRequests";
import PendingRequestsTab from "./PendingRequests";
import DeclinedRequestsTab from "./DeclinedRequests";
import ApprovedRequestsTab from "./ApprovedRequests";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppConfig } from "app-config";

const pageStyles = makeStyles(() => ({
  "& .MuiTabs-indicator": {
    display: "none",
    //backgroundColor: "orange"
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  label: {
    background: "rgba(245, 244, 244, 0.8)",
    textTransform: "capitalize",
    margin: "18px",
    "& .makeStyles-label-3": {
      marginRight: 0,
    },
  },
  tabePanel: {
    "&.MuiTab-textColorInherit.Mui-selected": {
      width: "1%",
    },
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}));

const tabTitles = [
  "Total requests",
  "Pending requests",
  "Declined requests",
  "Aprroved requests",
];

const ZoneManagementInventory = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [tabColor, setTabColor] = useState("black");
  const [tabBackground, setTabBackground] = useState(
    "rgba(245, 244, 244, 0.8)",
  );
  const [active, setActive] = useState(0);
  const [age, setAge] = useState("");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = async (e) => {
    setActive(e);
  };

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  console.log("CHECK!!!", userData);

  //get total application rest
  const totalRequestInventory = async (e) => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/application/inventory`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setActive(e);
        console.log("Total Request Mssg", data);
        setLoading(false);
        setStatus(status);
        setData(data?.data);
        setMessage(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = (date) => {
    let options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let data = new Date(date);
    return data.toLocaleDateString("en-US", options);
  };

  // useEffect(() => {
  //   totalRequestInventory();
  // }, []);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          {tabTitles.map((tabs, index) => (
            <LinkTab
              key={index}
              label={tabs}
              className={classes.label}
              {...a11yProps(0)}
              style={{
                color: active == index ? "white" : "black",
                backgroundColor:
                  active == index ? "#47BB78" : "rgba(245, 244, 244, 0.8)",
                // width: "1%",
              }}
              onClick={() => handleClick(index)}
            />
          ))}
        </Tabs>

        <div>
          <form>
            <select
              id="cat"
              name="cat"
              style={{
                padding: "0.8rem 1.2rem",
                border: "1px solid black",
              }}
            >
              <option value="volvo">Sort by ...</option>
              <option value="name">Name</option>
              <option value="date">Date</option>
            </select>
          </form>
        </div>
      </div>

      {/* {loading && (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )} */}

      <TabPanel value={value} index={0}>
        <TotalRequestsTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PendingRequestsTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DeclinedRequestsTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ApprovedRequestsTab />
      </TabPanel>
    </div>
  );
};

export default ZoneManagementInventory;
