import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  colors,
  TextField,
  Button,
  MenuItem,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import CircleCheck from "images/check-circle.svg";
import downoadCloud from "images/download-cloud.svg";
import avatar from "images/avatar.svg";
import { AppConfig } from "app-config";
import axiosInstance from "Api/Api";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import FolderIcon from "@material-ui/icons/Folder";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: "3rem",
    paddingInline: "2rem",
    background: "white",
    display: "flex",
    gap: "2em",
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  heading: {
    textAlign: "center",
    paddingTop: 32,
  },
  greyText: {
    fontWeight: "500",
    fontSize: "14px",
    color: "#B0AEAE",
    marginTop: "16px",
    fontFamily: "Jakarta",
  },
  blackText: {
    fontWeight: "500",
    fontSize: "14px",
    display: "block",
    color: "black",
    marginTop: "12px",
  },
  greenBorder: {
    borderLeft: "3px solid #47BB78",
    padding: "10px",
    color: "#47BB78",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "18px",
  },
  select: {
    width: "90%",
    maxWidth: 375,
    margin: "1.4rem",
    backgroundColor: "white",
  },
  boxIndent: {
    paddingInline: "31px",
  },
  greyBg: {
    background: "rgba(250, 250, 250, 0.8)",
    marginBottom: "14px",
    maxHeight: "100%",
  },
  halfWidth: {
    width: "50%",
  },
  documentRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "20px",
  },
  boldText: {
    fontSize: "14px",
    display: "block",
    color: "black",
  },
  inputField: {
    marginBottom: "20px",
    width: "100%",
    background: "white",
    border: "1px solid black",
    "& .MuiFilledInput-multiline": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
      marginBottom: "10px",
    },
  },

  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  chip: {
    background: "rgba(71, 187, 120, 0.3)",
    borderRadius: "29px",
    color: " #47BB78",
  },
  textarea: {
    padding: "10px 0px 10px 17px",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
  },
  button: {
    marginTop: "27px",
    textTransform: "capitalize",
  },
  textField: {
    "& .MuiSelect-filled.MuiSelect-filled": {
      background: "white",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      marginBottom: "10px",
      backgroundColor: "white",
    },
  },

  demo: {
    // maxHeight: "100%",
    overflowY: "auto",
    position: "relative",
    maxHeight: "500px",
  },

  formRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));

const SingleApplication = () => {
  const classes = useStyles();

  const [values, setValues] = useState({
    approval_instance_id: "",
    comment: "",
    document_id: "",
  });
  const [dense, setDense] = React.useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);
  const [document, setDocument] = useState("");
  const [comment, setComment] = useState("");
  const [defaultValue, setDefaultValue] = useState("MD");
  const [applicationId, setApplicationId] = useState(null);
  const [requestDetails, setRequestDetails] = useState({});
  const [documentId, setDocumentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [action, setAction] = useState("");
  const [applicationHistory, setApplicationHistory] = useState([]);
  const [approvalFile, setApprovalFile] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [approvalIntances, setApprovalIntances] = useState([]);
  const [open, setOpen] = useState(false);
  const [erorrMessage, setErrorMessage] = useState("");

  const params = useParams();
  const history = useHistory();

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const selectDocument = (e) => {
    setDocument(e.target.value);
  };

  const handleSend = (e) => {
    setDefaultValue(e.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApprovalLoading(true);
    const { approval_instance_id, comment } = values;
    const formData = new FormData();
    formData.append("approval_instance_id", approval_instance_id);
    formData.append("comment", comment);
    formData.append("replace_document", checked);
    if (checked) {
      formData.append("document_id", values.document_id);
    }
    formData.append("application_id", applicationId);
    if (approvalFile) {
      formData.append("file_title", fileTitle);
      formData.append("file", approvalFile);
    }

    axiosInstance
      .post("/application_approval/add", formData)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
          Swal.fire("Approval", "Approval has been successfully recorded").then(
            (result) => {
              return result.isConfirmed
                ? history.push(`/admin-account/zone-admin-ent`)
                : null;
            },
          );
        } else {
          Swal.fire(
            "Approved",
            "The request has successfully been approved",
          ).then((result) => {
            return result.isConfirmed
              ? history.push(`/admin-account/zone-admin-ent`)
              : null;
          });
          // if (response?.data?.data.comment === "declined") {
          //   Swal.fire(
          //     "Declined",
          //     "The request has successfully been declined",
          //   ).then((result) => {
          //     return result.isConfirmed
          //       ? history.push(`/admin-account/zone-admin-ent`)
          //       : null;
          //   });
          // } else {
          //   Swal.fire(
          //     "Approved",
          //     "The request has successfully been approved",
          //   ).then((result) => {
          //     return result.isConfirmed
          //       ? history.push(`/admin-account/zone-admin-ent`)
          //       : null;
          //   });
          // }

          // if (requestDetails?.approval_stage.approval_stage === "approved") {
          //   Swal.fire(
          //     "Approved",
          //     "The request has successfully been approved",
          //   ).then((result) => {
          //     return result.isConfirmed
          //       ? history.push(`/admin-account/zone-admin-ent`)
          //       : null;
          //   });
          // } else {
          //   Swal.fire("", response.message, "info");
          // }
          // if (requestDetails?.approval_stage.approval_stage === "Declined") {
          //   Swal.fire(
          //     "Declined",
          //     "The request has successfully been declined",
          //   ).then((result) => {
          //     return result.isConfirmed
          //       ? history.push(`/admin-account/zone-admin-ent`)
          //       : null;
          //   });
          // }
        }
      })
      .catch(function (error) {
        const { message } = error.response.data;
        setErrorMessage(message);
        setOpen(true);
      })
      .finally(() => setApprovalLoading(false));
  };

  const fetchAllApprovalInstances = () => {
    setLoading(true);

    axiosInstance
      .get("/approval_instances/list")
      .then(function (response) {
        if (response.status === 200) {
          setApprovalIntances(response.data.data);
          // console.log(approvalIntances);
          // console.log(props.match.params);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  //fetch request details
  const fetchRequestDetails = () => {
    setLoading(true);
    axiosInstance
      .get(`/application/get/${params.rowId}`)
      .then(function (response) {
        if (response.status === 200) {
          console.log("Approval Resp", response);
          setApplicationHistory(response.data.data.history);
          setRequestDetails(response.data.data.application);
          console.log("Request Details", response.data.data.application);
          setDocumentId(response.data.data.application_attachments);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  //handle close
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setApplicationId(params.rowId);
    fetchRequestDetails();
    fetchAllApprovalInstances();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Zone management information</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Enterprise name
              <span className={classes.blackText}>
                {requestDetails?.company?.name}
              </span>
            </p>
            {/* <p className={classes.greyText}>
              RCI number
              <span className={classes.blackText}>
                {requestDetails?.company?.rc_no
                  ? requestDetails?.company?.name
                  : null}
              </span>
            </p> */}
            <p className={classes.greyText}>
              Enterprise address
              <span className={classes.blackText}>
                {requestDetails?.company?.address}
              </span>
            </p>
            {/* <p className={classes.greyText}>
               FZC
              <span className={classes.blackText}>Semira enterprise</span>
            </p> */}
            <p className={classes.greyText}>
              Email
              <span className={classes.blackText}>
                {requestDetails?.company?.email}
              </span>
            </p>
          </div>
        </div>

        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Application details</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              Application type
              <span className={classes.blackText}>
                {requestDetails?.service?.name}
              </span>
            </p>
            <p className={classes.greyText}>
              Remitta code
              <span className={classes.blackText}>
                {requestDetails?.remitta_code}
              </span>
            </p>
            <p className={classes.greyText}>
              Application fee
              <span className={classes.blackText}>
                ${requestDetails?.processing_fee}
              </span>
            </p>
            <p className={classes.greyText}>
              Application date
              <span className={classes.blackText}>
                {moment(requestDetails?.application_date).format("M/D/YYYY")}
                {/* {moment(row?.application_date).format("M/D/YYYY")} */}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Uploaded documents</div>

          {requestDetails?.application_attachments?.length === 0 ? (
            <h1>No document found</h1>
          ) : (
            <div className={classes.demo}>
              <List dense={dense}>
                {requestDetails?.application_attachments?.map(
                  (document, index) => (
                    <ListItem key={index + document.upload_date}>
                      <div>
                        <img src={CircleCheck} alt="circle-icon" />
                      </div>

                      {!document.document ? null : (
                        <ListItemText
                          primary={document.document.name}
                          // secondary={document.document.description}
                        />
                      )}

                      <a href={document.upload_url} target="_blank" download>
                        <ListItemSecondaryAction>
                          <img src={downoadCloud} alt="download-icon" />
                          {/* <IconButton edge="end" aria-label="download">
                            <CloudDownloadIcon />
                          </IconButton> */}
                        </ListItemSecondaryAction>
                      </a>
                    </ListItem>
                  ),
                )}
              </List>
            </div>
          )}
        </div>

        {requestDetails?.application_approvals?.length > 0 ? (
          <div className={classes.greyBg}>
            <div className={classes.greenBorder}>Approval Trial</div>
            <div className={classes.boxIndent}>
              {requestDetails
                ? requestDetails.application_approvals?.map((app) => (
                    <div className={classes.space}>
                      <div className={classes.documentRow}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={CircleCheck} alt="circle-icon" />

                          <span>
                            {
                              app.approval_instance.approval_stage
                                .approval_stage
                            }
                          </span>
                        </div>
                      </div>
                      <Chip label={"Approved"} className={classes.chip} />
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : null}

        {/* <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Authority Remarks</div>
          <div className={classes.boxIndent}>
            <div className={classes.space}>
              <div className={classes.documentRow}>
                <img src={CircleCheck} alt="circle-icon" />

                <div className={classes.boldText} style={{ marginTop: "10px" }}>
                  <span>Zone management (ZMC)</span>
                  <br />
                  <span style={{ color: "#B0AEAE" }}>
                    I strongly advise semira enterprise is approved
                  </span>
                </div>
              </div>
              <Chip label={"Approved"} className={classes.chip} />
            </div>
          </div>
        </div> */}

        <div className={classes.greyBg}>
          <div className={classes.greyBg}>
            <div className={classes.greenBorder}>Approval form</div>
            <div style={{ padding: "5px 20px" }}>
              <form className={classes.formRoot} noValidate autoComplete="off">
                <div>
                  {approvalIntances.length ? (
                    <TextField
                      id="standard-secondary"
                      label="Approval Action"
                      variant="outlined"
                      select
                      value={values.approval_instance_id}
                      onChange={handleChange("approval_instance_id")}
                    >
                      {approvalIntances.map((option) => (
                        <MenuItem
                          key={option.approval_instance_id}
                          value={option.approval_instance_id}
                        >
                          {option.approval_type === "Approve" &&
                          requestDetails?.reached_final_approval
                            ? "Approve"
                            : option.approval_type === "Approve" &&
                              !requestDetails?.reached_final_approval
                            ? "Approve to Proceed"
                            : option.approval_type}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : null}
                </div>

                <div className={classes.checkbox}>
                  {/* <div>
                    <p>Document re-upload required?</p>
                  </div>

                  <Checkbox
                    onChange={handleCheck}
                    color="primary"
                    checked={checked}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  /> */}
                </div>
                {checked ? (
                  <TextField
                    id="standard-secondary"
                    label="Documents"
                    variant="outlined"
                    select
                    value={values.document_id}
                    onChange={handleChange("document_id")}
                  >
                    {requestDetails?.application_attachments?.map((option) => (
                      <MenuItem
                        key={option.document.id}
                        value={option.document_id}
                      >
                        {option.document.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}

                <div>
                  <TextField
                    id="outlined-secondary"
                    label="Remarks"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={values.comment}
                    onChange={handleChange("comment")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid black",
                    background: "white",
                    padding: "5px 20px",
                    marginLeft: "10px",
                    width: "100%",
                  }}
                >
                  <p>Send to:</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={avatar} alt="avatar" />
                    <TextField
                      select
                      onChange={handleSend}
                      variant="filled"
                      defaultValue="md"
                      className={classes.textField}
                      style={{ fontStyle: "10px" }}
                      InputProps={{ disableUnderline: true }}
                    >
                      <MenuItem value="md">MD</MenuItem>
                      <MenuItem value="finance">Finance</MenuItem>
                      <MenuItem value="legal">Legal</MenuItem>
                    </TextField>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.greyBg}>
            {approvalLoading ? (
              // <Button>Loading...</Button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Button
                // autoFocus
                fullWidth
                variant="contained"
                color="primary"
                // onClick={handleSubmit}
                className={classes.button}
                size="large"
                onClick={handleSubmit}
              >
                Send
              </Button>
            )}
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {erorrMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SingleApplication;
