import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Badge,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Logout from "../../images/logout.svg";
import ZoneAdminDataTable from "pages/Account/ZoneAdminRequetsTabs/ZoneAdminDataTable";
import ZoneAdminApplicationDetail from "./ZoneAdminRequetsTabs/ZoneAdminApplicationDetail";
import ZoneAdminRequestAction from "pages/Account/ZoneAdminRequetsTabs/ZoneAdminRequestAction";
import DataTable from "pages/Account/DataTable";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { Switch, Route, Link } from "react-router-dom";
import SignupPage from "pages/Signup.js";
import Homepage from "pages/Homepage";
import AdminRequestAction from "pages/Account/AdminRequestAction";
import AdminRequestActionHistory from "pages/Account/AdminRequestActionHistory";
import ActionSubmitted from "pages/Account/ActionSubmitted";
import { useHistory, NavLink } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import logo from "images/Nepzaimage.png";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ViewListIcon from "@material-ui/icons/ViewList";
import SettingsMenu from "./SettingsMenu";
import Zones from "pages/Account/Zones";
import Services from "pages/Account/Services";
import Document from "pages/Account/Document";
import EditCreateDocument from "pages/Account/EditCreateDocument";
import ExchangeRate from "pages/Account/ExchangeRate";
import AdminHistory from "pages/Account/History/AdminHistory";
import CreateUser from "pages/Account/UserManagement/CreateUser";
import EditCreateZones from "pages/Account/EditCreate";
import EditCreateServices from "pages/Account/EditCreateService";
import NepzaAdminDashboard from "pages/Account/NepzaAdminDashboard";
import AdminUsers from "pages/Account/UserManagement/AdminUsers";
import EditAdminUser from "pages/Account/UserManagement/EditAdminUser";
import ZoneAdminEnterprises from "./ZoneAdminEnterprises";
import ZoneAdminApplication from "./ZoneAdminApplication";
import Revenue from "./Revenue";
import ZoneAdminDashboard from "./ZoneAdminDashboard";
import PublicDashboard from "../FZMC/Public/Dashboard";
import FreeZoneRequestsDashboard from "pages/FZMC/Public/FreeZoneRequestsDashboard";
import ZoneManagementApplication from "../FZMC/Public/ZoneManagementApplication";
import ZoneApplicationDetail from "pages/FZMC/Public/ZoneApplicationDetail";
import ZMCTable from "pages/FZMC/Public/ZMCTable";
import GeneratedRevenue from "pages/FZMC/Public/GeneratedRevenus";
import ZoneManagementInventory from "pages/FZMC/Public/ZoneManagementInventory";
import FreeZoneApplication from "pages/FZMC/Public/FreeZoneRequets/Applications/FreeZoneApplication";
import FreeZoneApplicationDetail from "pages/FZMC/Public/FreeZoneRequets/Applications/FreeZoneApplicationDetail";
import FreeZoneInventory from "pages/FZMC/Public/FreeZoneRequets/RequestsInventory/FreeZoneInventory";
import PublicRevenues from "pages/FZMC/Public/Revenue/PublicRevenues";
import PrivateAdminDashboard from "../FZMC/Private/Dashboard";
import ZoneManagementPrivateDashboard from "../FZMC/Private/ZoneManagement/Dashboard";
import ZoneManagamentPrivateApplication from "../FZMC/Private/ZoneManagement/Application";
import ZoneManagementPrivateInventory from "../FZMC/Private/ZoneManagement/RequestInventory";
import SingleZoneApplicationPrivate from "../FZMC/Private/ZoneManagement/SingleApplication";
import FreeZoneRequestsPrivateDashboard from "../FZMC/Private/FreeZoneRequests/Dashboard";
import FreeZoneRequestsPrivateApplication from "../FZMC/Private/FreeZoneRequests/Application";
import SingleFreeZoneApplicationPrivate from "../FZMC/Private/FreeZoneRequests/SingleApplication";
import SingleZoneAdminEnterprise from "./ZoneAdminRequetsTabs/SingleZoneAdminEnterprise";
import ViewRoles from "pages/Account/RoleManagement/ViewRoles";
import CreateRole from "pages/Account/RoleManagement/CreateRole";
import EditRole from "pages/Account/RoleManagement/EditRole";
import AllCategories from "pages/Account/Categories/AllCategories";
import CategoryServices from "pages/Account/Categories/CategoryServices";
import ProcessModules from "pages/Account/ProcessModules/ViewProcessModules";
import CreateProcessModule from "pages/Account/ProcessModules/CreateProcessModule";
import EditProcessModule from "pages/Account/ProcessModules/EditProcessModule";
import ZmcApplications from "pages/ZMCApplications/ZMCApplications";
import ZMCApplicationDetails from "pages/ZMCApplications/ZMCApplicationDetails";
import PromoterDetails from "pages/PromoterDetails";

import axios from "axios";
import axiosInstance from "../../Api/Api.js";
import ProcessFlow from "./ProcessFlow";
import EditCreateProcessFlow from "./UpdateProcessFlow";
import FinalApproval from "../../Approvals/FinalApproval";
import AdminViewDetails from "../CompanyRequest/AdminViewDetails";
import AdminZones from "pages/Account/AdminZones";
import AdminPromoters from "pages/Account/AdminPromoters";
import AdminEnterprises from "pages/Account/AdminEnterprises";
import AddParticulars from "pages/Admin/AddParticulars";
import ZmcDashboard from "pages/Admin/ZmcDashboard";
import EnterpriseRequest from "./Tables/EnterpriseRequest";
import FreeZoneRequest from "./Tables/FreeZoneRequest";
import ZoneRequest from "./Tables/ZoneRequest";
import Applications from "pages/Account/Applications";
import EnterpriseApplications from "./Applications/EnterpriseApplications";
import EnterpriseAppInfo from "./Applications/EnterpriseAppInfo";
import FreeZoneApplications from "./Applications/FreeZoneApplications";
import ZoneApplications from "./Applications/ZoneApplications";
import EnterpriseDashboard from "./MDDashboard/EnterpriseDashboard";
import ZoneDashboard from "./MDDashboard/ZoneDashboard";
import ZoneManagementDashboard from "./MDDashboard/ZoneManagementDashboard";
import Revenues from "./Tables/Revenues";
import FinanceAdmin from "./FinanceAdmin";
import FinanceFZR from "./Tables/FinanceFZR";
import FinanceER from "./Tables/FinanceER";
import FinanceRevenue from "./Tables/FinanceRevenue";
import Exchange from "./Exchange";
import ZmcInventory from "./Inventory/ZmcInventory";
import FreeZonesInventory from "./Inventory/FreeZonesInventory";
import EnterpriseInventory from "./Inventory/EnterpriseInventory";
import Mdinfo from "./Tables/Mdinfo";
import RequestAction from "pages/Account/ZoneAdminRequetsTabs/RequestAction";
import CheckList from "pages/checklist/CheckList";
import RequestInventoryDetails from "pages/FZMC/Public/FreeZoneRequets/RequestsInventory/RequestInventoryDetails";
import ZoneManagementApplicationDetails from "pages/FZMC/Public/ZoneManagementRequestsTabs/ZoneManagementApplicationDetails";
import ZoneManagementInventoryDetails from "pages/FZMC/Public/ZoneManagementRequestsTabs/ZoneManagementInventoryDetails";
import UserProfile from "pages/UserProfile";
import FinanceApplicationDetails from "./Tables/FinanceApplicationDetails";
import FinanceApplicationTable from "./Tables/FinanceApplicationTable";
import ZMCApplicationTable from "./Tables/ZMCApplicationTable";
import ZmcApplicationDetails from "./Tables/ZmcApplicationDetails";
import EnterpriseApplicationDetails from "./Tables/EnterpriseApplicationDetails";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    padding: "1em 0",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  `,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: theme.palette.action.hover
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  menuLink: {
    position: "relative",
    display: "block",
    // padding:' 0 3em'
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1) * 1.5,
    paddingBottom: theme.spacing(1) * 1.5,
  },
  menuIcon: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
  },
  menuActive: {
    backgroundColor: "#47BB78",
    color: "white",
  },
  logo: {
    maxWidth: "80%",
  },
  icons: {
    color: "red !important",
  },
  input: {
    color: "#000",
    padding: "1em",
    border: "1px solid rgba(176, 174, 174, 1)",
    borderRadius: "2em",
    fontSize: "14px",
    width: "480px",
  },
  justifyDrawer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
}));
function ResponsiveDrawer(props) {
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [state, setState] = useState("");
  const [allRequests, setAllRequests] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const drawer = (
    <div className={classes.justifyDrawer}>
      {/* <div className={classes.toolbar} /> */}
      <div>
        <div
          style={{
            marginBottom: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.5rem",
          }}
        >
          <img src={logo} className={classes.logo} alt="logo" />
        </div>

        {/* <Divider /> */}
        <List className={classes.listMenu}>
          <SettingsMenu />
        </List>
        <Divider />
      </div>

      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon color="#E72E2E">
            <img src={Logout} alt="logout" />
          </ListItemIcon>
          <ListItemText primary={"Logout"} style={{ color: "red" }} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {/* {getUser()?.data?.company?.name} */}
            <input
              placeholder="Search something here"
              className={classes.input}
            />
          </Typography>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            // onClick={handleMenu}
            style={{ color: "gray", marginRight: "2px" }}
          >
            <NavLink
              to="/admin-account/profile"
              exact
              // className={classes.menuLink}
              // activeClassName={classes.menuActive}
            >
              <AccountCircle />
            </NavLink>
          </IconButton>
          <Typography
            variant="h6"
            style={{ color: "black", fontSize: "18px", marginRight: "30px" }}
          >
            {getAdmin()?.data?.fullname}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Switch>
          <Route exact path="/admin-account">
            <DataTable />
          </Route>
          <Route path="/admin-account/history-request-details/:rowId">
            <AdminViewDetails />
          </Route>
          <Route path="/admin-account/process-flow/:rowId">
            <EditCreateProcessFlow />
          </Route>
          <Route path="/admin-account/request-action/:rowId">
            <AdminRequestAction />
          </Route>

          <Route path="/admin-account/action-request/:rowId">
            <ZoneAdminRequestAction />
            {/* <RequestAction /> */}
          </Route>

          <Route path="/admin-account/action-submitted">
            <ActionSubmitted />
          </Route>
          <Route path="/admin-account/zmc-applications">
            <ZmcApplications />
          </Route>

          {/*Check list path */}
          <Route path="/admin-accont/checklist">
            <CheckList />
          </Route>
          <Route path="/admin-account/admin-zones">
            <AdminZones />
          </Route>
          <Route path="/admin-account/admin-promoters">
            <AdminPromoters />
          </Route>
          <Route path="/admin-account/admin-enterprises">
            <AdminEnterprises />
          </Route>
          <Route path="/admin-account/admin-freezone-dashboard">
            <FreeZoneRequestsDashboard />
          </Route>

          <Route exact path="/admin-account/free-zone-applications">
            <FreeZoneApplication />
          </Route>

          <Route exact path="/admin-account/free-zone-applications/:rowId">
            <FreeZoneApplicationDetail />
          </Route>

          <Route path="/admin-account/admin-zone-dashboard">
            <ZoneManagementDashboard />
          </Route>

          <Route path="/admin-account/zone-admin-enterprises">
            <ZoneAdminEnterprises />
            {/* <DataTable /> */}
          </Route>

          <Route exact path="/admin-account/zone-admin-ent">
            <ZoneAdminDataTable />
          </Route>

          <Route path="/admin-account/zone-admin-enterprises/:entId">
            <SingleZoneAdminEnterprise />
          </Route>
          <Route exact path="/admin-account/zone-request-inventory">
            <ZoneManagementInventory />
            {/* <NepzaAdminDashboard /> */}
          </Route>

          <Route exact path="/admin-account/zone-request-inventory/:rowId">
            <ZoneManagementInventoryDetails />
            {/* <NepzaAdminDashboard /> */}
          </Route>

          <Route path="/admin-account/zmc-request-detail/:rowId">
            <ZMCApplicationDetails />
          </Route>
          <Route path="/admin-account/promoter-detail/:rowId">
            <PromoterDetails />
          </Route>
          <Route path="/admin-account/enterprise-request">
            <EnterpriseRequest />
          </Route>
          <Route path="/admin-account/free-zone-request">
            <FreeZoneRequest />
          </Route>
          <Route path="/admin-account/zone-request">
            <ZoneRequest />
          </Route>
          <Route path="/admin-account/final-approval">
            <FinalApproval />
          </Route>
          <Route path="/admin-account/nepza-admin">
            {/* <NepzaAdminDashboard /> */}
            <ZmcDashboard />
          </Route>

          <Route exact path="/admin-account/zone-admin-applications">
            <ZoneAdminApplication />
          </Route>
          <Route exact path="/admin-account/profile">
            <UserProfile />
          </Route>

          <Route exact path="/admin-account/zone-admin-applications/:rowId">
            <ZoneAdminApplicationDetail />
          </Route>

          <Route
            exact
            path="/admin-account/zone-management-requests-applications"
          >
            <ZoneManagementApplication />
          </Route>

          <Route
            exact
            path="/admin-account/zone-management-requests-applications/:rowId"
          >
            <ZoneManagementApplicationDetails />
          </Route>

          <Route path="/admin-account/zonemanagement-application-private/:rowId">
            <SingleZoneApplicationPrivate />
          </Route>

          <Route path="/admin-account/freezone-application-private/:id">
            <SingleFreeZoneApplicationPrivate />
          </Route>

          <Route exact path="/admin-account/zone-admin-dashboard">
            <ZoneAdminDashboard />
          </Route>

          <Route path="/admin-account/zone-admin-revenue">
            <Revenue />
          </Route>

          <Route path="/admin-account/fzm-public">
            <PublicDashboard />
          </Route>

          <Route exact path="/admin-account/fzm-private">
            <PrivateAdminDashboard />
          </Route>

          <Route exact path="/admin-account/free-zone-request-inventory">
            <FreeZoneInventory />
          </Route>

          <Route exact path="/admin-account/free-zone-request-inventory/:rowId">
            <RequestInventoryDetails />
          </Route>

          <Route path="/admin-account/free-zone-revenues">
            <PublicRevenues />
          </Route>

          <Route path="/admin-account/zonemanagement-dashboard-private">
            <ZoneManagementPrivateDashboard />
          </Route>

          <Route path="/admin-account/freezone-dashboard-private">
            <FreeZoneRequestsPrivateDashboard />
          </Route>

          <Route path="/admin-account/freezone-application-private">
            <FreeZoneRequestsPrivateApplication />
          </Route>

          <Route path="/admin-account/zone-manager-table">
            <ZMCTable />
          </Route>

          <Route path="/admin-account/generated-revenue">
            <GeneratedRevenue />
          </Route>

          <Route exact path="/admin-account/zonemanagement-application-private">
            <ZoneManagamentPrivateApplication />
          </Route>

          <Route path="/admin-account/zone-management-requests-applications/:id">
            <ZoneApplicationDetail />
          </Route>

          <Route path="/admin-account/zonemanagement-request-private">
            <ZoneManagementPrivateInventory />
          </Route>

          <Route path="/admin-account/zones/edit-create/:rowId">
            <EditCreateZones />
          </Route>
          <Route path="/admin-account/services/edit-create/:rowId">
            <EditCreateServices />
          </Route>
          <Route path="/admin-account/services">
            <Services />
          </Route>
          <Route path="/admin-account/document/edit-create/:rowId">
            <EditCreateDocument />
          </Route>
          <Route path="/admin-account/document">
            <Document />
          </Route>
          <Route path="/admin-account/exchange-rate">
            <ExchangeRate />
          </Route>
          <Route path="/admin-account/view-users">
            <AdminUsers />
          </Route>
          <Route path="/admin-account/view-categories">
            <AllCategories />
          </Route>
          <Route path="/admin-account/category-services">
            <CategoryServices />
          </Route>
          <Route path="/admin-account/history">
            <AdminHistory />
          </Route>
          <Route path="/admin-account/zones">
            <Zones />
          </Route>
          <Route path="/admin-account/create-user">
            <CreateUser />
          </Route>
          <Route path="/admin-account/view-roles">
            <ViewRoles />
          </Route>
          <Route path="/admin-account/create-role">
            <CreateRole />
          </Route>
          <Route path="/admin-account/view-process-modules">
            <ProcessModules />
          </Route>
          <Route path="/admin-account/create-process-module">
            <CreateProcessModule />
          </Route>
          <Route path="/admin-account/edit-process-module/:rowId">
            <EditProcessModule />
          </Route>
          <Route path="/admin-account/edit-admin/:rowId">
            <EditAdminUser />
          </Route>
          <Route path="/admin-account/edit-role/:rowId">
            <EditRole />
          </Route>
          <Route path="/admin-account/process-flow">
            <ProcessFlow />
          </Route>
          <Route exact path="/admin-account/enterprise-applications">
            <EnterpriseApplications />
          </Route>
          <Route exact path="/admin-account/enterprise-applications/:id">
            <EnterpriseApplicationDetails />
          </Route>
          <Route path="/admin-account/free-zone-applications-new">
            <FreeZoneApplications />
          </Route>
          <Route exact path="/admin-account/zone-applications">
            <ZMCApplicationTable />
          </Route>
          <Route exact path="/admin-account/zone-applications/:id">
            <ZmcApplicationDetails />
          </Route>
          <Route path="/admin-account/enterprise-dashboard">
            <EnterpriseDashboard />
          </Route>
          <Route path="/admin-account/free-zone-dashboard">
            <ZoneManagementDashboard />
          </Route>
          <Route path="/admin-account/revenues">
            <Revenues />
          </Route>
          <Route path="/admin-account/zone-dashboard">
            <ZoneDashboard />
          </Route>
          <Route path="/admin-account/finance-md">
            <FinanceAdmin />
          </Route>
          <Route path="/admin-account/finance-fzr">
            <FinanceFZR />
          </Route>
          <Route exact path="/admin-account/finance-applications">
            <FinanceApplicationTable />
          </Route>
          <Route exact path="/admin-account/finance-applications/:id">
            <FinanceApplicationDetails />
          </Route>
          <Route path="/admin-account/finance-er">
            <FinanceER />
          </Route>
          <Route path="/admin-account/finance-revenue">
            <FinanceRevenue />
          </Route>
          <Route path="/admin-account/rate-calculate">
            <Exchange />
          </Route>
          <Route path="/admin-account/zminventory">
            <ZmcInventory />
          </Route>
          <Route path="/admin-account/fzinventory">
            <FreeZonesInventory />
          </Route>
          <Route path="/admin-account/enterpriseinventory">
            <EnterpriseInventory />
          </Route>
          <Route path="/admin-account/company-info">
            <Mdinfo />
          </Route>
          {/* <Route path="/admin-account/rate-calculate">
            <Exchange />
          </Route> */}
          <Route path="/admin-account">
            <DataTable />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
