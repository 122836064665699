import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  colors,
  capitalize,
  Menu,
  MenuItem,
  Box,
  Chip,
  Grid,
  TextField,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppConfig } from "app-config";
import Swal from "sweetalert2";
import { green, grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import axiosInstance from "Api/Api";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    color: "#B0AEAE",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: grey[50],
  //   },
  // },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
  },
  table: {
    minWidth: 700,
    paddingInline: "65px",
    paddingTop: "33px",
    paddingBottom: "80px",
    backgroundColor: "white",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  delBtn: {
    color: "red",
  },
  norec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    flexDirection: "column",
  },
  action: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  payBtn: {
    color: colors.green[600],
  },
  head: {
    // backgroundColor: grey[200],
  },
  bold: {
    fontWeight: "bold",
  },
  headText: {
    fontWeight: "600",
    fontSize: "24px",
  },
  bigText: {
    fontWeight: "500",
    fontSize: "24px",
  },
  smallText: {
    fontWeight: "500",
  },
  chip: {
    width: "90px",
    height: "35px",
    display: "flex",
    background: "rgba(146, 179, 230, 0.17)",
    borderRadius: "62px",
    paddingInline: "12px",
    paddingBlock: "5px",
  },
  paginated: {
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: "60px",
    paddingBlock: "18px",
  },
  field: {
    display: "block",
    maxWidth: "500px",
  },
  chipText: {
    fontWeight: 500,
    fontSize: "17.7021px",
    lineHeight: "22px",
  },
}));

const Exchange = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    editIcon: true,
    rate: "",
    standardRate: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [dollarValue, setDollar] = useState(0);
  const [nairaValue, setNaira] = useState(0);
  const [rateMessage, setRateMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [disableInput, setDisableInput] = useState(true);

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setValues({ ...values, [prop]: event.target.value });
  };

  const fetchCurrentRate = () => {
    setLoading(true);
    axiosInstance
      .get(`/exchange_rate/get`)
      .then(function (response) {
        setLoading(false);
        if (response.status === 200) {
          console.log(response.data.data);

          setValues({
            ...values,
            rate: response.data.data.rate,
            standardRate: response.data.data.standard_rate,
            editIcon: true,
          });
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error?.response?.status === 404) {
          setRateMessage(error?.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };
  const handleClickEditIcon = () => {
    setValues({ ...values, editIcon: !values.editIcon });
    setDisableInput(!disableInput);
  };

  useEffect(() => {
    fetchCurrentRate();
  }, []);
  return (
    <Box className={classes.table}>
      <Box style={{ marginBottom: "3em" }}>
        <Typography gutterBottom variant="h4" className={classes.headText}>
          Rate Calculator
        </Typography>
      </Box>
      <Grid container>
        <Grid xs={12} md={2} item>
          <Typography gutterBottom variant="p" className={classes.smallText}>
            1.00 USD equals
          </Typography>
          <Typography gutterBottom variant="h5" className={classes.bigText}>
            {values.standardRate || 0} NGN
          </Typography>
        </Grid>
        <Grid xs={12} md={8} item>
          <Box>
            <Typography gutterBottom variant="p" className={classes.smallText}>
              Currency from
            </Typography>
            <TextField
              variant="outlined"
              value={dollarValue}
              onChange={(e) => {
                setDollar(e.target.value);
                setNaira((e.target.value * values.standardRate).toFixed(2));
              }}
              fullWidth
              className={classes.field}
              InputProps={{
                endAdornment: (
                  <Box className={classes.chip}>
                    <img src="/usa.png" />
                    <Typography
                      gutterBottom
                      variant="p"
                      component={"p"}
                      className={classes.chipText}
                    >
                      USA
                    </Typography>
                  </Box>
                ),
              }}
            />
          </Box>
          <Box style={{ marginTop: "45px" }}>
            <Typography gutterBottom variant="p" className={classes.smallText}>
              Currency To
            </Typography>
            <TextField
              variant="outlined"
              className={classes.field}
              value={nairaValue}
              onChange={(e) => {
                setNaira(e.target.value);
                setDollar((e.target.value / values.standardRate).toFixed(2));
              }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <Box className={classes.chip}>
                    <img src="/nigeria.png" />
                    <Typography
                      gutterBottom
                      variant="p"
                      component={"p"}
                      className={classes.chipText}
                    >
                      NGN
                    </Typography>
                  </Box>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Exchange;
