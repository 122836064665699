import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Pagination } from "@material-ui/lab";
import ArrowDownIcon from "../../../images/arrowdown.svg";
import { AppConfig } from "app-config";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const columns = [
  { id: "name", label: "Revenue Type", minWidth: 170 },
  {
    id: "date",
    label: "Phone Number",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "status",
    label: "Amount paid",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "action",
    label: "Actions",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, application, date, status, action) {
  return { name, application, date, status, action };
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  ent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    maxHeight: 440,
    marginTop: "2rem",
  },
  paper: {
    // padding: theme.spacing(2),
    color: "black",
    // minHeight: "150px",
    width: "100%",
    padding: "1rem 2rem",
  },
  editButton: {
    background: "#47BB78",
    border: "none",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      background: "#47BB78",
      border: "none",
      color: "white",
    },
  },
  link: {
    background: "#47BB78",
    color: "white",
    padding: "5px 1px",
    borderRadius: "5px",
  },
  paginated: {
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: "60px",
    paddingBlock: "18px",
  },
  select: {
    padding: "0.8rem 2.5rem",
    border: "1px solid #B0AEAE",
    borderRadius: "5px",
    color: "#B0AEAE",
    appearance: "none",
    backgroundImage: `url(${ArrowDownIcon})`,
    backgroundSize: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 8px) center",
    outline: "none",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
});

const formatDate = (date) => {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  if (date) {
    return new Date(date).toLocaleDateString(undefined, options);
  }
  return null;
};

export default function ApplicationTable() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [step, setStep] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState([]);
  const [paginated, setPaginated] = useState(false);

  const itemLink = localStorage.getItem("url");

  console.log("Item Link", itemLink);

  const user = localStorage.getItem("userDetails");

  const userData = JSON.parse(user);

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPaginated(true);
  };

  const Previous = (e) => {
    e.preventDefault();
    prevState();
  };

  const Next = (e) => {
    e.preventDefault();
    nextStep();
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevState = () => {
    setStep(step - 1);
  };

  const fetchApplication = async () => {
    setLoading(true);
    let url = `${AppConfig.URL}/${itemLink}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const { status, message, data } = await response.json();
      console.log("ITEMLINK", data);
      if (status) {
        setLoading(false);
        setApplication(data?.revenue?.data);
        // setLastPage(data.last_page);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //format as money code
  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  //show application detail
  const showRequestDetails = (rowId) => {
    console.log(rowId);
    history.push(
      `/admin-account/zone-management-requests-applications/${rowId}`,
    );
  };

  useEffect(() => {
    fetchApplication();
  }, []);

  return (
    <>
      <Paper className={classes.paper} style={{ marginTop: "4rem" }}>
        <div className={classes.ent}>
          <div>
            <Typography style={{ fontSize: "1.2rem" }}>
              Generated Revenue
            </Typography>
          </div>
          <div>
            <form>
              <select
                id="cat"
                name="cat"
                style={{
                  padding: "0.8rem 1.2rem",
                  border: "1px solid black",
                }}
                className={classes.select}
              >
                <option value="volvo">Sort by ...</option>
                <option value="name">Name</option>
                <option value="date">Date</option>
              </select>
            </form>
          </div>
        </div>
        {loading ? (
          <Box className={classes.spinner}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: "#B0AEAE" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {application?.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell align="left">{row?.type}</TableCell>

                    <TableCell align="right">
                      <Typography
                        style={{
                          // color: "#47BB78",
                          textAlign: "left",
                          fontSize: "13px",
                          borderRadius: "5px",
                          padding: "5px 2px",
                          textTransform: "capitalize",
                        }}
                      >
                        {formatAsMoney(row?.amount)}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        style={{
                          // color: "#47BB78",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "5px",
                          padding: "5px 2px",
                          textTransform: "capitalize",
                        }}
                      >
                        {formatDate(row?.date)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        className={classes.editButton}
                        onClick={() => showRequestDetails(row?.application_id)}
                      >
                        View details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Box className={classes.paginated}>
        <Button onClick={Previous} color="default" className={classes.btn}>
          <span style={{ marginRight: "5px" }}>
            <ArrowBackIcon />
          </span>
          Previous
        </Button>
        <Pagination
          component="Box"
          count={lastPage}
          onChange={handleChangePage}
          page={page}
          classes={{ ul: classes.ul }}
          color="standard"
        />
        <Button onClick={Next} color="default" className={classes.btn}>
          Next
          <span>
            <ArrowForwardIcon />
          </span>
        </Button>
      </Box>
    </>
  );
}
