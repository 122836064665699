import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Paper,
  Input,
  Button,
  TextField,
} from "@material-ui/core";
import DataList from "./DataList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3rem",
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  textfield: {
    marginRight: 2,
    marginTop: 2,
  },
}));

export default function BasicTable() {
  const classes = useStyles();
  const [tab, setTab] = useState("all");
  const [search, setSearch] = useState(false);
  const [reference, setReference] = useState("");
  const handleTabsChange = (event, value) => {
    setSearch(false);
    setTab(value);
  };
  const handleChange = (e) => {
    setReference(e.target.value);
  };

  const handleClick = () => {
    setTimeout(() => setSearch(true), 50);
    setSearch(false);
  };

  const tabs = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "in-progress", label: "In-Progress" },
    { value: "declined", label: "Declined" },
    { value: "processed", label: "Processed" },
  ];

  if (!tab) {
    return <Redirect to={"/admin-account"} />;
  }

  return (
    <div>
      <TextField
        size="small"
        variant="outlined"
        className={classes.textfield}
        onChange={handleChange}
        placeholder="Input Reference No."
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        size="large"
      >
        Search
      </Button>
      <Paper className={classes.paper}>
        <Tabs
          className={classes.tabs}
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={tab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {search && <DataList type={"search"} reference={reference} />}
          {!search && tab === "all" && (
            <DataList type={"all"} reference={reference} />
          )}
          {!search && tab === "pending" && (
            <DataList type={"pending"} reference={reference} />
          )}
          {!search && tab === "processed" && (
            <DataList type={"processed"} reference={reference} />
          )}
          {!search && tab === "in-progress" && (
            <DataList type={"in-progress"} reference={reference} />
          )}
          {!search && tab === "declined" && (
            <DataList type={"declined"} reference={reference} />
          )}
        </div>
      </Paper>
    </div>
  );
}
