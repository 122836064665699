import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  Divider,
  colors,
  Paper,
  Typography,
  Chip,
  TextField,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import AdminZonesList from "./AdminZonesList";
import circleCheck from "images/check-circle.svg";
import downoadCloud from "images/download-cloud.svg";
import avatar from "images/avatar.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: "3rem",
    paddingInline: "2rem",
    background: "white",
    display: "flex",
    gap: "2em",
  },
  tabs: {
    marginTop: "3rem",
  },
  divider: {
    backgroundColor: colors.grey[300],
  },
  content: {
    margin: "3rem",
  },
  heading: {
    textAlign: "center",
    paddingTop: 32,
  },
  greyText: {
    fontWeight: "500",
    fontSize: "16px",
    color: "#B0AEAE",
    marginTop: "16px",
  },
  blackText: {
    fontWeight: "500",
    fontSize: "14px",
    display: "block",
    color: "black",
    marginTop: "12px",
  },
  greenBorder: {
    borderLeft: "3px solid #47BB78",
    padding: "10px",
    color: "#47BB78",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "18px",
  },
  boxIndent: {
    paddingInline: "31px",
  },
  greyBg: {
    background: "rgba(250, 250, 250, 0.8)",
    marginBottom: "14px",
  },
  halfWidth: {
    width: "50%",
  },
  documentRow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "20px",
  },
  boldText: {
    fontWeight: "600",
    fontSize: "14px",
    display: "block",
    color: "black",
  },

  space: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  chip: {
    background: "rgba(71, 187, 120, 0.3)",
    borderRadius: "29px",
    color: " #47BB78",
  },
  textarea: {
    padding: "10px 0px 10px 17px",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
  },
  button: {
    marginTop: "27px",
  },
}));

const Applications = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Zone management information</div>
          <div className={classes.boxIndent}>
            <p className={classes.greyText}>
              ZMC name
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              RCI number
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            <p className={classes.greyText}>
              ZMC address
              <span className={classes.blackText}>Semira enterprise</span>
            </p>
            {/* <p className={classes.greyText}>
               FZC
              <span className={classes.blackText}>Semira enterprise</span>
            </p> */}
            <p className={classes.greyText}>
              Email
              {/* <span className={classes.blackText}>Semira enterprise</span> */}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.halfWidth}>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Uploaded documents</div>
          <div className={classes.boxIndent}>
            <div className={classes.documentRow}>
              <img src={circleCheck} alt="circle-icon" />
              <span className={classes.boldText}>Semira enterprise</span>
              <img src={downoadCloud} alt="circle-icon" />
            </div>
          </div>
        </div>
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Approval Trail</div>
          <div className={classes.boxIndent}>
            <div className={classes.space}>
              <div className={classes.documentRow}>
                <img src={circleCheck} alt="circle-icon" />
                <span className={classes.boldText}>Semira enterprise</span>
              </div>
              <Chip label={"Approved"} className={classes.chip} />
            </div>
          </div>
        </div>
        {/* <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Authority remarks</div>
          <div className={classes.boxIndent}>
            <div className={classes.documentRow}>
              <img src={avatar} alt="circle-icon" />
              Free Zone Manager
            </div>
            <span className={classes.greyText}>
              I strongly advise semira enterprise is approved
            </span>
          </div>
        </div> */}
        <div className={classes.greyBg}>
          <div className={classes.greenBorder}>Remarks</div>
          <div className={classes.boxIndent}>
            <TextareaAutosize
              className={classes.textarea}
              maxRows={4}
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
      ut labore et dolore magna aliqua."
              style={{ width: "100%" }}
            />
          </div>
          <Button
            // autoFocus
            fullWidth
            variant="contained"
            color="primary"
            // onClick={handleSubmit}
            className={classes.button}
            size="large"
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Applications;
