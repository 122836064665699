import React from "react";
import { useParams } from "react-router-dom";

const SingleZoneAdminEnterprise = () => {
  const { entId } = useParams();

  return <div>SingleZoneAdminEnterprise</div>;
};

export default SingleZoneAdminEnterprise;
